import {
	IonButton,
	IonCol,
	IonGrid,
	IonIcon,
	IonImg,
	IonLabel,
	IonRow,
	IonSpinner,
	useIonToast,
	IonCard,
	IonItem,
	IonRadio,
	IonRadioGroup,
} from '@ionic/react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { AccountType, DocType, DocCategory, VerificationStatus } from 'API';
import DocumentApi from 'api/document';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FileUploader } from 'react-drag-drop-files';
import { useNavigate, useLocation } from 'react-router-dom';
import AppContext from 'utils/app-context';
import { Routes } from 'utils/routes';
import DocumentCapture from 'components/ImageCapture';
import IdCapture from 'components/IdCapture';
import RinngitPayLoader from 'components/RinngitPayLoader';
import AccountApi from 'api/account';
import { Toaster, toast } from 'react-hot-toast';
import { removeTypenameFromS3 } from 'utils/object-helper';

const IDSubmissionForBusinessAuth = () => {
	const navigate = useNavigate();
	let location = useLocation();
	const appContext = useContext(AppContext);
	const [showToast] = useIonToast();
	const fileTypes = ['JPEG', 'PNG', 'JPG'];
	const document0 = useRef<HTMLInputElement>(null);
	const document1 = useRef<HTMLInputElement>(null);
	const [fileChange, setFileChange] = useState<boolean>(false);
	const [captureImage, setCapturImage] = useState<boolean>(false);
	const [capturedImage0, setCapturedImage0] = useState<File | null>(null);
	const [capturedImage1, setCapturedImage1] = useState<File | null>(null);
	const [documentNumber, setDocumentNumber] = useState<number>(0);
	const [isLoading, setIsLoading] = useState(false);
	const [docTypeChange, setDocTypeChange] = useState(false);
	const accountDetails = appContext.selectedAccount();
	const docType =
		JSON.parse(appContext.selectedAccount().attributes).nationality ==
		appContext.nationality.MALAYSIAN
			? DocType.MYKAD
			: DocType.PASSPORT;
	let document = accountDetails.referenceDocs.items.find(
		(doc) => doc.docCategory === DocCategory.INDIVIDUAL,
	);
	const validationSchema = Yup.object({
		documentType: Yup.string().required(),
		docCategory: Yup.string().required(),
		document0: Yup.string(),
		document1: Yup.string(),
	});

	let formik = useFormik({
		initialValues: {
			documentType: !uploadedDocument() ? DocType.MYKAD : uploadedDocument().docType,
			docCategory: DocCategory.BUSINESS,
			document0: undefined as any,
			document1: undefined as any,
		},
		validationSchema: validationSchema,
		onSubmit: (values, { setSubmitting }) => {
			fileChange
				? onNext(values, setSubmitting)
				: navigate(Routes.CUSTOMER_VERIFICATION + '/' + Routes.ACCOUNT_SELFIE_VERIFICATION, {
						state: { id: accountDetails?.id, page: 2 },
				  });
		},
	});

	const notify = (toastMessageDetails) => {
		if (toastMessageDetails.toastType === 'error') {
			toast.error(toastMessageDetails.toastMessage, {
				duration: 3200,
				position: 'top-center',
			});
		}

		if (toastMessageDetails.toastType === 'success') {
			toast.success(toastMessageDetails.toastMessage, {
				duration: 3200,
				position: 'top-center',
			});
		}
	};

	useEffect(() => {
		if (location.state !== null) {
			notify({ toastMessage: location.state.toastMessage, toastType: location.state.toastType });
		}
		console.log('ID Submission Page for Business Auth');
		console.log('Selected Account: ', accountDetails);
		console.log(uploadedDocument() && uploadedDocument());
		loadImagefromS3(uploadedDocument() && uploadedDocument());
	}, []);

	function uploadedDocument() {
		return appContext
			.selectedAccount()
			?.referenceDocs.items.find(
				(doc) =>
					(doc.docType == DocType.MYKAD || doc.docType == DocType.PASSPORT) &&
					doc.docCategory === DocCategory.INDIVIDUAL &&
					doc.account.type === AccountType.BUSINESS,
			);
	}

	const onNext = async (formState, setSubmitting) => {
		console.log(fileChange);
		try {
			setIsLoading(true);
			const pages = setUplaodDocument();
			const idDocument = !uploadedDocument() ? {} : uploadedDocument();
			idDocument.docType = formik.values.documentType;
			idDocument.docCategory = DocCategory.INDIVIDUAL;
			idDocument.accountId = accountDetails.id;
			idDocument.updatedBy = appContext.userDetails.username;
			idDocument.owner = accountDetails.owner;
			idDocument.status = VerificationStatus.PENDING;
			const updatedPages = (idDocument.pages || []).map((page) => removeTypenameFromS3(page));
			idDocument.pages = updatedPages;
			console.log('idDocument', idDocument);
			const createDocument = await DocumentApi.save(idDocument);
			console.log('createDocument', createDocument);
			await DocumentApi.upload(idDocument, pages, createDocument.id, false);
			idDocument.id = createDocument.id;
			const updateDocument = await DocumentApi.save(idDocument);
			console.log('updateDocument', updateDocument);
			const extractedData = await DocumentApi.extractID(createDocument.id);
			console.log(extractedData);
			appContext.setSelectedAccount(createDocument.account);
			await getAccount();

			navigate(Routes.CUSTOMER_VERIFICATION + '/' + Routes.ACCOUNT_SELFIE_VERIFICATION, {
				state: { id: accountDetails?.id, page: 2 },
			});
		} catch (error) {
			setIsLoading(false);
			console.log(error);
			console.error('Failed uploading document', error);
		}
	};

	function setUplaodDocument() {
		return [
			formik.values?.document0 as unknown as File,
			formik.values.document1 as unknown as File,
		];
	}

	async function getAccount() {
		const getAccountDetails = await AccountApi.getByUser(appContext.userDetails.username).then(
			(accounts) => {
				return accounts;
			},
		);
		const selectedAccount = getAccountDetails.find((account) => {
			return account.id === appContext.selectedAccount().id;
		});
		console.log('--------', selectedAccount);
		appContext.setSelectedAccount(selectedAccount);
	}

	function loadImagefromS3(imageDetails) {
		setIsLoading(true);
		console.log('get image url', imageDetails);

		imageDetails?.pages?.forEach((element, index) => {
			console.log(element);
			DocumentApi.getImage(element.key).then((res) => {
				// console.log(res);
				formik.setFieldValue('document' + index, res);
			});
		});
		setIsLoading(false);
	}

	const handleFileChange = async (file, key) => {
		setFileChange(true);
		console.log(file, key);
		if (file === '') {
			console.log('111111');
			key === 'document0'
				? document0.current?.click()
				: key === 'document1'
				? document1.current?.click()
				: document0.current?.click();
		} else {
			console.log('22222');
			if (file.type === 'application/pdf') {
				console.log('pdf file');
			}
			formik.setFieldValue(key, file);
		}
		console.log('555555');
	};

	const isFormValid = () => {
		if (formik.values.documentType === DocType.MYKAD) {
			return (
				(formik.values.document0?.type && formik.values.document1?.type) ||
				(formik.values.document0 && formik.values.document1)
			);
		}
		if (formik.values.documentType === DocType.PASSPORT) {
			return (
				(formik.values.document0?.type && formik.values.document1?.type) ||
				(formik.values.document0 && formik.values.document1)
			);
		}
		return false;
	};

	const handleImageCapture = (image: File, documentNumber: number) => {
		documentNumber === 0 ? setCapturedImage0(image) : setCapturedImage1(image);
		formik.setFieldValue('document' + documentNumber, image);
		setCapturImage(false);
	};

	function documentDetailss() {
		return {
			documentType: formik.values.documentType === DocType.MYKAD ? 'MYKAD' : 'PASSPORT',
			documentNumber,
		};
	}

	const handleDocumentSelection = (documentType) => {
		formik.setFieldValue('documentType', documentType);
		// console.log(uploadedIdDocument.docType === documentType);
		// if (uploadedIdDocument.docType === documentType) {
		// 	loadImagefromS3(uploadedIdDocument && uploadedIdDocument);
		// } else {
		// 	formik.setFieldValue('document' + 0, null);
		// 	formik.setFieldValue('document' + 1, null);
		// }
	};

	return (
		<form className="mt-2 md:mt-0 ml-0 md:ml-1" onSubmit={formik.handleSubmit}>
			<Toaster />
			{!isLoading ? (
				<section className=" h-full">
					{captureImage && (
						<div className="ml-1 md:ml-5 mt-5">
							<IdCapture onImageCapture={handleImageCapture} documentDetails={documentDetailss()} />
						</div>
					)}

					{!captureImage && (
						<>
							<IonGrid>
								<IonRow>
									<IonCol>
										<div className="mt-1 md:mt-2 pb-6 ml-2 md:ml-6 font-bold text-secondary-75 text-22px ">
											Upload Your{' '}
											{formik.values.documentType === DocType.MYKAD ? 'MyKad' : 'Passport'}
										</div>
										<div className="text-16px ml-2 md:ml-6 text-secondary-75 font-normal">
											Once you upload a copy of{' '}
											{formik.values.documentType === DocType.MYKAD ? 'MyKad' : 'Passport'}, the
											system will automatically capture the information in the document and auto
											fill your details.
										</div>
									</IonCol>
								</IonRow>

								<IonRadioGroup
									value={formik.values.documentType}
									onIonChange={(e) => {
										formik.setFieldValue('accountType', e.detail.value);
										// setAccountChange(true);
										if (e.detail.value === DocType.PASSPORT) {
											formik.setFieldValue('document0', undefined);
											// Add similar logic for other related states if necessary
										} else if (e.detail.value === DocType.MYKAD) {
											formik.setFieldValue('document1', undefined);
											// Add similar logic for other related states if necessary
										}
									}}
								>
									<IonRow className="ml-0 md:ml-2.5 w-screen">
										<IonRow className="w-3/4 md:w-32 ml-2 md:ml-0">
											<div
												onClick={() => {
													formik.setFieldValue('documentType', DocType.MYKAD);
													formik.setFieldValue('document' + 0, null);
													formik.setFieldValue('document' + 1, null);
												}}
											>
												<IonItem lines="none" className="common-item">
													<IonLabel className=" text-16px font-normal text-secondary-100">
														MyKad
													</IonLabel>
													<IonRadio className="mr-2" slot="start" value={DocType.MYKAD} />
												</IonItem>
											</div>
										</IonRow>

										<IonRow className="w-3/4 md:w-0 ml-2 md:ml-0">
											<div
												onClick={() => {
													formik.setFieldValue('documentType', DocType.PASSPORT);
													formik.setFieldValue('document' + 0, null);
													formik.setFieldValue('document' + 1, null);
												}}
											>
												<IonItem lines="none" className="common-item">
													<IonLabel className=" text-16px font-normal text-secondary-100">
														Passport
													</IonLabel>
													<IonRadio className="mr-2" slot="start" value={DocType.PASSPORT} />
												</IonItem>
											</div>
										</IonRow>
									</IonRow>
								</IonRadioGroup>

								<IonRow>
									{!formik.values?.document0 && (
										<>
											<IonRow className="w-64 mb-0 ml- md:ml-1">
												<IonRow className="ml-1.5 md:ml-4">
													<>
														<div className="flex flex-col items-center">
															<div className="flex flex-col justify-center items-center">
																<IonCard className="w-64 h-40 bg-secondary-00 border-2 border-dashed border-secondary-100 rounded-lg relative overflow-hidden flex flex-col justify-center items-center text-center">
																	<IonIcon
																		className={`icon uploadIcon h-7 w-9	mb-2 ${
																			formik.values.documentType === DocType.MYKAD
																				? 'mykad'
																				: 'passport'
																		} bg-primary-100`}
																	/>
																	<IonLabel
																		className="text-secondary-75 text-16px font-normal"
																		style={{ fontSize: '0.9rem' }}
																	>
																		Scan or take a photo of the{' '}
																		{formik.values.documentType === DocType.MYKAD
																			? 'Front side'
																			: 'Front page'}{' '}
																		of your{' '}
																		{formik.values.documentType === DocType.MYKAD
																			? 'MyKad'
																			: 'Passport'}
																	</IonLabel>
																	<IonRow className="flex justify-center">
																		<div
																			className="text-xs cursor-pointer mt-1.5 m-auto h-auto py-1 bg-primary-100 rounded-md text-center text-secondary-00 w-24 align-center"
																			onClick={() => {
																				setCapturImage(true);
																				setDocumentNumber(0);
																				setFileChange(true);
																			}}
																		>
																			Capture
																		</div>
																	</IonRow>
																</IonCard>
															</div>
														</div>
													</>
												</IonRow>
											</IonRow>
										</>
									)}

									{formik.values?.document0 && (
										<>
											<IonRow className="ml-2 w-64 mb-0 md:ml-1">
												<IonRow className="flex ">
													<>
														<div className="ml-0 md:ml-4 flex flex-col items-center">
															<div className="flex flex-col justify-center items-center">
																<IonCard className="w-64 h-40 border-2 border-primary-50 rounded-lg relative overflow-hidden flex flex-col justify-center items-center text-center bg-transparent">
																	<IonImg
																		src={
																			capturedImage0
																				? URL.createObjectURL(capturedImage0)
																				: formik.values?.document0
																		}
																	></IonImg>
																</IonCard>
															</div>
														</div>
													</>
												</IonRow>
												<IonRow className="mt-1 ml-10 w-64 mb-0 justify-center">
													<IonLabel className="text-16px text-secondary-75 font-normal">
														{formik.values.documentType === DocType.MYKAD
															? 'Front side'
															: 'Front page'}{' '}
														of {formik.values.documentType === DocType.MYKAD ? 'MyKad' : 'Passport'}
													</IonLabel>
												</IonRow>
												<IonRow className="ml-4 md:ml-12 md:pl-1 mt-3 mb-4 justify-center w-80">
													<div
														className="text-xs cursor-pointer h-auto py-1 border bg-primary-100 rounded-md text-center text-secondary-00 w-24 align-center"
														onClick={() => {
															setCapturImage(true);
															setDocumentNumber(0);
															setFileChange(true);
														}}
													>
														Re-Capture
													</div>
												</IonRow>
											</IonRow>
										</>
									)}

									{!formik.values?.document1 && (
										<>
											<IonRow className="w-80 mb-0">
												<IonRow
													className="ml-1.5 md:ml-10 mt-5 md:mt-0"
													//   style={{ marginTop: '22px' }}
												>
													<>
														<div className="flex flex-col items-center">
															<div className="flex flex-col justify-center items-center">
																<IonCard className="w-64 h-40 pl-1 pr-1 bg-secondary-00 border-2 border-dashed border-secondary-100 rounded-lg relative overflow-hidden flex flex-col justify-center items-center text-center">
																	<IonIcon
																		className={`icon uploadIcon h-7 w-9 mb-2 ${
																			formik.values.documentType === DocType.MYKAD
																				? 'mykad'
																				: 'passport'
																		} bg-primary-100`}
																	/>
																	<IonLabel
																		className="text-secondary-75 text-16px font-normal"
																		style={{ fontSize: '0.9rem' }}
																	>
																		Scan or take a photo of the{' '}
																		{formik.values.documentType === DocType.MYKAD
																			? 'Back side'
																			: 'Back page'}{' '}
																		of your{' '}
																		{formik.values.documentType === DocType.MYKAD
																			? 'MyKad'
																			: 'Passport'}
																	</IonLabel>
																	<IonRow className="flex justify-center">
																		<div
																			className="text-xs cursor-pointer mt-1.5 m-auto h-auto py-1 bg-primary-100 rounded-md text-center text-secondary-00 w-24 align-center"
																			onClick={() => {
																				setCapturImage(true);
																				setDocumentNumber(1);
																				setFileChange(true);
																			}}
																		>
																			Capture
																		</div>
																	</IonRow>
																</IonCard>
															</div>
														</div>
													</>
												</IonRow>
											</IonRow>
										</>
									)}

									{formik.values?.document1 && (
										<>
											<IonRow className="w-80 mb-0 ml-2 md:ml-10">
												<IonRow className="mt-0">
													<>
														<div className="ml-0">
															<div>
																<IonCard className="w-64 h-40 border-2 border-primary-50 rounded-lg relative overflow-hidden flex flex-col justify-center items-center text-center bg-transparent">
																	<IonImg
																		src={
																			capturedImage1
																				? URL.createObjectURL(capturedImage1)
																				: formik.values?.document1
																		}
																	></IonImg>
																</IonCard>
															</div>
														</div>
													</>
												</IonRow>
												<IonRow className="mt-1 ml-2 w-64 mb-0 justify-center">
													<IonLabel className="text-16px text-secondary-75 font-normal">
														{formik.values.documentType === DocType.MYKAD
															? 'Back side'
															: 'Back page'}{' '}
														of {formik.values.documentType === DocType.MYKAD ? 'MyKad' : 'Passport'}
													</IonLabel>
												</IonRow>
												<IonRow className="ml-2.5 mb-5 mt-2.5 justify-center w-64">
													<div
														className="text-xs cursor-pointer h-auto py-1 border bg-primary-100 rounded-md text-center text-secondary-00 w-24 align-center"
														onClick={() => {
															setCapturImage(true);
															setDocumentNumber(1);
															setFileChange(true);
														}}
													>
														Re-Capture
													</div>
												</IonRow>
											</IonRow>
										</>
									)}
								</IonRow>
							</IonGrid>

							<IonRow>
								<div className="ml-5 md:ml-8 md:pl-0.5 mt-3 mb-7">
									<IonButton
										type="submit"
										expand="block"
										className="h-36px capitalize font-normal text-16px rounded-sm"
										disabled={!isFormValid() || formik.isSubmitting}
									>
										{formik.isSubmitting ? <IonSpinner /> : 'Continue'}
									</IonButton>
								</div>
							</IonRow>
						</>
					)}
				</section>
			) : (
				<RinngitPayLoader props={{ overlay: true }} />
			)}
		</form>
	);
};

export default React.memo(IDSubmissionForBusinessAuth);
