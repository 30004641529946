import {
	IonButton,
	IonCard,
	IonCardContent,
	IonLabel,
	IonRow,
	IonSkeletonText,
	useIonToast,
	IonIcon,
	IonText,
	IonPopover,
	IonContent,
	IonSearchbar,
	IonInput,
	IonItem,
	IonSelect,
	IonSelectOption,
	IonMenuToggle,
	IonMenu,
	IonToolbar,
	IonHeader,
	IonTitle,
} from '@ionic/react';
import {
	alert,
	arrowBackSharp,
	arrowForwardSharp,
	arrowUp,
	ellipse,
	playSkipBack,
	playSkipForward,
	search,
	close,
	navigate,
} from 'ionicons/icons';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppContext, { AppContextType } from 'utils/app-context';
import { Routes } from 'utils/routes';
import { Account, AccountStatus, AccountType } from 'API';
import AccountApi from 'api/account';
import useMobileDetector from 'hooks/use-mobile-detector';
import * as clientStorage from 'utils/client-storage';
import filter from '../../images/filter.svg';
import { Toaster, toast } from 'react-hot-toast';
import RinngitPayLoader from 'components/RinngitPayLoader';
import { Tooltip as ReactTooltip } from 'react-tooltip';

function AccountCard({ account, onClick, isLastIndex }) {
	const navigate = useNavigate();
	const isMobile = useMobileDetector() === 'mobile';
	const appContext = useContext<AppContextType>(AppContext);
	const [present] = useIonToast();

	const notify = (toastMessageDetails) => {
		if (toastMessageDetails.toastType === 'error') {
			toast.error(toastMessageDetails.toastMessage, {
				duration: 3200,
				position: 'top-center',
			});
		}

		if (toastMessageDetails.toastType === 'success') {
			toast.success(toastMessageDetails.toastMessage, {
				duration: 3200,
				position: 'top-center',
			});
		}
	};

	function toSentenceCase(inputString) {
		if (typeof inputString !== 'string' || inputString.length === 0) {
			return '';
		}
		return inputString.charAt(0).toUpperCase() + inputString.slice(1).toLowerCase();
	}

	const onAccountSelect = (account) => {
		appContext.setSelectedAccount(account);
		navigate(Routes.CUSTOMER_VERIFICATION + '/' + Routes.ACCOUNT_BASIC_DETAILS, {
			state: { id: account?.id, page: 0 },
		});
	};

	const navigateToAccount = (account) => {
		if (account.status === AccountStatus.PENDING) {
			navigate(Routes.PENDING);
		} else if (account.status === AccountStatus.ACTIVE) {
			// presentToast('Account is active');
			notify({ toastMessage: 'Account is active', toastType: 'success' });
		} else if (account.status === AccountStatus.REJECTED) {
			// presentToast('Account is active');
			notify({
				toastMessage:
					'Your account is rejected by an admin, Please contact the RinggitPay admin for more details',
				toastType: 'error',
			});
		} else {
			onAccountSelect(account);
		}
	};

	return (
		<>
			<Toaster />
			<IonRow className="w-screen">
				{!isMobile && (
					<>
						<div
							className={` grid grid-cols-5 mr-5 ml-5 border bg-secondary-00 h-14 w-screen hover:bg-mousehover-50 ${
								isLastIndex && 'rounded-b-2xl'
							}`}
						>
							<IonRow className="col-span-1 flex justify-start items-start ml-6 my-4 text-14px">
								<div className={`whitespace-nowrap ${window.innerWidth < 980 ? 'truncate' : ''}`}>
									{appContext.userDetails.name ||
										appContext.userDetails.fullname ||
										appContext.userDetails.attributes.email.split('@')[0]}
								</div>{' '}
							</IonRow>

							<IonRow
								className="col-span-1 mx-2 flex justify-start items-center text-14px cursor-pointer text-primary-100"
								onClick={() => navigateToAccount(account)}
							>
								<div data-tooltip-id={`accountId-${account.id}`}>
									{account.id.slice(0, account.id.length / 2)}...
								</div>
								<ReactTooltip
									id={`accountId-${account.id}`}
									place="bottom"
									content={account.id}
									style={{ backgroundColor: '#1b77f7', color: '#ffffff' }}
								/>
							</IonRow>

							<IonRow className="col-span-1 flex justify-start items-start ml-3 my-4 text-16px">
								{account.shortName}
							</IonRow>

							<IonRow
								className={`col-span-1 flex justify-start items-start ml-2 my-4 text-16px ${
									account.status === AccountStatus.ACTIVE
										? 'text-success'
										: account.status === AccountStatus.DRAFT ||
										  account.status === AccountStatus.PENDING
										? 'text-primary-100'
										: 'text-failure'
								}`}
							>
								<IonIcon
									icon={ellipse}
									className={`h-3 w-3 mt-1.5 justify-end mr-1 ${
										account.status === AccountStatus.ACTIVE
											? 'text-success'
											: account.status === AccountStatus.DRAFT ||
											  account.status === AccountStatus.PENDING
											? 'text-primary-100'
											: 'text-failure'
									}`}
								/>
								{account.status}
							</IonRow>

							<IonRow className="col-span-1 flex justify-start items-start ml-3 my-4 text-16px ">
								{toSentenceCase(account?.type)}
							</IonRow>
						</div>
					</>
				)}

				{isMobile && (
					<>
						<IonCard
							className="ml-4 mb-0 mt-3  border border-shao bg-secondary-00 h-24 w-11/12 sm:mr-16 lg:mr-36 shadow-none rounded-lg"
							onClick={() => navigateToAccount(account)}
						>
							<IonRow className="flex justify-between">
								<IonLabel className="flex w-5/12 ml-3 mt-3 text-16px">{account.shortName}</IonLabel>
								<IonRow className="flex w-5/12 ml-3 mt-1 justify-end text-16px">
									<IonText className="mr-3 text-secondary-75 justify-end mt-2 text-16px">
										{toSentenceCase(account?.type)}
									</IonText>
								</IonRow>
								<IonLabel className="flex w-5/12 ml-3 mt-6 text-16px">
									{appContext.userDetails.name ||
										appContext.userDetails.fullname ||
										appContext.userDetails.attributes.email.split('@')[0]}
								</IonLabel>
								<IonLabel
									className={`flex w-5/12 mr-3 justify-end mt-6  text-16px ${
										account.status === AccountStatus.ACTIVE
											? 'text-success'
											: account.status === AccountStatus.DRAFT ||
											  account.status === AccountStatus.PENDING
											? 'text-primary-100'
											: 'text-failure'
									}`}
								>
									<IonIcon
										icon={ellipse}
										className={`h-3 w-3 mt-1.5 justify-end mr-1 ${
											account.status === AccountStatus.ACTIVE
												? 'text-success'
												: account.status === AccountStatus.DRAFT ||
												  account.status === AccountStatus.PENDING
												? 'text-primary-100'
												: 'text-failure'
										}`}
									/>
									{account.status}
								</IonLabel>
							</IonRow>
						</IonCard>
					</>
				)}
			</IonRow>
		</>
	);
}

export default function AccountSelection() {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [accounts, setAccounts] = useState<Account[]>([]);
	const navigate = useNavigate();
	const appContext = useContext(AppContext);
	const isDesktop = useMobileDetector() === 'desktop';
	const isMobile = useMobileDetector() === 'mobile';
	const popover = useRef<HTMLIonPopoverElement>(null);
	const [popoverOpen, setPopoverOpen] = useState(false);
	const [searchText, setSearchText] = useState('');
	const [present] = useIonToast();

	const notify = (toastMessageDetails) => {
		if (toastMessageDetails.toastType === 'error') {
			toast.error(toastMessageDetails.toastMessage, {
				duration: 3200,
				position: 'top-center',
			});
		}

		if (toastMessageDetails.toastType === 'success') {
			toast.success(toastMessageDetails.toastMessage, {
				duration: 3200,
				position: 'top-center',
			});
		}
	};

	const [selectedStatus, setSelectedStatus] = useState('ALL');
	const [selectedShortName, setSelectedShortName] = useState(null);
	const [selectedOwner, setSelectedOwner] = useState(null);
	const [selectedAccountType, setSelectedAccountType] = useState(null);

	const statusSelectRef = useRef<HTMLIonSelectElement>(null);
	const shortNameInputRef = useRef<HTMLIonInputElement>(null);
	const ownerInputRef = useRef<HTMLIonInputElement>(null);
	const accountTypeSelectRef = useRef<HTMLIonSelectElement>(null);

	useEffect(() => {
		console.log('Admin List All Accounts');
		clientStorage.clearSelectedAccount();
		listAccounts();
	}, []);

	async function listAccounts() {
		try {
			setIsLoading(true);
			const listAccounts = await AccountApi.listAccounts(
				undefined,
				undefined,
				undefined,
				undefined,
				undefined,
			);
			console.log(listAccounts.data.listAccounts.items);
			const sortedAccounts = listAccounts.data.listAccounts.items.sort(
				(a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime(),
			);
			console.log(sortedAccounts);
			setAccounts(sortedAccounts);
			setIsLoading(false);
			return sortedAccounts;
		} catch (error) {
			console.error('Error fetching accounts:', error);
			setIsLoading(false);
		}
	}

	useState(() => {
		console.log('Account Selection Page.....');
		clientStorage.clearSelectedAccount();
		AccountApi.getByUser(appContext.userDetails.username)
			.then((accounts) => {
				setAccounts(accounts);
				if (accounts.length === 0) {
					const route = isDesktop ? Routes.PRE_REQUISITE : Routes.PRE_REQUISITE;
					navigate(route);
				}
			})
			.catch((error) => {
				console.log(error);

				notify({ toastMessage: error?.errors[0].message, toastType: 'error' });
			});
	});

	const onAccountSelect = (account) => {
		appContext.setSelectedAccount(account);
		navigate(Routes.CUSTOMER_VERIFICATION + '/' + Routes.ACCOUNT_BASIC_DETAILS, {
			state: { id: account?.id, page: 0 },
		});
	};

	const onCreateAccount = () => {
		navigate(Routes.PRE_REQUISITE);
	};

	const openPopover = (e: any) => {
		popover.current!.event = e;
		setPopoverOpen(true);
	};

	const handleInputChange = (e) => {
		const inputText = e.target.value;
		console.log('Searching for:', inputText);
		setSearchText(inputText);
	};

	const handleFilterChange = (e, filterType) => {
		console.log('Filter Type: ', filterType, 'Filter Value: ', e.detail.value);

		if (filterType === 'STATUS') {
			// console.log('setting status');
			setSelectedStatus(e.detail.value);
		} else if (filterType === 'SHORT_NAME') {
			// console.log('setting shortname');
			setSelectedShortName(e.detail.value);
		} else if (filterType === 'OWNER') {
			// console.log('setting owner');
			setSelectedOwner(e.detail.value);
		} else if (filterType === 'ACCOUNT_TYPE') {
			// console.log('setting account type');
			setSelectedAccountType(e.detail.value);
		}
		// console.log('done');
	};

	const filterAccounts = async () => {
		// console.log('Filtering.....');
		setIsLoading(true);
		const accounts = await listAccounts();

		const filteredAccounts = accounts.filter((account) => {
			console.log(selectedStatus, selectedShortName, selectedOwner, selectedAccountType);
			// const filteredByStatus = !selectedStatus || account.status === selectedStatus;
			const filteredByStatus = selectedStatus === 'ALL' || account.status === selectedStatus;
			const filteredByType = !selectedAccountType || account.type === selectedAccountType;
			const filteredByShortName =
				!selectedShortName || account.shortName.includes(selectedShortName);

			return filteredByStatus && filteredByType && filteredByShortName;
		});
		const sortedAccounts = filteredAccounts.sort(
			(a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime(),
		);
		console.log(sortedAccounts);
		setAccounts(sortedAccounts);
		setIsLoading(false);
	};

	const clearFilter = async () => {
		if (statusSelectRef.current) {
			statusSelectRef.current.value = undefined;
		}

		if (shortNameInputRef.current) {
			shortNameInputRef.current.value = '';
		}

		if (ownerInputRef.current) {
			ownerInputRef.current.value = '';
		}

		if (accountTypeSelectRef.current) {
			accountTypeSelectRef.current.value = undefined;
		}
		listAccounts();
	};

	return (
		<>
			<Toaster />
			{!isLoading ? (
				<>
					<IonMenu side="end" contentId="filter-menu">
						<IonHeader>
							<IonToolbar>
								<IonRow>
									<IonTitle>Filter</IonTitle>
									<IonMenuToggle>
										<IonIcon icon={close} className="h-6 w-6 mr-3 pt-2 cursor-pointer "></IonIcon>
									</IonMenuToggle>
								</IonRow>
							</IonToolbar>
						</IonHeader>

						<IonContent className="ion-padding">
							<IonRow className="flex mb-3.5 pt-1.5 w-screen">
								<IonLabel className="font-normal text-secondary-75 text-16px">
									Account Status
								</IonLabel>
							</IonRow>

							<IonSelect
								ref={statusSelectRef}
								interface="popover"
								className="app-input-filed text-16px text-secondary-75 font-medium w-60 bg-secondary-00 pr-2 mt-3 ml-0.5"
								onIonChange={(e) => handleFilterChange(e, 'STATUS')}
								placeholder="Filter by status"
								value={selectedStatus}
							>
								<IonSelectOption className="w-60" value="ALL">
									ALL
								</IonSelectOption>
								{Object.values(AccountStatus).map(
									(status) =>
										status !== AccountStatus.DELETED &&
										status !== AccountStatus.BLOCKED && (
											<IonSelectOption key={status} className="w-60" value={status}>
												{status}
											</IonSelectOption>
										),
								)}
								{/* <IonSelectOption value={AccountStatus.DELETED}>{AccountStatus.DELETED}</IonSelectOption>
						<IonSelectOption value={AccountStatus.BLOCKED}>{AccountStatus.BLOCKED}</IonSelectOption> */}
							</IonSelect>

							<IonRow className="flex  mt-5 mb-3.5 pt-1.5 w-screen">
								<IonLabel className="font-normal text-secondary-75 text-16px">Short Name</IonLabel>
							</IonRow>

							<IonItem className="app-input-filed bg-secondary-00 mt-3 ml-0.5 w-60" lines="none">
								<IonInput
									ref={shortNameInputRef}
									placeholder="Filter by Short Name"
									className=" text-16px text-secondary-75 font-medium bg-secondary-00"
									onIonChange={(e) => handleFilterChange(e, 'SHORT_NAME')}
								></IonInput>
							</IonItem>

							{/* <IonRow className="flex  mt-5 mb-3.5 pt-1.5 w-screen">
						<IonLabel className="font-normal text-secondary-75 text-16px">Owner</IonLabel>
					</IonRow>

					<IonItem className="app-input-filed bg-secondary-00 mt-3 ml-0.5 w-60" lines="none">
						<IonInput
							ref={ownerInputRef}
							placeholder="Filter by Owner"
							className=" text-16px text-secondary-75 font-medium bg-secondary-00"
							onIonChange={(e) => handleChange(e, 'OWNER')}
						></IonInput>
					</IonItem> */}

							<IonRow className="flex  mt-5 mb-3.5 pt-1.5 w-screen">
								<IonLabel className="font-normal text-secondary-75 text-16px">
									Account Type
								</IonLabel>
							</IonRow>

							<IonSelect
								ref={accountTypeSelectRef}
								interface="popover"
								className="ion-popover app-input-filed text-16px text-secondary-75 font-medium w-60 bg-secondary-00 pr-2 mt-3 ml-0.5"
								onIonChange={(e) => handleFilterChange(e, 'ACCOUNT_TYPE')}
								placeholder="Filter by Category"
							>
								{Object.values(AccountType).map((type) => (
									<IonSelectOption key={type} className="w-60" value={type}>
										{type}
									</IonSelectOption>
								))}
							</IonSelect>

							<IonMenuToggle>
								<IonButton className="mt-7 mr-5" onClick={filterAccounts}>
									Apply
								</IonButton>
								<IonButton className="mt-7 mr-5" onClick={clearFilter}>
									Clear Filter
								</IonButton>
							</IonMenuToggle>
						</IonContent>
					</IonMenu>

					<IonRow>
						<IonRow className="w-screen md:w-1/2 pt-5 pl-5 pb-0 md:pb-0">
							<div className="text-22px text-secondary-75 font-bold">Accounts</div>
						</IonRow>
					</IonRow>

					<IonRow>
						{/* <IonRow className="min-width-600  max-width-600 pl-3 mb-2 mt-0 md:mb-0 pb-0 md:pb-5">
					<IonSearchbar
						className="rounded-2xl mr-3 max-width-600 md:w-80 shadow-none"
						placeholder="Search..."
						onChange={handleInputChange}
					></IonSearchbar>
				</IonRow> */}

						<IonRow className="w-full pt-2.5 md:pt-2 pl-4 md:pl-10 pr-5  justify-end shadow-none pb-5">
							<IonButton
								className="h-36px shadow-none capitalize font-normal rounded-md mr-5"
								fill="solid"
								// onClick={onCreateAccount}
								onClick={() => navigate(Routes.PRE_REQUISITE)}
							>
								Add Account
							</IonButton>
							<IonMenuToggle id="filter-menu">
								<IonIcon
									icon={filter}
									className="pt-1.5 pr-0.5 ml-1 h-7 w-7 cursor-pointer"
								></IonIcon>
							</IonMenuToggle>
						</IonRow>
					</IonRow>

					<IonRow>
						{isLoading ? (
							<>
								{!isMobile && (
									<div className="w-screen ">
										<IonRow>
											<div className="ml-5 grid grid-cols-5 border border-shao bg-secondary-00 h-14 w-screen mr-9 shadow-none rounded-t-2xl">
												<div className="col-span-1 flex justify-start items-start ml-6 my-5 text-16px text-secondary-100 font-bold">
													Owner
												</div>
												<div className="col-span-1 flex justify-start items-start ml-2 my-5 text-16px text-secondary-100 font-bold">
													Identifiers
												</div>
												<div className="col-span-1 flex justify-start items-start ml-2 my-5 text-16px text-secondary-100 font-bold">
													Short Name
												</div>
												<div className="col-span-1 justify-start items-start ml-2 my-5 text-16px text-secondary-100 font-bold">
													Status
												</div>
												<div className="col-span-1 justify-start items-start ml-2 my-5 text-16px text-secondary-100 font-bold">
													Category
												</div>
											</div>
										</IonRow>
									</div>
								)}

								{isMobile && (
									<div className="w-screen ">
										<IonCard className="m-4 bg-primary-200">
											<IonCardContent className="p-4">
												<IonSkeletonText
													animated
													className="bg-primary-500 w-2/4"
												></IonSkeletonText>
												<IonSkeletonText
													animated
													className="bg-primary-500 w-3/4"
												></IonSkeletonText>
												<IonSkeletonText
													animated
													className="bg-primary-500 w-2/4 mt-3"
												></IonSkeletonText>
											</IonCardContent>
										</IonCard>
										<IonCard className="m-4 bg-primary-200">
											<IonCardContent className="p-4">
												<IonSkeletonText
													animated
													className="bg-primary-500 w-2/4"
												></IonSkeletonText>
												<IonSkeletonText
													animated
													className="bg-primary-500 w-3/4"
												></IonSkeletonText>
												<IonSkeletonText
													animated
													className="bg-primary-500 w-2/4 mt-3"
												></IonSkeletonText>
											</IonCardContent>
										</IonCard>
										<IonCard className="m-4 bg-primary-200">
											<IonCardContent className="p-4">
												<IonSkeletonText
													animated
													className="bg-primary-500 w-2/4"
												></IonSkeletonText>
												<IonSkeletonText
													animated
													className="bg-primary-500 w-3/4"
												></IonSkeletonText>
												<IonSkeletonText
													animated
													className="bg-primary-500 w-2/4 mt-3"
												></IonSkeletonText>
											</IonCardContent>
										</IonCard>
									</div>
								)}
							</>
						) : (
							<>
								{!isMobile && (
									<div className="w-screen ">
										<IonRow>
											<div className="ml-5 grid grid-cols-5 border border-shao bg-secondary-00 h-14 w-screen mr-5 shadow-none rounded-t-2xl">
												<div className="col-span-1 flex justify-start items-start ml-6 my-5 text-16px text-secondary-100 font-bold">
													Owner
												</div>
												<div className="col-span-1 flex justify-start items-start ml-2 my-5 text-16px text-secondary-100 font-bold">
													Identifiers
												</div>
												<div className="col-span-1 flex justify-start items-start ml-2 my-5 text-16px text-secondary-100 font-bold">
													Short Name
												</div>
												<div className="col-span-1 flex justify-start items-start ml-2 my-5 text-16px text-secondary-100 font-bold">
													Status
												</div>
												<div className="col-span-1 flex justify-start items-start ml-2 my-5 text-16px text-secondary-100 font-bold">
													Category
												</div>
											</div>
										</IonRow>
									</div>
								)}

								{accounts.length === 0 && (
									<IonRow className="ml-4 w-screen mr-5 flex items-center justify-center mt-5 mb-5 ">
										<IonLabel className=" text-22px font-bold">No Accounts to Display</IonLabel>
									</IonRow>
								)}

								<div className="w-screen ">
									{accounts.map((account, index) => {
										const isLastIndex = index === accounts.length - 1;
										// @ts-ignore
										return (
											<AccountCard
												key={index}
												account={account}
												onClick={onAccountSelect}
												isLastIndex={isLastIndex}
											/>
										);
									})}
								</div>

								{/* <IonRow className="w-full flex items-end justify-end">
							<IonRow className="mt-5 mr-5 mb-7 flex items-end justify-end">
								{isMobile && (
									<>
										<IonText className=" pr-10">1 - 10 of 108</IonText>

										<IonIcon
											icon={playSkipBack}
											className=" pl-3 pb-0.5 h-6 w-6 cursor-pointer"
										></IonIcon>
										<IonIcon
											icon={arrowBackSharp}
											className="pr-3 pl-6 pb-0.5 h-6 w-6 cursor-pointer"
										></IonIcon>
										<IonIcon
											icon={arrowForwardSharp}
											className=" pl-6 pr-3 pb-0.5 h-6 w-6 cursor-pointer"
										></IonIcon>
										<IonIcon
											icon={playSkipForward}
											className=" pl-3 pb-0.5 h-6 w-6 cursor-pointer"
										></IonIcon>
									</>
								)}

								<IonText className="pr-7">Items per page:</IonText>

								<div
									className="flex items-end md:items-center justify-end md:justify-center cursor-pointer"
									onClick={openPopover}
								>
									<IonText className="pr-10 md:pr-14">10</IonText>
									<IonIcon
										icon={arrowUp}
										className="mt-5 md:mt-0 md:pr-14 pb-1.5 md:pb-0.5 h-4 w-5"
									></IonIcon>
								</div>
								<IonPopover
									size="cover"
									ref={popover}
									isOpen={popoverOpen}
									onDidDismiss={() => setPopoverOpen(false)}
								>
									<IonContent className="ion-padding">
										<div>5</div>
										<div>10</div>
										<div>25</div>
										<div>50</div>
									</IonContent>
								</IonPopover>

								{!isMobile && (
									<>
										<IonText className=" pr-10">1 - 10 of 108</IonText>

										<IonIcon
											icon={playSkipBack}
											className=" pl-3 pb-0.5 h-5 w-5 cursor-pointer"
										></IonIcon>

										<IonIcon
											icon={arrowBackSharp}
											className="pr-3 pl-6 pb-0.5 h-5 w-5 cursor-pointer"
										></IonIcon>
										<IonIcon
											icon={arrowForwardSharp}
											className=" pl-6 pr-3 pb-0.5 h-5 w-5 cursor-pointer"
										></IonIcon>
										<IonIcon
											icon={playSkipForward}
											className=" pl-3 pb-0.5 h-5 w-5 cursor-pointer"
										></IonIcon>
									</>
								)}
							</IonRow>
						</IonRow> */}
							</>
						)}
					</IonRow>
				</>
			) : (
				<RinngitPayLoader props={{ overlay: true }} />
			)}
		</>
	);
}
