/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type MessageInput = {
  mobileNumber: string,
  type: MessageType,
  message: string,
};

export enum MessageType {
  OTP = "OTP",
  MARKETING = "MARKETING",
}


export enum CTOSReportType {
  KYC = "KYC",
  BUSINESS = "BUSINESS",
  REPORT_LITE = "REPORT_LITE",
  REPORT_FULL = "REPORT_FULL",
}


export type CreateAccountInput = {
  id?: string | null,
  shortName?: string | null,
  type: AccountType,
  attributes?: string | null,
  questionnaire?: string | null,
  owner: string,
  status: AccountStatus,
  comments?: Array< CommentInput | null > | null,
  updatedBy: string,
  createdBy: string,
};

export enum AccountType {
  INDIVIDUAL = "INDIVIDUAL",
  BUSINESS = "BUSINESS",
}


export enum AccountStatus {
  DRAFT = "DRAFT",
  PENDING = "PENDING",
  ACTIVE = "ACTIVE",
  BLOCKED = "BLOCKED",
  REJECTED = "REJECTED",
  DELETED = "DELETED",
}


export type CommentInput = {
  message: string,
  commentedBy: string,
  commentedAt: number,
};

export type ModelAccountConditionInput = {
  shortName?: ModelStringInput | null,
  type?: ModelAccountTypeInput | null,
  attributes?: ModelStringInput | null,
  questionnaire?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  status?: ModelAccountStatusInput | null,
  updatedBy?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  and?: Array< ModelAccountConditionInput | null > | null,
  or?: Array< ModelAccountConditionInput | null > | null,
  not?: ModelAccountConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelAccountTypeInput = {
  eq?: AccountType | null,
  ne?: AccountType | null,
};

export type ModelAccountStatusInput = {
  eq?: AccountStatus | null,
  ne?: AccountStatus | null,
};

export type Account = {
  __typename: "Account",
  id: string,
  shortName?: string | null,
  type: AccountType,
  linkedBankAccounts?: ModelBankAccountConnection | null,
  referenceDocs?: ModelDocumentConnection | null,
  attributes?: string | null,
  questionnaire?: string | null,
  owner: string,
  status: AccountStatus,
  comments?:  Array<Comment | null > | null,
  updatedBy: string,
  createdBy: string,
  createdAt: string,
  updatedAt: string,
};

export type ModelBankAccountConnection = {
  __typename: "ModelBankAccountConnection",
  items:  Array<BankAccount | null >,
  nextToken?: string | null,
};

export type BankAccount = {
  __typename: "BankAccount",
  id: string,
  bankName: string,
  accountNumber: string,
  accountHolderName?: string | null,
  accountCurrency?: string | null,
  default?: boolean | null,
  disabled?: boolean | null,
  status?: VerificationStatus | null,
  owner: string,
  comments?:  Array<Comment | null > | null,
  verifiedBy?: string | null,
  accountId: string,
  account: Account,
  updatedBy: string,
  createdAt: string,
  updatedAt: string,
};

export enum VerificationStatus {
  PENDING = "PENDING",
  VERIFIED = "VERIFIED",
  SUSPICIOUS = "SUSPICIOUS",
}


export type Comment = {
  __typename: "Comment",
  message: string,
  commentedBy: string,
  commentedAt: number,
};

export type ModelDocumentConnection = {
  __typename: "ModelDocumentConnection",
  items:  Array<Document | null >,
  nextToken?: string | null,
};

export type Document = {
  __typename: "Document",
  id: string,
  docType: DocType,
  docCategory: DocCategory,
  pages?:  Array<S3Object | null > | null,
  status?: VerificationStatus | null,
  comments?:  Array<Comment | null > | null,
  verifiedBy?: string | null,
  extractedData?: string | null,
  owner: string,
  accountId: string,
  account: Account,
  updatedBy: string,
  createdAt: string,
  updatedAt: string,
};

export enum DocType {
  MYKAD = "MYKAD",
  PASSPORT = "PASSPORT",
  SSM = "SSM",
  CREDIT_REPORT = "CREDIT_REPORT",
  SELFIE = "SELFIE",
  AUTHLETTER = "AUTHLETTER",
}


export enum DocCategory {
  INDIVIDUAL = "INDIVIDUAL",
  BUSINESS = "BUSINESS",
}


export type S3Object = {
  __typename: "S3Object",
  bucket: string,
  key: string,
  region: string,
};

export type UpdateAccountInput = {
  id: string,
  shortName?: string | null,
  type?: AccountType | null,
  attributes?: string | null,
  questionnaire?: string | null,
  owner?: string | null,
  status?: AccountStatus | null,
  comments?: Array< CommentInput | null > | null,
  updatedBy?: string | null,
  createdBy?: string | null,
};

export type DeleteAccountInput = {
  id: string,
};

export type CreateBankAccountInput = {
  id?: string | null,
  bankName: string,
  accountNumber: string,
  accountHolderName?: string | null,
  accountCurrency?: string | null,
  default?: boolean | null,
  disabled?: boolean | null,
  status?: VerificationStatus | null,
  owner: string,
  comments?: Array< CommentInput | null > | null,
  verifiedBy?: string | null,
  accountId: string,
  updatedBy: string,
};

export type ModelBankAccountConditionInput = {
  bankName?: ModelStringInput | null,
  accountNumber?: ModelStringInput | null,
  accountHolderName?: ModelStringInput | null,
  accountCurrency?: ModelStringInput | null,
  default?: ModelBooleanInput | null,
  disabled?: ModelBooleanInput | null,
  status?: ModelVerificationStatusInput | null,
  owner?: ModelStringInput | null,
  verifiedBy?: ModelStringInput | null,
  accountId?: ModelIDInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelBankAccountConditionInput | null > | null,
  or?: Array< ModelBankAccountConditionInput | null > | null,
  not?: ModelBankAccountConditionInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelVerificationStatusInput = {
  eq?: VerificationStatus | null,
  ne?: VerificationStatus | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type UpdateBankAccountInput = {
  id: string,
  bankName?: string | null,
  accountNumber?: string | null,
  accountHolderName?: string | null,
  accountCurrency?: string | null,
  default?: boolean | null,
  disabled?: boolean | null,
  status?: VerificationStatus | null,
  owner?: string | null,
  comments?: Array< CommentInput | null > | null,
  verifiedBy?: string | null,
  accountId?: string | null,
  updatedBy?: string | null,
};

export type DeleteBankAccountInput = {
  id: string,
};

export type CreateAccountUserInput = {
  accountId: string,
  userId: string,
  role?: string | null,
  updatedBy: string,
  id?: string | null,
};

export type ModelAccountUserConditionInput = {
  accountId?: ModelIDInput | null,
  userId?: ModelStringInput | null,
  role?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelAccountUserConditionInput | null > | null,
  or?: Array< ModelAccountUserConditionInput | null > | null,
  not?: ModelAccountUserConditionInput | null,
};

export type AccountUser = {
  __typename: "AccountUser",
  accountId: string,
  userId: string,
  role?: string | null,
  account: Account,
  updatedBy: string,
  id: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateAccountUserInput = {
  accountId?: string | null,
  userId?: string | null,
  role?: string | null,
  updatedBy?: string | null,
  id: string,
};

export type DeleteAccountUserInput = {
  id: string,
};

export type CreateDocumentInput = {
  id?: string | null,
  docType: DocType,
  docCategory: DocCategory,
  pages?: Array< S3ObjectInput | null > | null,
  status?: VerificationStatus | null,
  comments?: Array< CommentInput | null > | null,
  verifiedBy?: string | null,
  extractedData?: string | null,
  owner: string,
  accountId: string,
  updatedBy: string,
};

export type S3ObjectInput = {
  bucket: string,
  key: string,
  region: string,
};

export type ModelDocumentConditionInput = {
  docType?: ModelDocTypeInput | null,
  docCategory?: ModelDocCategoryInput | null,
  status?: ModelVerificationStatusInput | null,
  verifiedBy?: ModelStringInput | null,
  extractedData?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  accountId?: ModelIDInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelDocumentConditionInput | null > | null,
  or?: Array< ModelDocumentConditionInput | null > | null,
  not?: ModelDocumentConditionInput | null,
};

export type ModelDocTypeInput = {
  eq?: DocType | null,
  ne?: DocType | null,
};

export type ModelDocCategoryInput = {
  eq?: DocCategory | null,
  ne?: DocCategory | null,
};

export type UpdateDocumentInput = {
  id: string,
  docType?: DocType | null,
  docCategory?: DocCategory | null,
  pages?: Array< S3ObjectInput | null > | null,
  status?: VerificationStatus | null,
  comments?: Array< CommentInput | null > | null,
  verifiedBy?: string | null,
  extractedData?: string | null,
  owner?: string | null,
  accountId?: string | null,
  updatedBy?: string | null,
};

export type DeleteDocumentInput = {
  id: string,
};

export type CreateUserPreferenceInput = {
  userId: string,
  preferences?: string | null,
  updatedBy: string,
};

export type ModelUserPreferenceConditionInput = {
  preferences?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelUserPreferenceConditionInput | null > | null,
  or?: Array< ModelUserPreferenceConditionInput | null > | null,
  not?: ModelUserPreferenceConditionInput | null,
};

export type UserPreference = {
  __typename: "UserPreference",
  userId: string,
  preferences?: string | null,
  updatedBy: string,
  createdAt: string,
  updatedAt: string,
  id?: string | null,
};

export type UpdateUserPreferenceInput = {
  userId: string,
  preferences?: string | null,
  updatedBy?: string | null,
};

export type DeleteUserPreferenceInput = {
  userId: string,
};

export type CreateUserInput = {
  username: string,
};

export type User = {
  __typename: "User",
  Username: string,
  Attributes?:  Array<Attribute | null > | null,
  UserCreateDate?: string | null,
  UserLastModifiedDate?: string | null,
  Enabled?: boolean | null,
  UserStatus?: UserStatus | null,
  MFAOptions?:  Array<MFAOption | null > | null,
  PreferredMfaSetting?: string | null,
  UserMFASettingList?: string | null,
  Groups?: Array< string | null > | null,
};

export type Attribute = {
  __typename: "Attribute",
  Name: string,
  Value?: string | null,
};

export enum UserStatus {
  UNCONFIRMED = "UNCONFIRMED",
  CONFIRMED = "CONFIRMED",
  ARCHIVED = "ARCHIVED",
  COMPROMISED = "COMPROMISED",
  UNKNOWN = "UNKNOWN",
  RESET_REQUIRED = "RESET_REQUIRED",
  FORCE_CHANGE_PASSWORD = "FORCE_CHANGE_PASSWORD",
  EXTERNAL_PROVIDER = "EXTERNAL_PROVIDER",
}


export type MFAOption = {
  __typename: "MFAOption",
  DeliveryMedium?: string | null,
  AttributeName?: string | null,
};

export type UserStatusInput = {
  username: string,
  enabled: boolean,
};

export type Administrator = {
  username: string,
  isAdmin: boolean,
};

export type ModelAccountFilterInput = {
  id?: ModelIDInput | null,
  shortName?: ModelStringInput | null,
  type?: ModelAccountTypeInput | null,
  attributes?: ModelStringInput | null,
  questionnaire?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  status?: ModelAccountStatusInput | null,
  updatedBy?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  and?: Array< ModelAccountFilterInput | null > | null,
  or?: Array< ModelAccountFilterInput | null > | null,
  not?: ModelAccountFilterInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelAccountConnection = {
  __typename: "ModelAccountConnection",
  items:  Array<Account | null >,
  nextToken?: string | null,
};

export type ModelBankAccountFilterInput = {
  id?: ModelIDInput | null,
  bankName?: ModelStringInput | null,
  accountNumber?: ModelStringInput | null,
  accountHolderName?: ModelStringInput | null,
  accountCurrency?: ModelStringInput | null,
  default?: ModelBooleanInput | null,
  disabled?: ModelBooleanInput | null,
  status?: ModelVerificationStatusInput | null,
  owner?: ModelStringInput | null,
  verifiedBy?: ModelStringInput | null,
  accountId?: ModelIDInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelBankAccountFilterInput | null > | null,
  or?: Array< ModelBankAccountFilterInput | null > | null,
  not?: ModelBankAccountFilterInput | null,
};

export type ModelAccountUserFilterInput = {
  accountId?: ModelIDInput | null,
  userId?: ModelStringInput | null,
  role?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelAccountUserFilterInput | null > | null,
  or?: Array< ModelAccountUserFilterInput | null > | null,
  not?: ModelAccountUserFilterInput | null,
};

export type ModelAccountUserConnection = {
  __typename: "ModelAccountUserConnection",
  items:  Array<AccountUser | null >,
  nextToken?: string | null,
};

export type ModelDocumentFilterInput = {
  id?: ModelIDInput | null,
  docType?: ModelDocTypeInput | null,
  docCategory?: ModelDocCategoryInput | null,
  status?: ModelVerificationStatusInput | null,
  verifiedBy?: ModelStringInput | null,
  extractedData?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  accountId?: ModelIDInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelDocumentFilterInput | null > | null,
  or?: Array< ModelDocumentFilterInput | null > | null,
  not?: ModelDocumentFilterInput | null,
};

export type ModelUserPreferenceFilterInput = {
  userId?: ModelStringInput | null,
  preferences?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelUserPreferenceFilterInput | null > | null,
  or?: Array< ModelUserPreferenceFilterInput | null > | null,
  not?: ModelUserPreferenceFilterInput | null,
};

export type ModelUserPreferenceConnection = {
  __typename: "ModelUserPreferenceConnection",
  items:  Array<UserPreference | null >,
  nextToken?: string | null,
};

export type ListUsersInput = {
  filter?: string | null,
  paginationToken?: string | null,
  limit?: number | null,
};

export type ListUsersResult = {
  __typename: "ListUsersResult",
  Users?:  Array<User | null > | null,
  NextPaginationToken?: string | null,
};

export type ModelSubscriptionAccountFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  shortName?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  attributes?: ModelSubscriptionStringInput | null,
  questionnaire?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  updatedBy?: ModelSubscriptionStringInput | null,
  createdBy?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAccountFilterInput | null > | null,
  or?: Array< ModelSubscriptionAccountFilterInput | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionBankAccountFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  bankName?: ModelSubscriptionStringInput | null,
  accountNumber?: ModelSubscriptionStringInput | null,
  accountHolderName?: ModelSubscriptionStringInput | null,
  accountCurrency?: ModelSubscriptionStringInput | null,
  default?: ModelSubscriptionBooleanInput | null,
  disabled?: ModelSubscriptionBooleanInput | null,
  status?: ModelSubscriptionStringInput | null,
  verifiedBy?: ModelSubscriptionStringInput | null,
  accountId?: ModelSubscriptionIDInput | null,
  updatedBy?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionBankAccountFilterInput | null > | null,
  or?: Array< ModelSubscriptionBankAccountFilterInput | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionAccountUserFilterInput = {
  accountId?: ModelSubscriptionIDInput | null,
  role?: ModelSubscriptionStringInput | null,
  updatedBy?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAccountUserFilterInput | null > | null,
  or?: Array< ModelSubscriptionAccountUserFilterInput | null > | null,
};

export type ModelSubscriptionDocumentFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  docType?: ModelSubscriptionStringInput | null,
  docCategory?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  verifiedBy?: ModelSubscriptionStringInput | null,
  extractedData?: ModelSubscriptionStringInput | null,
  accountId?: ModelSubscriptionIDInput | null,
  updatedBy?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionDocumentFilterInput | null > | null,
  or?: Array< ModelSubscriptionDocumentFilterInput | null > | null,
};

export type ModelSubscriptionUserPreferenceFilterInput = {
  userId?: ModelSubscriptionStringInput | null,
  preferences?: ModelSubscriptionStringInput | null,
  updatedBy?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserPreferenceFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserPreferenceFilterInput | null > | null,
};

export type ExtractDocumentMutationVariables = {
  docId: string,
};

export type ExtractDocumentMutation = {
  extractDocument?: string | null,
};

export type VerifyFaceMutationVariables = {
  identityDocId: string,
  selfieDocId: string,
};

export type VerifyFaceMutation = {
  verifyFace?: string | null,
};

export type VerifyBankAccountMutationVariables = {
  bankAccountId: string,
};

export type VerifyBankAccountMutation = {
  verifyBankAccount?: string | null,
};

export type SendMessageMutationVariables = {
  input: MessageInput,
};

export type SendMessageMutation = {
  sendMessage?: string | null,
};

export type GenerateCreditReportMutationVariables = {
  reportType: CTOSReportType,
  identityDocId: string,
};

export type GenerateCreditReportMutation = {
  generateCreditReport?: string | null,
};

export type CreateAccountMutationVariables = {
  input: CreateAccountInput,
  condition?: ModelAccountConditionInput | null,
};

export type CreateAccountMutation = {
  createAccount?:  {
    __typename: "Account",
    id: string,
    shortName?: string | null,
    type: AccountType,
    linkedBankAccounts?:  {
      __typename: "ModelBankAccountConnection",
      items:  Array< {
        __typename: "BankAccount",
        id: string,
        bankName: string,
        accountNumber: string,
        accountHolderName?: string | null,
        accountCurrency?: string | null,
        default?: boolean | null,
        disabled?: boolean | null,
        status?: VerificationStatus | null,
        owner: string,
        comments?:  Array< {
          __typename: "Comment",
          message: string,
          commentedBy: string,
          commentedAt: number,
        } | null > | null,
        verifiedBy?: string | null,
        accountId: string,
        account:  {
          __typename: "Account",
          id: string,
          shortName?: string | null,
          type: AccountType,
          linkedBankAccounts?:  {
            __typename: "ModelBankAccountConnection",
            nextToken?: string | null,
          } | null,
          referenceDocs?:  {
            __typename: "ModelDocumentConnection",
            nextToken?: string | null,
          } | null,
          attributes?: string | null,
          questionnaire?: string | null,
          owner: string,
          status: AccountStatus,
          comments?:  Array< {
            __typename: "Comment",
            message: string,
            commentedBy: string,
            commentedAt: number,
          } | null > | null,
          updatedBy: string,
          createdBy: string,
          createdAt: string,
          updatedAt: string,
        },
        updatedBy: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    referenceDocs?:  {
      __typename: "ModelDocumentConnection",
      items:  Array< {
        __typename: "Document",
        id: string,
        docType: DocType,
        docCategory: DocCategory,
        pages?:  Array< {
          __typename: "S3Object",
          bucket: string,
          key: string,
          region: string,
        } | null > | null,
        status?: VerificationStatus | null,
        comments?:  Array< {
          __typename: "Comment",
          message: string,
          commentedBy: string,
          commentedAt: number,
        } | null > | null,
        verifiedBy?: string | null,
        extractedData?: string | null,
        owner: string,
        accountId: string,
        account:  {
          __typename: "Account",
          id: string,
          shortName?: string | null,
          type: AccountType,
          linkedBankAccounts?:  {
            __typename: "ModelBankAccountConnection",
            nextToken?: string | null,
          } | null,
          referenceDocs?:  {
            __typename: "ModelDocumentConnection",
            nextToken?: string | null,
          } | null,
          attributes?: string | null,
          questionnaire?: string | null,
          owner: string,
          status: AccountStatus,
          comments?:  Array< {
            __typename: "Comment",
            message: string,
            commentedBy: string,
            commentedAt: number,
          } | null > | null,
          updatedBy: string,
          createdBy: string,
          createdAt: string,
          updatedAt: string,
        },
        updatedBy: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    attributes?: string | null,
    questionnaire?: string | null,
    owner: string,
    status: AccountStatus,
    comments?:  Array< {
      __typename: "Comment",
      message: string,
      commentedBy: string,
      commentedAt: number,
    } | null > | null,
    updatedBy: string,
    createdBy: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateAccountMutationVariables = {
  input: UpdateAccountInput,
  condition?: ModelAccountConditionInput | null,
};

export type UpdateAccountMutation = {
  updateAccount?:  {
    __typename: "Account",
    id: string,
    shortName?: string | null,
    type: AccountType,
    linkedBankAccounts?:  {
      __typename: "ModelBankAccountConnection",
      items:  Array< {
        __typename: "BankAccount",
        id: string,
        bankName: string,
        accountNumber: string,
        accountHolderName?: string | null,
        accountCurrency?: string | null,
        default?: boolean | null,
        disabled?: boolean | null,
        status?: VerificationStatus | null,
        owner: string,
        comments?:  Array< {
          __typename: "Comment",
          message: string,
          commentedBy: string,
          commentedAt: number,
        } | null > | null,
        verifiedBy?: string | null,
        accountId: string,
        account:  {
          __typename: "Account",
          id: string,
          shortName?: string | null,
          type: AccountType,
          linkedBankAccounts?:  {
            __typename: "ModelBankAccountConnection",
            nextToken?: string | null,
          } | null,
          referenceDocs?:  {
            __typename: "ModelDocumentConnection",
            nextToken?: string | null,
          } | null,
          attributes?: string | null,
          questionnaire?: string | null,
          owner: string,
          status: AccountStatus,
          comments?:  Array< {
            __typename: "Comment",
            message: string,
            commentedBy: string,
            commentedAt: number,
          } | null > | null,
          updatedBy: string,
          createdBy: string,
          createdAt: string,
          updatedAt: string,
        },
        updatedBy: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    referenceDocs?:  {
      __typename: "ModelDocumentConnection",
      items:  Array< {
        __typename: "Document",
        id: string,
        docType: DocType,
        docCategory: DocCategory,
        pages?:  Array< {
          __typename: "S3Object",
          bucket: string,
          key: string,
          region: string,
        } | null > | null,
        status?: VerificationStatus | null,
        comments?:  Array< {
          __typename: "Comment",
          message: string,
          commentedBy: string,
          commentedAt: number,
        } | null > | null,
        verifiedBy?: string | null,
        extractedData?: string | null,
        owner: string,
        accountId: string,
        account:  {
          __typename: "Account",
          id: string,
          shortName?: string | null,
          type: AccountType,
          linkedBankAccounts?:  {
            __typename: "ModelBankAccountConnection",
            nextToken?: string | null,
          } | null,
          referenceDocs?:  {
            __typename: "ModelDocumentConnection",
            nextToken?: string | null,
          } | null,
          attributes?: string | null,
          questionnaire?: string | null,
          owner: string,
          status: AccountStatus,
          comments?:  Array< {
            __typename: "Comment",
            message: string,
            commentedBy: string,
            commentedAt: number,
          } | null > | null,
          updatedBy: string,
          createdBy: string,
          createdAt: string,
          updatedAt: string,
        },
        updatedBy: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    attributes?: string | null,
    questionnaire?: string | null,
    owner: string,
    status: AccountStatus,
    comments?:  Array< {
      __typename: "Comment",
      message: string,
      commentedBy: string,
      commentedAt: number,
    } | null > | null,
    updatedBy: string,
    createdBy: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteAccountMutationVariables = {
  input: DeleteAccountInput,
  condition?: ModelAccountConditionInput | null,
};

export type DeleteAccountMutation = {
  deleteAccount?:  {
    __typename: "Account",
    id: string,
    shortName?: string | null,
    type: AccountType,
    linkedBankAccounts?:  {
      __typename: "ModelBankAccountConnection",
      items:  Array< {
        __typename: "BankAccount",
        id: string,
        bankName: string,
        accountNumber: string,
        accountHolderName?: string | null,
        accountCurrency?: string | null,
        default?: boolean | null,
        disabled?: boolean | null,
        status?: VerificationStatus | null,
        owner: string,
        comments?:  Array< {
          __typename: "Comment",
          message: string,
          commentedBy: string,
          commentedAt: number,
        } | null > | null,
        verifiedBy?: string | null,
        accountId: string,
        account:  {
          __typename: "Account",
          id: string,
          shortName?: string | null,
          type: AccountType,
          linkedBankAccounts?:  {
            __typename: "ModelBankAccountConnection",
            nextToken?: string | null,
          } | null,
          referenceDocs?:  {
            __typename: "ModelDocumentConnection",
            nextToken?: string | null,
          } | null,
          attributes?: string | null,
          questionnaire?: string | null,
          owner: string,
          status: AccountStatus,
          comments?:  Array< {
            __typename: "Comment",
            message: string,
            commentedBy: string,
            commentedAt: number,
          } | null > | null,
          updatedBy: string,
          createdBy: string,
          createdAt: string,
          updatedAt: string,
        },
        updatedBy: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    referenceDocs?:  {
      __typename: "ModelDocumentConnection",
      items:  Array< {
        __typename: "Document",
        id: string,
        docType: DocType,
        docCategory: DocCategory,
        pages?:  Array< {
          __typename: "S3Object",
          bucket: string,
          key: string,
          region: string,
        } | null > | null,
        status?: VerificationStatus | null,
        comments?:  Array< {
          __typename: "Comment",
          message: string,
          commentedBy: string,
          commentedAt: number,
        } | null > | null,
        verifiedBy?: string | null,
        extractedData?: string | null,
        owner: string,
        accountId: string,
        account:  {
          __typename: "Account",
          id: string,
          shortName?: string | null,
          type: AccountType,
          linkedBankAccounts?:  {
            __typename: "ModelBankAccountConnection",
            nextToken?: string | null,
          } | null,
          referenceDocs?:  {
            __typename: "ModelDocumentConnection",
            nextToken?: string | null,
          } | null,
          attributes?: string | null,
          questionnaire?: string | null,
          owner: string,
          status: AccountStatus,
          comments?:  Array< {
            __typename: "Comment",
            message: string,
            commentedBy: string,
            commentedAt: number,
          } | null > | null,
          updatedBy: string,
          createdBy: string,
          createdAt: string,
          updatedAt: string,
        },
        updatedBy: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    attributes?: string | null,
    questionnaire?: string | null,
    owner: string,
    status: AccountStatus,
    comments?:  Array< {
      __typename: "Comment",
      message: string,
      commentedBy: string,
      commentedAt: number,
    } | null > | null,
    updatedBy: string,
    createdBy: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateBankAccountMutationVariables = {
  input: CreateBankAccountInput,
  condition?: ModelBankAccountConditionInput | null,
};

export type CreateBankAccountMutation = {
  createBankAccount?:  {
    __typename: "BankAccount",
    id: string,
    bankName: string,
    accountNumber: string,
    accountHolderName?: string | null,
    accountCurrency?: string | null,
    default?: boolean | null,
    disabled?: boolean | null,
    status?: VerificationStatus | null,
    owner: string,
    comments?:  Array< {
      __typename: "Comment",
      message: string,
      commentedBy: string,
      commentedAt: number,
    } | null > | null,
    verifiedBy?: string | null,
    accountId: string,
    account:  {
      __typename: "Account",
      id: string,
      shortName?: string | null,
      type: AccountType,
      linkedBankAccounts?:  {
        __typename: "ModelBankAccountConnection",
        items:  Array< {
          __typename: "BankAccount",
          id: string,
          bankName: string,
          accountNumber: string,
          accountHolderName?: string | null,
          accountCurrency?: string | null,
          default?: boolean | null,
          disabled?: boolean | null,
          status?: VerificationStatus | null,
          owner: string,
          comments?:  Array< {
            __typename: "Comment",
            message: string,
            commentedBy: string,
            commentedAt: number,
          } | null > | null,
          verifiedBy?: string | null,
          accountId: string,
          account:  {
            __typename: "Account",
            id: string,
            shortName?: string | null,
            type: AccountType,
            attributes?: string | null,
            questionnaire?: string | null,
            owner: string,
            status: AccountStatus,
            updatedBy: string,
            createdBy: string,
            createdAt: string,
            updatedAt: string,
          },
          updatedBy: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      referenceDocs?:  {
        __typename: "ModelDocumentConnection",
        items:  Array< {
          __typename: "Document",
          id: string,
          docType: DocType,
          docCategory: DocCategory,
          pages?:  Array< {
            __typename: "S3Object",
            bucket: string,
            key: string,
            region: string,
          } | null > | null,
          status?: VerificationStatus | null,
          comments?:  Array< {
            __typename: "Comment",
            message: string,
            commentedBy: string,
            commentedAt: number,
          } | null > | null,
          verifiedBy?: string | null,
          extractedData?: string | null,
          owner: string,
          accountId: string,
          account:  {
            __typename: "Account",
            id: string,
            shortName?: string | null,
            type: AccountType,
            attributes?: string | null,
            questionnaire?: string | null,
            owner: string,
            status: AccountStatus,
            updatedBy: string,
            createdBy: string,
            createdAt: string,
            updatedAt: string,
          },
          updatedBy: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      attributes?: string | null,
      questionnaire?: string | null,
      owner: string,
      status: AccountStatus,
      comments?:  Array< {
        __typename: "Comment",
        message: string,
        commentedBy: string,
        commentedAt: number,
      } | null > | null,
      updatedBy: string,
      createdBy: string,
      createdAt: string,
      updatedAt: string,
    },
    updatedBy: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateBankAccountMutationVariables = {
  input: UpdateBankAccountInput,
  condition?: ModelBankAccountConditionInput | null,
};

export type UpdateBankAccountMutation = {
  updateBankAccount?:  {
    __typename: "BankAccount",
    id: string,
    bankName: string,
    accountNumber: string,
    accountHolderName?: string | null,
    accountCurrency?: string | null,
    default?: boolean | null,
    disabled?: boolean | null,
    status?: VerificationStatus | null,
    owner: string,
    comments?:  Array< {
      __typename: "Comment",
      message: string,
      commentedBy: string,
      commentedAt: number,
    } | null > | null,
    verifiedBy?: string | null,
    accountId: string,
    account:  {
      __typename: "Account",
      id: string,
      shortName?: string | null,
      type: AccountType,
      linkedBankAccounts?:  {
        __typename: "ModelBankAccountConnection",
        items:  Array< {
          __typename: "BankAccount",
          id: string,
          bankName: string,
          accountNumber: string,
          accountHolderName?: string | null,
          accountCurrency?: string | null,
          default?: boolean | null,
          disabled?: boolean | null,
          status?: VerificationStatus | null,
          owner: string,
          comments?:  Array< {
            __typename: "Comment",
            message: string,
            commentedBy: string,
            commentedAt: number,
          } | null > | null,
          verifiedBy?: string | null,
          accountId: string,
          account:  {
            __typename: "Account",
            id: string,
            shortName?: string | null,
            type: AccountType,
            attributes?: string | null,
            questionnaire?: string | null,
            owner: string,
            status: AccountStatus,
            updatedBy: string,
            createdBy: string,
            createdAt: string,
            updatedAt: string,
          },
          updatedBy: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      referenceDocs?:  {
        __typename: "ModelDocumentConnection",
        items:  Array< {
          __typename: "Document",
          id: string,
          docType: DocType,
          docCategory: DocCategory,
          pages?:  Array< {
            __typename: "S3Object",
            bucket: string,
            key: string,
            region: string,
          } | null > | null,
          status?: VerificationStatus | null,
          comments?:  Array< {
            __typename: "Comment",
            message: string,
            commentedBy: string,
            commentedAt: number,
          } | null > | null,
          verifiedBy?: string | null,
          extractedData?: string | null,
          owner: string,
          accountId: string,
          account:  {
            __typename: "Account",
            id: string,
            shortName?: string | null,
            type: AccountType,
            attributes?: string | null,
            questionnaire?: string | null,
            owner: string,
            status: AccountStatus,
            updatedBy: string,
            createdBy: string,
            createdAt: string,
            updatedAt: string,
          },
          updatedBy: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      attributes?: string | null,
      questionnaire?: string | null,
      owner: string,
      status: AccountStatus,
      comments?:  Array< {
        __typename: "Comment",
        message: string,
        commentedBy: string,
        commentedAt: number,
      } | null > | null,
      updatedBy: string,
      createdBy: string,
      createdAt: string,
      updatedAt: string,
    },
    updatedBy: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteBankAccountMutationVariables = {
  input: DeleteBankAccountInput,
  condition?: ModelBankAccountConditionInput | null,
};

export type DeleteBankAccountMutation = {
  deleteBankAccount?:  {
    __typename: "BankAccount",
    id: string,
    bankName: string,
    accountNumber: string,
    accountHolderName?: string | null,
    accountCurrency?: string | null,
    default?: boolean | null,
    disabled?: boolean | null,
    status?: VerificationStatus | null,
    owner: string,
    comments?:  Array< {
      __typename: "Comment",
      message: string,
      commentedBy: string,
      commentedAt: number,
    } | null > | null,
    verifiedBy?: string | null,
    accountId: string,
    account:  {
      __typename: "Account",
      id: string,
      shortName?: string | null,
      type: AccountType,
      linkedBankAccounts?:  {
        __typename: "ModelBankAccountConnection",
        items:  Array< {
          __typename: "BankAccount",
          id: string,
          bankName: string,
          accountNumber: string,
          accountHolderName?: string | null,
          accountCurrency?: string | null,
          default?: boolean | null,
          disabled?: boolean | null,
          status?: VerificationStatus | null,
          owner: string,
          comments?:  Array< {
            __typename: "Comment",
            message: string,
            commentedBy: string,
            commentedAt: number,
          } | null > | null,
          verifiedBy?: string | null,
          accountId: string,
          account:  {
            __typename: "Account",
            id: string,
            shortName?: string | null,
            type: AccountType,
            attributes?: string | null,
            questionnaire?: string | null,
            owner: string,
            status: AccountStatus,
            updatedBy: string,
            createdBy: string,
            createdAt: string,
            updatedAt: string,
          },
          updatedBy: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      referenceDocs?:  {
        __typename: "ModelDocumentConnection",
        items:  Array< {
          __typename: "Document",
          id: string,
          docType: DocType,
          docCategory: DocCategory,
          pages?:  Array< {
            __typename: "S3Object",
            bucket: string,
            key: string,
            region: string,
          } | null > | null,
          status?: VerificationStatus | null,
          comments?:  Array< {
            __typename: "Comment",
            message: string,
            commentedBy: string,
            commentedAt: number,
          } | null > | null,
          verifiedBy?: string | null,
          extractedData?: string | null,
          owner: string,
          accountId: string,
          account:  {
            __typename: "Account",
            id: string,
            shortName?: string | null,
            type: AccountType,
            attributes?: string | null,
            questionnaire?: string | null,
            owner: string,
            status: AccountStatus,
            updatedBy: string,
            createdBy: string,
            createdAt: string,
            updatedAt: string,
          },
          updatedBy: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      attributes?: string | null,
      questionnaire?: string | null,
      owner: string,
      status: AccountStatus,
      comments?:  Array< {
        __typename: "Comment",
        message: string,
        commentedBy: string,
        commentedAt: number,
      } | null > | null,
      updatedBy: string,
      createdBy: string,
      createdAt: string,
      updatedAt: string,
    },
    updatedBy: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateAccountUserMutationVariables = {
  input: CreateAccountUserInput,
  condition?: ModelAccountUserConditionInput | null,
};

export type CreateAccountUserMutation = {
  createAccountUser?:  {
    __typename: "AccountUser",
    accountId: string,
    userId: string,
    role?: string | null,
    account:  {
      __typename: "Account",
      id: string,
      shortName?: string | null,
      type: AccountType,
      linkedBankAccounts?:  {
        __typename: "ModelBankAccountConnection",
        items:  Array< {
          __typename: "BankAccount",
          id: string,
          bankName: string,
          accountNumber: string,
          accountHolderName?: string | null,
          accountCurrency?: string | null,
          default?: boolean | null,
          disabled?: boolean | null,
          status?: VerificationStatus | null,
          owner: string,
          comments?:  Array< {
            __typename: "Comment",
            message: string,
            commentedBy: string,
            commentedAt: number,
          } | null > | null,
          verifiedBy?: string | null,
          accountId: string,
          account:  {
            __typename: "Account",
            id: string,
            shortName?: string | null,
            type: AccountType,
            attributes?: string | null,
            questionnaire?: string | null,
            owner: string,
            status: AccountStatus,
            updatedBy: string,
            createdBy: string,
            createdAt: string,
            updatedAt: string,
          },
          updatedBy: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      referenceDocs?:  {
        __typename: "ModelDocumentConnection",
        items:  Array< {
          __typename: "Document",
          id: string,
          docType: DocType,
          docCategory: DocCategory,
          pages?:  Array< {
            __typename: "S3Object",
            bucket: string,
            key: string,
            region: string,
          } | null > | null,
          status?: VerificationStatus | null,
          comments?:  Array< {
            __typename: "Comment",
            message: string,
            commentedBy: string,
            commentedAt: number,
          } | null > | null,
          verifiedBy?: string | null,
          extractedData?: string | null,
          owner: string,
          accountId: string,
          account:  {
            __typename: "Account",
            id: string,
            shortName?: string | null,
            type: AccountType,
            attributes?: string | null,
            questionnaire?: string | null,
            owner: string,
            status: AccountStatus,
            updatedBy: string,
            createdBy: string,
            createdAt: string,
            updatedAt: string,
          },
          updatedBy: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      attributes?: string | null,
      questionnaire?: string | null,
      owner: string,
      status: AccountStatus,
      comments?:  Array< {
        __typename: "Comment",
        message: string,
        commentedBy: string,
        commentedAt: number,
      } | null > | null,
      updatedBy: string,
      createdBy: string,
      createdAt: string,
      updatedAt: string,
    },
    updatedBy: string,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateAccountUserMutationVariables = {
  input: UpdateAccountUserInput,
  condition?: ModelAccountUserConditionInput | null,
};

export type UpdateAccountUserMutation = {
  updateAccountUser?:  {
    __typename: "AccountUser",
    accountId: string,
    userId: string,
    role?: string | null,
    account:  {
      __typename: "Account",
      id: string,
      shortName?: string | null,
      type: AccountType,
      linkedBankAccounts?:  {
        __typename: "ModelBankAccountConnection",
        items:  Array< {
          __typename: "BankAccount",
          id: string,
          bankName: string,
          accountNumber: string,
          accountHolderName?: string | null,
          accountCurrency?: string | null,
          default?: boolean | null,
          disabled?: boolean | null,
          status?: VerificationStatus | null,
          owner: string,
          comments?:  Array< {
            __typename: "Comment",
            message: string,
            commentedBy: string,
            commentedAt: number,
          } | null > | null,
          verifiedBy?: string | null,
          accountId: string,
          account:  {
            __typename: "Account",
            id: string,
            shortName?: string | null,
            type: AccountType,
            attributes?: string | null,
            questionnaire?: string | null,
            owner: string,
            status: AccountStatus,
            updatedBy: string,
            createdBy: string,
            createdAt: string,
            updatedAt: string,
          },
          updatedBy: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      referenceDocs?:  {
        __typename: "ModelDocumentConnection",
        items:  Array< {
          __typename: "Document",
          id: string,
          docType: DocType,
          docCategory: DocCategory,
          pages?:  Array< {
            __typename: "S3Object",
            bucket: string,
            key: string,
            region: string,
          } | null > | null,
          status?: VerificationStatus | null,
          comments?:  Array< {
            __typename: "Comment",
            message: string,
            commentedBy: string,
            commentedAt: number,
          } | null > | null,
          verifiedBy?: string | null,
          extractedData?: string | null,
          owner: string,
          accountId: string,
          account:  {
            __typename: "Account",
            id: string,
            shortName?: string | null,
            type: AccountType,
            attributes?: string | null,
            questionnaire?: string | null,
            owner: string,
            status: AccountStatus,
            updatedBy: string,
            createdBy: string,
            createdAt: string,
            updatedAt: string,
          },
          updatedBy: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      attributes?: string | null,
      questionnaire?: string | null,
      owner: string,
      status: AccountStatus,
      comments?:  Array< {
        __typename: "Comment",
        message: string,
        commentedBy: string,
        commentedAt: number,
      } | null > | null,
      updatedBy: string,
      createdBy: string,
      createdAt: string,
      updatedAt: string,
    },
    updatedBy: string,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteAccountUserMutationVariables = {
  input: DeleteAccountUserInput,
  condition?: ModelAccountUserConditionInput | null,
};

export type DeleteAccountUserMutation = {
  deleteAccountUser?:  {
    __typename: "AccountUser",
    accountId: string,
    userId: string,
    role?: string | null,
    account:  {
      __typename: "Account",
      id: string,
      shortName?: string | null,
      type: AccountType,
      linkedBankAccounts?:  {
        __typename: "ModelBankAccountConnection",
        items:  Array< {
          __typename: "BankAccount",
          id: string,
          bankName: string,
          accountNumber: string,
          accountHolderName?: string | null,
          accountCurrency?: string | null,
          default?: boolean | null,
          disabled?: boolean | null,
          status?: VerificationStatus | null,
          owner: string,
          comments?:  Array< {
            __typename: "Comment",
            message: string,
            commentedBy: string,
            commentedAt: number,
          } | null > | null,
          verifiedBy?: string | null,
          accountId: string,
          account:  {
            __typename: "Account",
            id: string,
            shortName?: string | null,
            type: AccountType,
            attributes?: string | null,
            questionnaire?: string | null,
            owner: string,
            status: AccountStatus,
            updatedBy: string,
            createdBy: string,
            createdAt: string,
            updatedAt: string,
          },
          updatedBy: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      referenceDocs?:  {
        __typename: "ModelDocumentConnection",
        items:  Array< {
          __typename: "Document",
          id: string,
          docType: DocType,
          docCategory: DocCategory,
          pages?:  Array< {
            __typename: "S3Object",
            bucket: string,
            key: string,
            region: string,
          } | null > | null,
          status?: VerificationStatus | null,
          comments?:  Array< {
            __typename: "Comment",
            message: string,
            commentedBy: string,
            commentedAt: number,
          } | null > | null,
          verifiedBy?: string | null,
          extractedData?: string | null,
          owner: string,
          accountId: string,
          account:  {
            __typename: "Account",
            id: string,
            shortName?: string | null,
            type: AccountType,
            attributes?: string | null,
            questionnaire?: string | null,
            owner: string,
            status: AccountStatus,
            updatedBy: string,
            createdBy: string,
            createdAt: string,
            updatedAt: string,
          },
          updatedBy: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      attributes?: string | null,
      questionnaire?: string | null,
      owner: string,
      status: AccountStatus,
      comments?:  Array< {
        __typename: "Comment",
        message: string,
        commentedBy: string,
        commentedAt: number,
      } | null > | null,
      updatedBy: string,
      createdBy: string,
      createdAt: string,
      updatedAt: string,
    },
    updatedBy: string,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateDocumentMutationVariables = {
  input: CreateDocumentInput,
  condition?: ModelDocumentConditionInput | null,
};

export type CreateDocumentMutation = {
  createDocument?:  {
    __typename: "Document",
    id: string,
    docType: DocType,
    docCategory: DocCategory,
    pages?:  Array< {
      __typename: "S3Object",
      bucket: string,
      key: string,
      region: string,
    } | null > | null,
    status?: VerificationStatus | null,
    comments?:  Array< {
      __typename: "Comment",
      message: string,
      commentedBy: string,
      commentedAt: number,
    } | null > | null,
    verifiedBy?: string | null,
    extractedData?: string | null,
    owner: string,
    accountId: string,
    account:  {
      __typename: "Account",
      id: string,
      shortName?: string | null,
      type: AccountType,
      linkedBankAccounts?:  {
        __typename: "ModelBankAccountConnection",
        items:  Array< {
          __typename: "BankAccount",
          id: string,
          bankName: string,
          accountNumber: string,
          accountHolderName?: string | null,
          accountCurrency?: string | null,
          default?: boolean | null,
          disabled?: boolean | null,
          status?: VerificationStatus | null,
          owner: string,
          comments?:  Array< {
            __typename: "Comment",
            message: string,
            commentedBy: string,
            commentedAt: number,
          } | null > | null,
          verifiedBy?: string | null,
          accountId: string,
          account:  {
            __typename: "Account",
            id: string,
            shortName?: string | null,
            type: AccountType,
            attributes?: string | null,
            questionnaire?: string | null,
            owner: string,
            status: AccountStatus,
            updatedBy: string,
            createdBy: string,
            createdAt: string,
            updatedAt: string,
          },
          updatedBy: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      referenceDocs?:  {
        __typename: "ModelDocumentConnection",
        items:  Array< {
          __typename: "Document",
          id: string,
          docType: DocType,
          docCategory: DocCategory,
          pages?:  Array< {
            __typename: "S3Object",
            bucket: string,
            key: string,
            region: string,
          } | null > | null,
          status?: VerificationStatus | null,
          comments?:  Array< {
            __typename: "Comment",
            message: string,
            commentedBy: string,
            commentedAt: number,
          } | null > | null,
          verifiedBy?: string | null,
          extractedData?: string | null,
          owner: string,
          accountId: string,
          account:  {
            __typename: "Account",
            id: string,
            shortName?: string | null,
            type: AccountType,
            attributes?: string | null,
            questionnaire?: string | null,
            owner: string,
            status: AccountStatus,
            updatedBy: string,
            createdBy: string,
            createdAt: string,
            updatedAt: string,
          },
          updatedBy: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      attributes?: string | null,
      questionnaire?: string | null,
      owner: string,
      status: AccountStatus,
      comments?:  Array< {
        __typename: "Comment",
        message: string,
        commentedBy: string,
        commentedAt: number,
      } | null > | null,
      updatedBy: string,
      createdBy: string,
      createdAt: string,
      updatedAt: string,
    },
    updatedBy: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateDocumentMutationVariables = {
  input: UpdateDocumentInput,
  condition?: ModelDocumentConditionInput | null,
};

export type UpdateDocumentMutation = {
  updateDocument?:  {
    __typename: "Document",
    id: string,
    docType: DocType,
    docCategory: DocCategory,
    pages?:  Array< {
      __typename: "S3Object",
      bucket: string,
      key: string,
      region: string,
    } | null > | null,
    status?: VerificationStatus | null,
    comments?:  Array< {
      __typename: "Comment",
      message: string,
      commentedBy: string,
      commentedAt: number,
    } | null > | null,
    verifiedBy?: string | null,
    extractedData?: string | null,
    owner: string,
    accountId: string,
    account:  {
      __typename: "Account",
      id: string,
      shortName?: string | null,
      type: AccountType,
      linkedBankAccounts?:  {
        __typename: "ModelBankAccountConnection",
        items:  Array< {
          __typename: "BankAccount",
          id: string,
          bankName: string,
          accountNumber: string,
          accountHolderName?: string | null,
          accountCurrency?: string | null,
          default?: boolean | null,
          disabled?: boolean | null,
          status?: VerificationStatus | null,
          owner: string,
          comments?:  Array< {
            __typename: "Comment",
            message: string,
            commentedBy: string,
            commentedAt: number,
          } | null > | null,
          verifiedBy?: string | null,
          accountId: string,
          account:  {
            __typename: "Account",
            id: string,
            shortName?: string | null,
            type: AccountType,
            attributes?: string | null,
            questionnaire?: string | null,
            owner: string,
            status: AccountStatus,
            updatedBy: string,
            createdBy: string,
            createdAt: string,
            updatedAt: string,
          },
          updatedBy: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      referenceDocs?:  {
        __typename: "ModelDocumentConnection",
        items:  Array< {
          __typename: "Document",
          id: string,
          docType: DocType,
          docCategory: DocCategory,
          pages?:  Array< {
            __typename: "S3Object",
            bucket: string,
            key: string,
            region: string,
          } | null > | null,
          status?: VerificationStatus | null,
          comments?:  Array< {
            __typename: "Comment",
            message: string,
            commentedBy: string,
            commentedAt: number,
          } | null > | null,
          verifiedBy?: string | null,
          extractedData?: string | null,
          owner: string,
          accountId: string,
          account:  {
            __typename: "Account",
            id: string,
            shortName?: string | null,
            type: AccountType,
            attributes?: string | null,
            questionnaire?: string | null,
            owner: string,
            status: AccountStatus,
            updatedBy: string,
            createdBy: string,
            createdAt: string,
            updatedAt: string,
          },
          updatedBy: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      attributes?: string | null,
      questionnaire?: string | null,
      owner: string,
      status: AccountStatus,
      comments?:  Array< {
        __typename: "Comment",
        message: string,
        commentedBy: string,
        commentedAt: number,
      } | null > | null,
      updatedBy: string,
      createdBy: string,
      createdAt: string,
      updatedAt: string,
    },
    updatedBy: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteDocumentMutationVariables = {
  input: DeleteDocumentInput,
  condition?: ModelDocumentConditionInput | null,
};

export type DeleteDocumentMutation = {
  deleteDocument?:  {
    __typename: "Document",
    id: string,
    docType: DocType,
    docCategory: DocCategory,
    pages?:  Array< {
      __typename: "S3Object",
      bucket: string,
      key: string,
      region: string,
    } | null > | null,
    status?: VerificationStatus | null,
    comments?:  Array< {
      __typename: "Comment",
      message: string,
      commentedBy: string,
      commentedAt: number,
    } | null > | null,
    verifiedBy?: string | null,
    extractedData?: string | null,
    owner: string,
    accountId: string,
    account:  {
      __typename: "Account",
      id: string,
      shortName?: string | null,
      type: AccountType,
      linkedBankAccounts?:  {
        __typename: "ModelBankAccountConnection",
        items:  Array< {
          __typename: "BankAccount",
          id: string,
          bankName: string,
          accountNumber: string,
          accountHolderName?: string | null,
          accountCurrency?: string | null,
          default?: boolean | null,
          disabled?: boolean | null,
          status?: VerificationStatus | null,
          owner: string,
          comments?:  Array< {
            __typename: "Comment",
            message: string,
            commentedBy: string,
            commentedAt: number,
          } | null > | null,
          verifiedBy?: string | null,
          accountId: string,
          account:  {
            __typename: "Account",
            id: string,
            shortName?: string | null,
            type: AccountType,
            attributes?: string | null,
            questionnaire?: string | null,
            owner: string,
            status: AccountStatus,
            updatedBy: string,
            createdBy: string,
            createdAt: string,
            updatedAt: string,
          },
          updatedBy: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      referenceDocs?:  {
        __typename: "ModelDocumentConnection",
        items:  Array< {
          __typename: "Document",
          id: string,
          docType: DocType,
          docCategory: DocCategory,
          pages?:  Array< {
            __typename: "S3Object",
            bucket: string,
            key: string,
            region: string,
          } | null > | null,
          status?: VerificationStatus | null,
          comments?:  Array< {
            __typename: "Comment",
            message: string,
            commentedBy: string,
            commentedAt: number,
          } | null > | null,
          verifiedBy?: string | null,
          extractedData?: string | null,
          owner: string,
          accountId: string,
          account:  {
            __typename: "Account",
            id: string,
            shortName?: string | null,
            type: AccountType,
            attributes?: string | null,
            questionnaire?: string | null,
            owner: string,
            status: AccountStatus,
            updatedBy: string,
            createdBy: string,
            createdAt: string,
            updatedAt: string,
          },
          updatedBy: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      attributes?: string | null,
      questionnaire?: string | null,
      owner: string,
      status: AccountStatus,
      comments?:  Array< {
        __typename: "Comment",
        message: string,
        commentedBy: string,
        commentedAt: number,
      } | null > | null,
      updatedBy: string,
      createdBy: string,
      createdAt: string,
      updatedAt: string,
    },
    updatedBy: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateUserPreferenceMutationVariables = {
  input: CreateUserPreferenceInput,
  condition?: ModelUserPreferenceConditionInput | null,
};

export type CreateUserPreferenceMutation = {
  createUserPreference?:  {
    __typename: "UserPreference",
    userId: string,
    preferences?: string | null,
    updatedBy: string,
    createdAt: string,
    updatedAt: string,
    id?: string | null,
  } | null,
};

export type UpdateUserPreferenceMutationVariables = {
  input: UpdateUserPreferenceInput,
  condition?: ModelUserPreferenceConditionInput | null,
};

export type UpdateUserPreferenceMutation = {
  updateUserPreference?:  {
    __typename: "UserPreference",
    userId: string,
    preferences?: string | null,
    updatedBy: string,
    createdAt: string,
    updatedAt: string,
    id?: string | null,
  } | null,
};

export type DeleteUserPreferenceMutationVariables = {
  input: DeleteUserPreferenceInput,
  condition?: ModelUserPreferenceConditionInput | null,
};

export type DeleteUserPreferenceMutation = {
  deleteUserPreference?:  {
    __typename: "UserPreference",
    userId: string,
    preferences?: string | null,
    updatedBy: string,
    createdAt: string,
    updatedAt: string,
    id?: string | null,
  } | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    Username: string,
    Attributes?:  Array< {
      __typename: "Attribute",
      Name: string,
      Value?: string | null,
    } | null > | null,
    UserCreateDate?: string | null,
    UserLastModifiedDate?: string | null,
    Enabled?: boolean | null,
    UserStatus?: UserStatus | null,
    MFAOptions?:  Array< {
      __typename: "MFAOption",
      DeliveryMedium?: string | null,
      AttributeName?: string | null,
    } | null > | null,
    PreferredMfaSetting?: string | null,
    UserMFASettingList?: string | null,
    Groups?: Array< string | null > | null,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UserStatusInput,
};

export type UpdateUserMutation = {
  updateUser?: string | null,
};

export type ToAdministratorGroupMutationVariables = {
  input: Administrator,
};

export type ToAdministratorGroupMutation = {
  toAdministratorGroup?: string | null,
};

export type CheckKycReadinessQueryVariables = {
  accountId: string,
};

export type CheckKycReadinessQuery = {
  checkKycReadiness?: string | null,
};

export type GetAccountQueryVariables = {
  id: string,
};

export type GetAccountQuery = {
  getAccount?:  {
    __typename: "Account",
    id: string,
    shortName?: string | null,
    type: AccountType,
    linkedBankAccounts?:  {
      __typename: "ModelBankAccountConnection",
      items:  Array< {
        __typename: "BankAccount",
        id: string,
        bankName: string,
        accountNumber: string,
        accountHolderName?: string | null,
        accountCurrency?: string | null,
        default?: boolean | null,
        disabled?: boolean | null,
        status?: VerificationStatus | null,
        owner: string,
        comments?:  Array< {
          __typename: "Comment",
          message: string,
          commentedBy: string,
          commentedAt: number,
        } | null > | null,
        verifiedBy?: string | null,
        accountId: string,
        account:  {
          __typename: "Account",
          id: string,
          shortName?: string | null,
          type: AccountType,
          linkedBankAccounts?:  {
            __typename: "ModelBankAccountConnection",
            nextToken?: string | null,
          } | null,
          referenceDocs?:  {
            __typename: "ModelDocumentConnection",
            nextToken?: string | null,
          } | null,
          attributes?: string | null,
          questionnaire?: string | null,
          owner: string,
          status: AccountStatus,
          comments?:  Array< {
            __typename: "Comment",
            message: string,
            commentedBy: string,
            commentedAt: number,
          } | null > | null,
          updatedBy: string,
          createdBy: string,
          createdAt: string,
          updatedAt: string,
        },
        updatedBy: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    referenceDocs?:  {
      __typename: "ModelDocumentConnection",
      items:  Array< {
        __typename: "Document",
        id: string,
        docType: DocType,
        docCategory: DocCategory,
        pages?:  Array< {
          __typename: "S3Object",
          bucket: string,
          key: string,
          region: string,
        } | null > | null,
        status?: VerificationStatus | null,
        comments?:  Array< {
          __typename: "Comment",
          message: string,
          commentedBy: string,
          commentedAt: number,
        } | null > | null,
        verifiedBy?: string | null,
        extractedData?: string | null,
        owner: string,
        accountId: string,
        account:  {
          __typename: "Account",
          id: string,
          shortName?: string | null,
          type: AccountType,
          linkedBankAccounts?:  {
            __typename: "ModelBankAccountConnection",
            nextToken?: string | null,
          } | null,
          referenceDocs?:  {
            __typename: "ModelDocumentConnection",
            nextToken?: string | null,
          } | null,
          attributes?: string | null,
          questionnaire?: string | null,
          owner: string,
          status: AccountStatus,
          comments?:  Array< {
            __typename: "Comment",
            message: string,
            commentedBy: string,
            commentedAt: number,
          } | null > | null,
          updatedBy: string,
          createdBy: string,
          createdAt: string,
          updatedAt: string,
        },
        updatedBy: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    attributes?: string | null,
    questionnaire?: string | null,
    owner: string,
    status: AccountStatus,
    comments?:  Array< {
      __typename: "Comment",
      message: string,
      commentedBy: string,
      commentedAt: number,
    } | null > | null,
    updatedBy: string,
    createdBy: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListAccountsQueryVariables = {
  id?: string | null,
  filter?: ModelAccountFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListAccountsQuery = {
  listAccounts?:  {
    __typename: "ModelAccountConnection",
    items:  Array< {
      __typename: "Account",
      id: string,
      shortName?: string | null,
      type: AccountType,
      linkedBankAccounts?:  {
        __typename: "ModelBankAccountConnection",
        items:  Array< {
          __typename: "BankAccount",
          id: string,
          bankName: string,
          accountNumber: string,
          accountHolderName?: string | null,
          accountCurrency?: string | null,
          default?: boolean | null,
          disabled?: boolean | null,
          status?: VerificationStatus | null,
          owner: string,
          comments?:  Array< {
            __typename: "Comment",
            message: string,
            commentedBy: string,
            commentedAt: number,
          } | null > | null,
          verifiedBy?: string | null,
          accountId: string,
          account:  {
            __typename: "Account",
            id: string,
            shortName?: string | null,
            type: AccountType,
            attributes?: string | null,
            questionnaire?: string | null,
            owner: string,
            status: AccountStatus,
            updatedBy: string,
            createdBy: string,
            createdAt: string,
            updatedAt: string,
          },
          updatedBy: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      referenceDocs?:  {
        __typename: "ModelDocumentConnection",
        items:  Array< {
          __typename: "Document",
          id: string,
          docType: DocType,
          docCategory: DocCategory,
          pages?:  Array< {
            __typename: "S3Object",
            bucket: string,
            key: string,
            region: string,
          } | null > | null,
          status?: VerificationStatus | null,
          comments?:  Array< {
            __typename: "Comment",
            message: string,
            commentedBy: string,
            commentedAt: number,
          } | null > | null,
          verifiedBy?: string | null,
          extractedData?: string | null,
          owner: string,
          accountId: string,
          account:  {
            __typename: "Account",
            id: string,
            shortName?: string | null,
            type: AccountType,
            attributes?: string | null,
            questionnaire?: string | null,
            owner: string,
            status: AccountStatus,
            updatedBy: string,
            createdBy: string,
            createdAt: string,
            updatedAt: string,
          },
          updatedBy: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      attributes?: string | null,
      questionnaire?: string | null,
      owner: string,
      status: AccountStatus,
      comments?:  Array< {
        __typename: "Comment",
        message: string,
        commentedBy: string,
        commentedAt: number,
      } | null > | null,
      updatedBy: string,
      createdBy: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAccountByShortNameQueryVariables = {
  shortName: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAccountFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetAccountByShortNameQuery = {
  getAccountByShortName?:  {
    __typename: "ModelAccountConnection",
    items:  Array< {
      __typename: "Account",
      id: string,
      shortName?: string | null,
      type: AccountType,
      linkedBankAccounts?:  {
        __typename: "ModelBankAccountConnection",
        items:  Array< {
          __typename: "BankAccount",
          id: string,
          bankName: string,
          accountNumber: string,
          accountHolderName?: string | null,
          accountCurrency?: string | null,
          default?: boolean | null,
          disabled?: boolean | null,
          status?: VerificationStatus | null,
          owner: string,
          comments?:  Array< {
            __typename: "Comment",
            message: string,
            commentedBy: string,
            commentedAt: number,
          } | null > | null,
          verifiedBy?: string | null,
          accountId: string,
          account:  {
            __typename: "Account",
            id: string,
            shortName?: string | null,
            type: AccountType,
            attributes?: string | null,
            questionnaire?: string | null,
            owner: string,
            status: AccountStatus,
            updatedBy: string,
            createdBy: string,
            createdAt: string,
            updatedAt: string,
          },
          updatedBy: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      referenceDocs?:  {
        __typename: "ModelDocumentConnection",
        items:  Array< {
          __typename: "Document",
          id: string,
          docType: DocType,
          docCategory: DocCategory,
          pages?:  Array< {
            __typename: "S3Object",
            bucket: string,
            key: string,
            region: string,
          } | null > | null,
          status?: VerificationStatus | null,
          comments?:  Array< {
            __typename: "Comment",
            message: string,
            commentedBy: string,
            commentedAt: number,
          } | null > | null,
          verifiedBy?: string | null,
          extractedData?: string | null,
          owner: string,
          accountId: string,
          account:  {
            __typename: "Account",
            id: string,
            shortName?: string | null,
            type: AccountType,
            attributes?: string | null,
            questionnaire?: string | null,
            owner: string,
            status: AccountStatus,
            updatedBy: string,
            createdBy: string,
            createdAt: string,
            updatedAt: string,
          },
          updatedBy: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      attributes?: string | null,
      questionnaire?: string | null,
      owner: string,
      status: AccountStatus,
      comments?:  Array< {
        __typename: "Comment",
        message: string,
        commentedBy: string,
        commentedAt: number,
      } | null > | null,
      updatedBy: string,
      createdBy: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAccountsByOwnerQueryVariables = {
  owner: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAccountFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetAccountsByOwnerQuery = {
  getAccountsByOwner?:  {
    __typename: "ModelAccountConnection",
    items:  Array< {
      __typename: "Account",
      id: string,
      shortName?: string | null,
      type: AccountType,
      linkedBankAccounts?:  {
        __typename: "ModelBankAccountConnection",
        items:  Array< {
          __typename: "BankAccount",
          id: string,
          bankName: string,
          accountNumber: string,
          accountHolderName?: string | null,
          accountCurrency?: string | null,
          default?: boolean | null,
          disabled?: boolean | null,
          status?: VerificationStatus | null,
          owner: string,
          comments?:  Array< {
            __typename: "Comment",
            message: string,
            commentedBy: string,
            commentedAt: number,
          } | null > | null,
          verifiedBy?: string | null,
          accountId: string,
          account:  {
            __typename: "Account",
            id: string,
            shortName?: string | null,
            type: AccountType,
            attributes?: string | null,
            questionnaire?: string | null,
            owner: string,
            status: AccountStatus,
            updatedBy: string,
            createdBy: string,
            createdAt: string,
            updatedAt: string,
          },
          updatedBy: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      referenceDocs?:  {
        __typename: "ModelDocumentConnection",
        items:  Array< {
          __typename: "Document",
          id: string,
          docType: DocType,
          docCategory: DocCategory,
          pages?:  Array< {
            __typename: "S3Object",
            bucket: string,
            key: string,
            region: string,
          } | null > | null,
          status?: VerificationStatus | null,
          comments?:  Array< {
            __typename: "Comment",
            message: string,
            commentedBy: string,
            commentedAt: number,
          } | null > | null,
          verifiedBy?: string | null,
          extractedData?: string | null,
          owner: string,
          accountId: string,
          account:  {
            __typename: "Account",
            id: string,
            shortName?: string | null,
            type: AccountType,
            attributes?: string | null,
            questionnaire?: string | null,
            owner: string,
            status: AccountStatus,
            updatedBy: string,
            createdBy: string,
            createdAt: string,
            updatedAt: string,
          },
          updatedBy: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      attributes?: string | null,
      questionnaire?: string | null,
      owner: string,
      status: AccountStatus,
      comments?:  Array< {
        __typename: "Comment",
        message: string,
        commentedBy: string,
        commentedAt: number,
      } | null > | null,
      updatedBy: string,
      createdBy: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBankAccountQueryVariables = {
  id: string,
};

export type GetBankAccountQuery = {
  getBankAccount?:  {
    __typename: "BankAccount",
    id: string,
    bankName: string,
    accountNumber: string,
    accountHolderName?: string | null,
    accountCurrency?: string | null,
    default?: boolean | null,
    disabled?: boolean | null,
    status?: VerificationStatus | null,
    owner: string,
    comments?:  Array< {
      __typename: "Comment",
      message: string,
      commentedBy: string,
      commentedAt: number,
    } | null > | null,
    verifiedBy?: string | null,
    accountId: string,
    account:  {
      __typename: "Account",
      id: string,
      shortName?: string | null,
      type: AccountType,
      linkedBankAccounts?:  {
        __typename: "ModelBankAccountConnection",
        items:  Array< {
          __typename: "BankAccount",
          id: string,
          bankName: string,
          accountNumber: string,
          accountHolderName?: string | null,
          accountCurrency?: string | null,
          default?: boolean | null,
          disabled?: boolean | null,
          status?: VerificationStatus | null,
          owner: string,
          comments?:  Array< {
            __typename: "Comment",
            message: string,
            commentedBy: string,
            commentedAt: number,
          } | null > | null,
          verifiedBy?: string | null,
          accountId: string,
          account:  {
            __typename: "Account",
            id: string,
            shortName?: string | null,
            type: AccountType,
            attributes?: string | null,
            questionnaire?: string | null,
            owner: string,
            status: AccountStatus,
            updatedBy: string,
            createdBy: string,
            createdAt: string,
            updatedAt: string,
          },
          updatedBy: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      referenceDocs?:  {
        __typename: "ModelDocumentConnection",
        items:  Array< {
          __typename: "Document",
          id: string,
          docType: DocType,
          docCategory: DocCategory,
          pages?:  Array< {
            __typename: "S3Object",
            bucket: string,
            key: string,
            region: string,
          } | null > | null,
          status?: VerificationStatus | null,
          comments?:  Array< {
            __typename: "Comment",
            message: string,
            commentedBy: string,
            commentedAt: number,
          } | null > | null,
          verifiedBy?: string | null,
          extractedData?: string | null,
          owner: string,
          accountId: string,
          account:  {
            __typename: "Account",
            id: string,
            shortName?: string | null,
            type: AccountType,
            attributes?: string | null,
            questionnaire?: string | null,
            owner: string,
            status: AccountStatus,
            updatedBy: string,
            createdBy: string,
            createdAt: string,
            updatedAt: string,
          },
          updatedBy: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      attributes?: string | null,
      questionnaire?: string | null,
      owner: string,
      status: AccountStatus,
      comments?:  Array< {
        __typename: "Comment",
        message: string,
        commentedBy: string,
        commentedAt: number,
      } | null > | null,
      updatedBy: string,
      createdBy: string,
      createdAt: string,
      updatedAt: string,
    },
    updatedBy: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListBankAccountsQueryVariables = {
  id?: string | null,
  filter?: ModelBankAccountFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListBankAccountsQuery = {
  listBankAccounts?:  {
    __typename: "ModelBankAccountConnection",
    items:  Array< {
      __typename: "BankAccount",
      id: string,
      bankName: string,
      accountNumber: string,
      accountHolderName?: string | null,
      accountCurrency?: string | null,
      default?: boolean | null,
      disabled?: boolean | null,
      status?: VerificationStatus | null,
      owner: string,
      comments?:  Array< {
        __typename: "Comment",
        message: string,
        commentedBy: string,
        commentedAt: number,
      } | null > | null,
      verifiedBy?: string | null,
      accountId: string,
      account:  {
        __typename: "Account",
        id: string,
        shortName?: string | null,
        type: AccountType,
        linkedBankAccounts?:  {
          __typename: "ModelBankAccountConnection",
          items:  Array< {
            __typename: "BankAccount",
            id: string,
            bankName: string,
            accountNumber: string,
            accountHolderName?: string | null,
            accountCurrency?: string | null,
            default?: boolean | null,
            disabled?: boolean | null,
            status?: VerificationStatus | null,
            owner: string,
            verifiedBy?: string | null,
            accountId: string,
            updatedBy: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        referenceDocs?:  {
          __typename: "ModelDocumentConnection",
          items:  Array< {
            __typename: "Document",
            id: string,
            docType: DocType,
            docCategory: DocCategory,
            status?: VerificationStatus | null,
            verifiedBy?: string | null,
            extractedData?: string | null,
            owner: string,
            accountId: string,
            updatedBy: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        attributes?: string | null,
        questionnaire?: string | null,
        owner: string,
        status: AccountStatus,
        comments?:  Array< {
          __typename: "Comment",
          message: string,
          commentedBy: string,
          commentedAt: number,
        } | null > | null,
        updatedBy: string,
        createdBy: string,
        createdAt: string,
        updatedAt: string,
      },
      updatedBy: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetLinkedBankAccountsByAccountQueryVariables = {
  accountId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBankAccountFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetLinkedBankAccountsByAccountQuery = {
  getLinkedBankAccountsByAccount?:  {
    __typename: "ModelBankAccountConnection",
    items:  Array< {
      __typename: "BankAccount",
      id: string,
      bankName: string,
      accountNumber: string,
      accountHolderName?: string | null,
      accountCurrency?: string | null,
      default?: boolean | null,
      disabled?: boolean | null,
      status?: VerificationStatus | null,
      owner: string,
      comments?:  Array< {
        __typename: "Comment",
        message: string,
        commentedBy: string,
        commentedAt: number,
      } | null > | null,
      verifiedBy?: string | null,
      accountId: string,
      account:  {
        __typename: "Account",
        id: string,
        shortName?: string | null,
        type: AccountType,
        linkedBankAccounts?:  {
          __typename: "ModelBankAccountConnection",
          items:  Array< {
            __typename: "BankAccount",
            id: string,
            bankName: string,
            accountNumber: string,
            accountHolderName?: string | null,
            accountCurrency?: string | null,
            default?: boolean | null,
            disabled?: boolean | null,
            status?: VerificationStatus | null,
            owner: string,
            verifiedBy?: string | null,
            accountId: string,
            updatedBy: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        referenceDocs?:  {
          __typename: "ModelDocumentConnection",
          items:  Array< {
            __typename: "Document",
            id: string,
            docType: DocType,
            docCategory: DocCategory,
            status?: VerificationStatus | null,
            verifiedBy?: string | null,
            extractedData?: string | null,
            owner: string,
            accountId: string,
            updatedBy: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        attributes?: string | null,
        questionnaire?: string | null,
        owner: string,
        status: AccountStatus,
        comments?:  Array< {
          __typename: "Comment",
          message: string,
          commentedBy: string,
          commentedAt: number,
        } | null > | null,
        updatedBy: string,
        createdBy: string,
        createdAt: string,
        updatedAt: string,
      },
      updatedBy: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAccountUserQueryVariables = {
  id: string,
};

export type GetAccountUserQuery = {
  getAccountUser?:  {
    __typename: "AccountUser",
    accountId: string,
    userId: string,
    role?: string | null,
    account:  {
      __typename: "Account",
      id: string,
      shortName?: string | null,
      type: AccountType,
      linkedBankAccounts?:  {
        __typename: "ModelBankAccountConnection",
        items:  Array< {
          __typename: "BankAccount",
          id: string,
          bankName: string,
          accountNumber: string,
          accountHolderName?: string | null,
          accountCurrency?: string | null,
          default?: boolean | null,
          disabled?: boolean | null,
          status?: VerificationStatus | null,
          owner: string,
          comments?:  Array< {
            __typename: "Comment",
            message: string,
            commentedBy: string,
            commentedAt: number,
          } | null > | null,
          verifiedBy?: string | null,
          accountId: string,
          account:  {
            __typename: "Account",
            id: string,
            shortName?: string | null,
            type: AccountType,
            attributes?: string | null,
            questionnaire?: string | null,
            owner: string,
            status: AccountStatus,
            updatedBy: string,
            createdBy: string,
            createdAt: string,
            updatedAt: string,
          },
          updatedBy: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      referenceDocs?:  {
        __typename: "ModelDocumentConnection",
        items:  Array< {
          __typename: "Document",
          id: string,
          docType: DocType,
          docCategory: DocCategory,
          pages?:  Array< {
            __typename: "S3Object",
            bucket: string,
            key: string,
            region: string,
          } | null > | null,
          status?: VerificationStatus | null,
          comments?:  Array< {
            __typename: "Comment",
            message: string,
            commentedBy: string,
            commentedAt: number,
          } | null > | null,
          verifiedBy?: string | null,
          extractedData?: string | null,
          owner: string,
          accountId: string,
          account:  {
            __typename: "Account",
            id: string,
            shortName?: string | null,
            type: AccountType,
            attributes?: string | null,
            questionnaire?: string | null,
            owner: string,
            status: AccountStatus,
            updatedBy: string,
            createdBy: string,
            createdAt: string,
            updatedAt: string,
          },
          updatedBy: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      attributes?: string | null,
      questionnaire?: string | null,
      owner: string,
      status: AccountStatus,
      comments?:  Array< {
        __typename: "Comment",
        message: string,
        commentedBy: string,
        commentedAt: number,
      } | null > | null,
      updatedBy: string,
      createdBy: string,
      createdAt: string,
      updatedAt: string,
    },
    updatedBy: string,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListAccountUsersQueryVariables = {
  filter?: ModelAccountUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAccountUsersQuery = {
  listAccountUsers?:  {
    __typename: "ModelAccountUserConnection",
    items:  Array< {
      __typename: "AccountUser",
      accountId: string,
      userId: string,
      role?: string | null,
      account:  {
        __typename: "Account",
        id: string,
        shortName?: string | null,
        type: AccountType,
        linkedBankAccounts?:  {
          __typename: "ModelBankAccountConnection",
          items:  Array< {
            __typename: "BankAccount",
            id: string,
            bankName: string,
            accountNumber: string,
            accountHolderName?: string | null,
            accountCurrency?: string | null,
            default?: boolean | null,
            disabled?: boolean | null,
            status?: VerificationStatus | null,
            owner: string,
            verifiedBy?: string | null,
            accountId: string,
            updatedBy: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        referenceDocs?:  {
          __typename: "ModelDocumentConnection",
          items:  Array< {
            __typename: "Document",
            id: string,
            docType: DocType,
            docCategory: DocCategory,
            status?: VerificationStatus | null,
            verifiedBy?: string | null,
            extractedData?: string | null,
            owner: string,
            accountId: string,
            updatedBy: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        attributes?: string | null,
        questionnaire?: string | null,
        owner: string,
        status: AccountStatus,
        comments?:  Array< {
          __typename: "Comment",
          message: string,
          commentedBy: string,
          commentedAt: number,
        } | null > | null,
        updatedBy: string,
        createdBy: string,
        createdAt: string,
        updatedAt: string,
      },
      updatedBy: string,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUsersByAccountQueryVariables = {
  accountId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAccountUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetUsersByAccountQuery = {
  getUsersByAccount?:  {
    __typename: "ModelAccountUserConnection",
    items:  Array< {
      __typename: "AccountUser",
      accountId: string,
      userId: string,
      role?: string | null,
      account:  {
        __typename: "Account",
        id: string,
        shortName?: string | null,
        type: AccountType,
        linkedBankAccounts?:  {
          __typename: "ModelBankAccountConnection",
          items:  Array< {
            __typename: "BankAccount",
            id: string,
            bankName: string,
            accountNumber: string,
            accountHolderName?: string | null,
            accountCurrency?: string | null,
            default?: boolean | null,
            disabled?: boolean | null,
            status?: VerificationStatus | null,
            owner: string,
            verifiedBy?: string | null,
            accountId: string,
            updatedBy: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        referenceDocs?:  {
          __typename: "ModelDocumentConnection",
          items:  Array< {
            __typename: "Document",
            id: string,
            docType: DocType,
            docCategory: DocCategory,
            status?: VerificationStatus | null,
            verifiedBy?: string | null,
            extractedData?: string | null,
            owner: string,
            accountId: string,
            updatedBy: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        attributes?: string | null,
        questionnaire?: string | null,
        owner: string,
        status: AccountStatus,
        comments?:  Array< {
          __typename: "Comment",
          message: string,
          commentedBy: string,
          commentedAt: number,
        } | null > | null,
        updatedBy: string,
        createdBy: string,
        createdAt: string,
        updatedAt: string,
      },
      updatedBy: string,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAccountsByUserQueryVariables = {
  userId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAccountUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetAccountsByUserQuery = {
  getAccountsByUser?:  {
    __typename: "ModelAccountUserConnection",
    items:  Array< {
      __typename: "AccountUser",
      accountId: string,
      userId: string,
      role?: string | null,
      account:  {
        __typename: "Account",
        id: string,
        shortName?: string | null,
        type: AccountType,
        linkedBankAccounts?:  {
          __typename: "ModelBankAccountConnection",
          items:  Array< {
            __typename: "BankAccount",
            id: string,
            bankName: string,
            accountNumber: string,
            accountHolderName?: string | null,
            accountCurrency?: string | null,
            default?: boolean | null,
            disabled?: boolean | null,
            status?: VerificationStatus | null,
            owner: string,
            verifiedBy?: string | null,
            accountId: string,
            updatedBy: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        referenceDocs?:  {
          __typename: "ModelDocumentConnection",
          items:  Array< {
            __typename: "Document",
            id: string,
            docType: DocType,
            docCategory: DocCategory,
            status?: VerificationStatus | null,
            verifiedBy?: string | null,
            extractedData?: string | null,
            owner: string,
            accountId: string,
            updatedBy: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        attributes?: string | null,
        questionnaire?: string | null,
        owner: string,
        status: AccountStatus,
        comments?:  Array< {
          __typename: "Comment",
          message: string,
          commentedBy: string,
          commentedAt: number,
        } | null > | null,
        updatedBy: string,
        createdBy: string,
        createdAt: string,
        updatedAt: string,
      },
      updatedBy: string,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDocumentQueryVariables = {
  id: string,
};

export type GetDocumentQuery = {
  getDocument?:  {
    __typename: "Document",
    id: string,
    docType: DocType,
    docCategory: DocCategory,
    pages?:  Array< {
      __typename: "S3Object",
      bucket: string,
      key: string,
      region: string,
    } | null > | null,
    status?: VerificationStatus | null,
    comments?:  Array< {
      __typename: "Comment",
      message: string,
      commentedBy: string,
      commentedAt: number,
    } | null > | null,
    verifiedBy?: string | null,
    extractedData?: string | null,
    owner: string,
    accountId: string,
    account:  {
      __typename: "Account",
      id: string,
      shortName?: string | null,
      type: AccountType,
      linkedBankAccounts?:  {
        __typename: "ModelBankAccountConnection",
        items:  Array< {
          __typename: "BankAccount",
          id: string,
          bankName: string,
          accountNumber: string,
          accountHolderName?: string | null,
          accountCurrency?: string | null,
          default?: boolean | null,
          disabled?: boolean | null,
          status?: VerificationStatus | null,
          owner: string,
          comments?:  Array< {
            __typename: "Comment",
            message: string,
            commentedBy: string,
            commentedAt: number,
          } | null > | null,
          verifiedBy?: string | null,
          accountId: string,
          account:  {
            __typename: "Account",
            id: string,
            shortName?: string | null,
            type: AccountType,
            attributes?: string | null,
            questionnaire?: string | null,
            owner: string,
            status: AccountStatus,
            updatedBy: string,
            createdBy: string,
            createdAt: string,
            updatedAt: string,
          },
          updatedBy: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      referenceDocs?:  {
        __typename: "ModelDocumentConnection",
        items:  Array< {
          __typename: "Document",
          id: string,
          docType: DocType,
          docCategory: DocCategory,
          pages?:  Array< {
            __typename: "S3Object",
            bucket: string,
            key: string,
            region: string,
          } | null > | null,
          status?: VerificationStatus | null,
          comments?:  Array< {
            __typename: "Comment",
            message: string,
            commentedBy: string,
            commentedAt: number,
          } | null > | null,
          verifiedBy?: string | null,
          extractedData?: string | null,
          owner: string,
          accountId: string,
          account:  {
            __typename: "Account",
            id: string,
            shortName?: string | null,
            type: AccountType,
            attributes?: string | null,
            questionnaire?: string | null,
            owner: string,
            status: AccountStatus,
            updatedBy: string,
            createdBy: string,
            createdAt: string,
            updatedAt: string,
          },
          updatedBy: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      attributes?: string | null,
      questionnaire?: string | null,
      owner: string,
      status: AccountStatus,
      comments?:  Array< {
        __typename: "Comment",
        message: string,
        commentedBy: string,
        commentedAt: number,
      } | null > | null,
      updatedBy: string,
      createdBy: string,
      createdAt: string,
      updatedAt: string,
    },
    updatedBy: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListDocumentsQueryVariables = {
  id?: string | null,
  filter?: ModelDocumentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListDocumentsQuery = {
  listDocuments?:  {
    __typename: "ModelDocumentConnection",
    items:  Array< {
      __typename: "Document",
      id: string,
      docType: DocType,
      docCategory: DocCategory,
      pages?:  Array< {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
      } | null > | null,
      status?: VerificationStatus | null,
      comments?:  Array< {
        __typename: "Comment",
        message: string,
        commentedBy: string,
        commentedAt: number,
      } | null > | null,
      verifiedBy?: string | null,
      extractedData?: string | null,
      owner: string,
      accountId: string,
      account:  {
        __typename: "Account",
        id: string,
        shortName?: string | null,
        type: AccountType,
        linkedBankAccounts?:  {
          __typename: "ModelBankAccountConnection",
          items:  Array< {
            __typename: "BankAccount",
            id: string,
            bankName: string,
            accountNumber: string,
            accountHolderName?: string | null,
            accountCurrency?: string | null,
            default?: boolean | null,
            disabled?: boolean | null,
            status?: VerificationStatus | null,
            owner: string,
            verifiedBy?: string | null,
            accountId: string,
            updatedBy: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        referenceDocs?:  {
          __typename: "ModelDocumentConnection",
          items:  Array< {
            __typename: "Document",
            id: string,
            docType: DocType,
            docCategory: DocCategory,
            status?: VerificationStatus | null,
            verifiedBy?: string | null,
            extractedData?: string | null,
            owner: string,
            accountId: string,
            updatedBy: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        attributes?: string | null,
        questionnaire?: string | null,
        owner: string,
        status: AccountStatus,
        comments?:  Array< {
          __typename: "Comment",
          message: string,
          commentedBy: string,
          commentedAt: number,
        } | null > | null,
        updatedBy: string,
        createdBy: string,
        createdAt: string,
        updatedAt: string,
      },
      updatedBy: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetReferenceDocumentsByAccountQueryVariables = {
  accountId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDocumentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetReferenceDocumentsByAccountQuery = {
  getReferenceDocumentsByAccount?:  {
    __typename: "ModelDocumentConnection",
    items:  Array< {
      __typename: "Document",
      id: string,
      docType: DocType,
      docCategory: DocCategory,
      pages?:  Array< {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
      } | null > | null,
      status?: VerificationStatus | null,
      comments?:  Array< {
        __typename: "Comment",
        message: string,
        commentedBy: string,
        commentedAt: number,
      } | null > | null,
      verifiedBy?: string | null,
      extractedData?: string | null,
      owner: string,
      accountId: string,
      account:  {
        __typename: "Account",
        id: string,
        shortName?: string | null,
        type: AccountType,
        linkedBankAccounts?:  {
          __typename: "ModelBankAccountConnection",
          items:  Array< {
            __typename: "BankAccount",
            id: string,
            bankName: string,
            accountNumber: string,
            accountHolderName?: string | null,
            accountCurrency?: string | null,
            default?: boolean | null,
            disabled?: boolean | null,
            status?: VerificationStatus | null,
            owner: string,
            verifiedBy?: string | null,
            accountId: string,
            updatedBy: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        referenceDocs?:  {
          __typename: "ModelDocumentConnection",
          items:  Array< {
            __typename: "Document",
            id: string,
            docType: DocType,
            docCategory: DocCategory,
            status?: VerificationStatus | null,
            verifiedBy?: string | null,
            extractedData?: string | null,
            owner: string,
            accountId: string,
            updatedBy: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        attributes?: string | null,
        questionnaire?: string | null,
        owner: string,
        status: AccountStatus,
        comments?:  Array< {
          __typename: "Comment",
          message: string,
          commentedBy: string,
          commentedAt: number,
        } | null > | null,
        updatedBy: string,
        createdBy: string,
        createdAt: string,
        updatedAt: string,
      },
      updatedBy: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserPreferenceQueryVariables = {
  userId: string,
};

export type GetUserPreferenceQuery = {
  getUserPreference?:  {
    __typename: "UserPreference",
    userId: string,
    preferences?: string | null,
    updatedBy: string,
    createdAt: string,
    updatedAt: string,
    id?: string | null,
  } | null,
};

export type ListUserPreferencesQueryVariables = {
  userId?: string | null,
  filter?: ModelUserPreferenceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListUserPreferencesQuery = {
  listUserPreferences?:  {
    __typename: "ModelUserPreferenceConnection",
    items:  Array< {
      __typename: "UserPreference",
      userId: string,
      preferences?: string | null,
      updatedBy: string,
      createdAt: string,
      updatedAt: string,
      id?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListUsersQueryVariables = {
  input?: ListUsersInput | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ListUsersResult",
    Users?:  Array< {
      __typename: "User",
      Username: string,
      Attributes?:  Array< {
        __typename: "Attribute",
        Name: string,
        Value?: string | null,
      } | null > | null,
      UserCreateDate?: string | null,
      UserLastModifiedDate?: string | null,
      Enabled?: boolean | null,
      UserStatus?: UserStatus | null,
      MFAOptions?:  Array< {
        __typename: "MFAOption",
        DeliveryMedium?: string | null,
        AttributeName?: string | null,
      } | null > | null,
      PreferredMfaSetting?: string | null,
      UserMFASettingList?: string | null,
      Groups?: Array< string | null > | null,
    } | null > | null,
    NextPaginationToken?: string | null,
  } | null,
};

export type GetUserByUserIdQueryVariables = {
  userId: string,
};

export type GetUserByUserIdQuery = {
  getUserByUserId?:  {
    __typename: "User",
    Username: string,
    Attributes?:  Array< {
      __typename: "Attribute",
      Name: string,
      Value?: string | null,
    } | null > | null,
    UserCreateDate?: string | null,
    UserLastModifiedDate?: string | null,
    Enabled?: boolean | null,
    UserStatus?: UserStatus | null,
    MFAOptions?:  Array< {
      __typename: "MFAOption",
      DeliveryMedium?: string | null,
      AttributeName?: string | null,
    } | null > | null,
    PreferredMfaSetting?: string | null,
    UserMFASettingList?: string | null,
    Groups?: Array< string | null > | null,
  } | null,
};

export type GetAccountUsersQueryVariables = {
  accountId: string,
};

export type GetAccountUsersQuery = {
  getAccountUsers?:  Array< {
    __typename: "User",
    Username: string,
    Attributes?:  Array< {
      __typename: "Attribute",
      Name: string,
      Value?: string | null,
    } | null > | null,
    UserCreateDate?: string | null,
    UserLastModifiedDate?: string | null,
    Enabled?: boolean | null,
    UserStatus?: UserStatus | null,
    MFAOptions?:  Array< {
      __typename: "MFAOption",
      DeliveryMedium?: string | null,
      AttributeName?: string | null,
    } | null > | null,
    PreferredMfaSetting?: string | null,
    UserMFASettingList?: string | null,
    Groups?: Array< string | null > | null,
  } | null > | null,
};

export type GetUserByEmailQueryVariables = {
  email: string,
};

export type GetUserByEmailQuery = {
  getUserByEmail?:  {
    __typename: "User",
    Username: string,
    Attributes?:  Array< {
      __typename: "Attribute",
      Name: string,
      Value?: string | null,
    } | null > | null,
    UserCreateDate?: string | null,
    UserLastModifiedDate?: string | null,
    Enabled?: boolean | null,
    UserStatus?: UserStatus | null,
    MFAOptions?:  Array< {
      __typename: "MFAOption",
      DeliveryMedium?: string | null,
      AttributeName?: string | null,
    } | null > | null,
    PreferredMfaSetting?: string | null,
    UserMFASettingList?: string | null,
    Groups?: Array< string | null > | null,
  } | null,
};

export type GetUserByEmailFilterQueryVariables = {
  email: string,
};

export type GetUserByEmailFilterQuery = {
  getUserByEmailFilter?:  Array< {
    __typename: "User",
    Username: string,
    Attributes?:  Array< {
      __typename: "Attribute",
      Name: string,
      Value?: string | null,
    } | null > | null,
    UserCreateDate?: string | null,
    UserLastModifiedDate?: string | null,
    Enabled?: boolean | null,
    UserStatus?: UserStatus | null,
    MFAOptions?:  Array< {
      __typename: "MFAOption",
      DeliveryMedium?: string | null,
      AttributeName?: string | null,
    } | null > | null,
    PreferredMfaSetting?: string | null,
    UserMFASettingList?: string | null,
    Groups?: Array< string | null > | null,
  } | null > | null,
};

export type GetUserByPhoneNumberQueryVariables = {
  phoneNumber: string,
};

export type GetUserByPhoneNumberQuery = {
  getUserByPhoneNumber?:  {
    __typename: "User",
    Username: string,
    Attributes?:  Array< {
      __typename: "Attribute",
      Name: string,
      Value?: string | null,
    } | null > | null,
    UserCreateDate?: string | null,
    UserLastModifiedDate?: string | null,
    Enabled?: boolean | null,
    UserStatus?: UserStatus | null,
    MFAOptions?:  Array< {
      __typename: "MFAOption",
      DeliveryMedium?: string | null,
      AttributeName?: string | null,
    } | null > | null,
    PreferredMfaSetting?: string | null,
    UserMFASettingList?: string | null,
    Groups?: Array< string | null > | null,
  } | null,
};

export type ListGroupsForUserQueryVariables = {
  userId: string,
};

export type ListGroupsForUserQuery = {
  listGroupsForUser?: Array< string | null > | null,
};

export type CheckShortNameAvailabilityQueryVariables = {
  shortName: string,
  accountId?: string | null,
};

export type CheckShortNameAvailabilityQuery = {
  checkShortNameAvailability?:  {
    __typename: "User",
    Username: string,
    Attributes?:  Array< {
      __typename: "Attribute",
      Name: string,
      Value?: string | null,
    } | null > | null,
    UserCreateDate?: string | null,
    UserLastModifiedDate?: string | null,
    Enabled?: boolean | null,
    UserStatus?: UserStatus | null,
    MFAOptions?:  Array< {
      __typename: "MFAOption",
      DeliveryMedium?: string | null,
      AttributeName?: string | null,
    } | null > | null,
    PreferredMfaSetting?: string | null,
    UserMFASettingList?: string | null,
    Groups?: Array< string | null > | null,
  } | null,
};

export type ListAllGroupsQueryVariables = {
};

export type ListAllGroupsQuery = {
  listAllGroups?:  Array< {
    __typename: "User",
    Username: string,
    Attributes?:  Array< {
      __typename: "Attribute",
      Name: string,
      Value?: string | null,
    } | null > | null,
    UserCreateDate?: string | null,
    UserLastModifiedDate?: string | null,
    Enabled?: boolean | null,
    UserStatus?: UserStatus | null,
    MFAOptions?:  Array< {
      __typename: "MFAOption",
      DeliveryMedium?: string | null,
      AttributeName?: string | null,
    } | null > | null,
    PreferredMfaSetting?: string | null,
    UserMFASettingList?: string | null,
    Groups?: Array< string | null > | null,
  } | null > | null,
};

export type ListUsersInGroupQueryVariables = {
  groupName: string,
};

export type ListUsersInGroupQuery = {
  listUsersInGroup?:  Array< {
    __typename: "User",
    Username: string,
    Attributes?:  Array< {
      __typename: "Attribute",
      Name: string,
      Value?: string | null,
    } | null > | null,
    UserCreateDate?: string | null,
    UserLastModifiedDate?: string | null,
    Enabled?: boolean | null,
    UserStatus?: UserStatus | null,
    MFAOptions?:  Array< {
      __typename: "MFAOption",
      DeliveryMedium?: string | null,
      AttributeName?: string | null,
    } | null > | null,
    PreferredMfaSetting?: string | null,
    UserMFASettingList?: string | null,
    Groups?: Array< string | null > | null,
  } | null > | null,
};

export type OnCreateAccountSubscriptionVariables = {
  filter?: ModelSubscriptionAccountFilterInput | null,
  owner?: string | null,
};

export type OnCreateAccountSubscription = {
  onCreateAccount?:  {
    __typename: "Account",
    id: string,
    shortName?: string | null,
    type: AccountType,
    linkedBankAccounts?:  {
      __typename: "ModelBankAccountConnection",
      items:  Array< {
        __typename: "BankAccount",
        id: string,
        bankName: string,
        accountNumber: string,
        accountHolderName?: string | null,
        accountCurrency?: string | null,
        default?: boolean | null,
        disabled?: boolean | null,
        status?: VerificationStatus | null,
        owner: string,
        comments?:  Array< {
          __typename: "Comment",
          message: string,
          commentedBy: string,
          commentedAt: number,
        } | null > | null,
        verifiedBy?: string | null,
        accountId: string,
        account:  {
          __typename: "Account",
          id: string,
          shortName?: string | null,
          type: AccountType,
          linkedBankAccounts?:  {
            __typename: "ModelBankAccountConnection",
            nextToken?: string | null,
          } | null,
          referenceDocs?:  {
            __typename: "ModelDocumentConnection",
            nextToken?: string | null,
          } | null,
          attributes?: string | null,
          questionnaire?: string | null,
          owner: string,
          status: AccountStatus,
          comments?:  Array< {
            __typename: "Comment",
            message: string,
            commentedBy: string,
            commentedAt: number,
          } | null > | null,
          updatedBy: string,
          createdBy: string,
          createdAt: string,
          updatedAt: string,
        },
        updatedBy: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    referenceDocs?:  {
      __typename: "ModelDocumentConnection",
      items:  Array< {
        __typename: "Document",
        id: string,
        docType: DocType,
        docCategory: DocCategory,
        pages?:  Array< {
          __typename: "S3Object",
          bucket: string,
          key: string,
          region: string,
        } | null > | null,
        status?: VerificationStatus | null,
        comments?:  Array< {
          __typename: "Comment",
          message: string,
          commentedBy: string,
          commentedAt: number,
        } | null > | null,
        verifiedBy?: string | null,
        extractedData?: string | null,
        owner: string,
        accountId: string,
        account:  {
          __typename: "Account",
          id: string,
          shortName?: string | null,
          type: AccountType,
          linkedBankAccounts?:  {
            __typename: "ModelBankAccountConnection",
            nextToken?: string | null,
          } | null,
          referenceDocs?:  {
            __typename: "ModelDocumentConnection",
            nextToken?: string | null,
          } | null,
          attributes?: string | null,
          questionnaire?: string | null,
          owner: string,
          status: AccountStatus,
          comments?:  Array< {
            __typename: "Comment",
            message: string,
            commentedBy: string,
            commentedAt: number,
          } | null > | null,
          updatedBy: string,
          createdBy: string,
          createdAt: string,
          updatedAt: string,
        },
        updatedBy: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    attributes?: string | null,
    questionnaire?: string | null,
    owner: string,
    status: AccountStatus,
    comments?:  Array< {
      __typename: "Comment",
      message: string,
      commentedBy: string,
      commentedAt: number,
    } | null > | null,
    updatedBy: string,
    createdBy: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateAccountSubscriptionVariables = {
  filter?: ModelSubscriptionAccountFilterInput | null,
  owner?: string | null,
};

export type OnUpdateAccountSubscription = {
  onUpdateAccount?:  {
    __typename: "Account",
    id: string,
    shortName?: string | null,
    type: AccountType,
    linkedBankAccounts?:  {
      __typename: "ModelBankAccountConnection",
      items:  Array< {
        __typename: "BankAccount",
        id: string,
        bankName: string,
        accountNumber: string,
        accountHolderName?: string | null,
        accountCurrency?: string | null,
        default?: boolean | null,
        disabled?: boolean | null,
        status?: VerificationStatus | null,
        owner: string,
        comments?:  Array< {
          __typename: "Comment",
          message: string,
          commentedBy: string,
          commentedAt: number,
        } | null > | null,
        verifiedBy?: string | null,
        accountId: string,
        account:  {
          __typename: "Account",
          id: string,
          shortName?: string | null,
          type: AccountType,
          linkedBankAccounts?:  {
            __typename: "ModelBankAccountConnection",
            nextToken?: string | null,
          } | null,
          referenceDocs?:  {
            __typename: "ModelDocumentConnection",
            nextToken?: string | null,
          } | null,
          attributes?: string | null,
          questionnaire?: string | null,
          owner: string,
          status: AccountStatus,
          comments?:  Array< {
            __typename: "Comment",
            message: string,
            commentedBy: string,
            commentedAt: number,
          } | null > | null,
          updatedBy: string,
          createdBy: string,
          createdAt: string,
          updatedAt: string,
        },
        updatedBy: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    referenceDocs?:  {
      __typename: "ModelDocumentConnection",
      items:  Array< {
        __typename: "Document",
        id: string,
        docType: DocType,
        docCategory: DocCategory,
        pages?:  Array< {
          __typename: "S3Object",
          bucket: string,
          key: string,
          region: string,
        } | null > | null,
        status?: VerificationStatus | null,
        comments?:  Array< {
          __typename: "Comment",
          message: string,
          commentedBy: string,
          commentedAt: number,
        } | null > | null,
        verifiedBy?: string | null,
        extractedData?: string | null,
        owner: string,
        accountId: string,
        account:  {
          __typename: "Account",
          id: string,
          shortName?: string | null,
          type: AccountType,
          linkedBankAccounts?:  {
            __typename: "ModelBankAccountConnection",
            nextToken?: string | null,
          } | null,
          referenceDocs?:  {
            __typename: "ModelDocumentConnection",
            nextToken?: string | null,
          } | null,
          attributes?: string | null,
          questionnaire?: string | null,
          owner: string,
          status: AccountStatus,
          comments?:  Array< {
            __typename: "Comment",
            message: string,
            commentedBy: string,
            commentedAt: number,
          } | null > | null,
          updatedBy: string,
          createdBy: string,
          createdAt: string,
          updatedAt: string,
        },
        updatedBy: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    attributes?: string | null,
    questionnaire?: string | null,
    owner: string,
    status: AccountStatus,
    comments?:  Array< {
      __typename: "Comment",
      message: string,
      commentedBy: string,
      commentedAt: number,
    } | null > | null,
    updatedBy: string,
    createdBy: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteAccountSubscriptionVariables = {
  filter?: ModelSubscriptionAccountFilterInput | null,
  owner?: string | null,
};

export type OnDeleteAccountSubscription = {
  onDeleteAccount?:  {
    __typename: "Account",
    id: string,
    shortName?: string | null,
    type: AccountType,
    linkedBankAccounts?:  {
      __typename: "ModelBankAccountConnection",
      items:  Array< {
        __typename: "BankAccount",
        id: string,
        bankName: string,
        accountNumber: string,
        accountHolderName?: string | null,
        accountCurrency?: string | null,
        default?: boolean | null,
        disabled?: boolean | null,
        status?: VerificationStatus | null,
        owner: string,
        comments?:  Array< {
          __typename: "Comment",
          message: string,
          commentedBy: string,
          commentedAt: number,
        } | null > | null,
        verifiedBy?: string | null,
        accountId: string,
        account:  {
          __typename: "Account",
          id: string,
          shortName?: string | null,
          type: AccountType,
          linkedBankAccounts?:  {
            __typename: "ModelBankAccountConnection",
            nextToken?: string | null,
          } | null,
          referenceDocs?:  {
            __typename: "ModelDocumentConnection",
            nextToken?: string | null,
          } | null,
          attributes?: string | null,
          questionnaire?: string | null,
          owner: string,
          status: AccountStatus,
          comments?:  Array< {
            __typename: "Comment",
            message: string,
            commentedBy: string,
            commentedAt: number,
          } | null > | null,
          updatedBy: string,
          createdBy: string,
          createdAt: string,
          updatedAt: string,
        },
        updatedBy: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    referenceDocs?:  {
      __typename: "ModelDocumentConnection",
      items:  Array< {
        __typename: "Document",
        id: string,
        docType: DocType,
        docCategory: DocCategory,
        pages?:  Array< {
          __typename: "S3Object",
          bucket: string,
          key: string,
          region: string,
        } | null > | null,
        status?: VerificationStatus | null,
        comments?:  Array< {
          __typename: "Comment",
          message: string,
          commentedBy: string,
          commentedAt: number,
        } | null > | null,
        verifiedBy?: string | null,
        extractedData?: string | null,
        owner: string,
        accountId: string,
        account:  {
          __typename: "Account",
          id: string,
          shortName?: string | null,
          type: AccountType,
          linkedBankAccounts?:  {
            __typename: "ModelBankAccountConnection",
            nextToken?: string | null,
          } | null,
          referenceDocs?:  {
            __typename: "ModelDocumentConnection",
            nextToken?: string | null,
          } | null,
          attributes?: string | null,
          questionnaire?: string | null,
          owner: string,
          status: AccountStatus,
          comments?:  Array< {
            __typename: "Comment",
            message: string,
            commentedBy: string,
            commentedAt: number,
          } | null > | null,
          updatedBy: string,
          createdBy: string,
          createdAt: string,
          updatedAt: string,
        },
        updatedBy: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    attributes?: string | null,
    questionnaire?: string | null,
    owner: string,
    status: AccountStatus,
    comments?:  Array< {
      __typename: "Comment",
      message: string,
      commentedBy: string,
      commentedAt: number,
    } | null > | null,
    updatedBy: string,
    createdBy: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateBankAccountSubscriptionVariables = {
  filter?: ModelSubscriptionBankAccountFilterInput | null,
  owner?: string | null,
};

export type OnCreateBankAccountSubscription = {
  onCreateBankAccount?:  {
    __typename: "BankAccount",
    id: string,
    bankName: string,
    accountNumber: string,
    accountHolderName?: string | null,
    accountCurrency?: string | null,
    default?: boolean | null,
    disabled?: boolean | null,
    status?: VerificationStatus | null,
    owner: string,
    comments?:  Array< {
      __typename: "Comment",
      message: string,
      commentedBy: string,
      commentedAt: number,
    } | null > | null,
    verifiedBy?: string | null,
    accountId: string,
    account:  {
      __typename: "Account",
      id: string,
      shortName?: string | null,
      type: AccountType,
      linkedBankAccounts?:  {
        __typename: "ModelBankAccountConnection",
        items:  Array< {
          __typename: "BankAccount",
          id: string,
          bankName: string,
          accountNumber: string,
          accountHolderName?: string | null,
          accountCurrency?: string | null,
          default?: boolean | null,
          disabled?: boolean | null,
          status?: VerificationStatus | null,
          owner: string,
          comments?:  Array< {
            __typename: "Comment",
            message: string,
            commentedBy: string,
            commentedAt: number,
          } | null > | null,
          verifiedBy?: string | null,
          accountId: string,
          account:  {
            __typename: "Account",
            id: string,
            shortName?: string | null,
            type: AccountType,
            attributes?: string | null,
            questionnaire?: string | null,
            owner: string,
            status: AccountStatus,
            updatedBy: string,
            createdBy: string,
            createdAt: string,
            updatedAt: string,
          },
          updatedBy: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      referenceDocs?:  {
        __typename: "ModelDocumentConnection",
        items:  Array< {
          __typename: "Document",
          id: string,
          docType: DocType,
          docCategory: DocCategory,
          pages?:  Array< {
            __typename: "S3Object",
            bucket: string,
            key: string,
            region: string,
          } | null > | null,
          status?: VerificationStatus | null,
          comments?:  Array< {
            __typename: "Comment",
            message: string,
            commentedBy: string,
            commentedAt: number,
          } | null > | null,
          verifiedBy?: string | null,
          extractedData?: string | null,
          owner: string,
          accountId: string,
          account:  {
            __typename: "Account",
            id: string,
            shortName?: string | null,
            type: AccountType,
            attributes?: string | null,
            questionnaire?: string | null,
            owner: string,
            status: AccountStatus,
            updatedBy: string,
            createdBy: string,
            createdAt: string,
            updatedAt: string,
          },
          updatedBy: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      attributes?: string | null,
      questionnaire?: string | null,
      owner: string,
      status: AccountStatus,
      comments?:  Array< {
        __typename: "Comment",
        message: string,
        commentedBy: string,
        commentedAt: number,
      } | null > | null,
      updatedBy: string,
      createdBy: string,
      createdAt: string,
      updatedAt: string,
    },
    updatedBy: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateBankAccountSubscriptionVariables = {
  filter?: ModelSubscriptionBankAccountFilterInput | null,
  owner?: string | null,
};

export type OnUpdateBankAccountSubscription = {
  onUpdateBankAccount?:  {
    __typename: "BankAccount",
    id: string,
    bankName: string,
    accountNumber: string,
    accountHolderName?: string | null,
    accountCurrency?: string | null,
    default?: boolean | null,
    disabled?: boolean | null,
    status?: VerificationStatus | null,
    owner: string,
    comments?:  Array< {
      __typename: "Comment",
      message: string,
      commentedBy: string,
      commentedAt: number,
    } | null > | null,
    verifiedBy?: string | null,
    accountId: string,
    account:  {
      __typename: "Account",
      id: string,
      shortName?: string | null,
      type: AccountType,
      linkedBankAccounts?:  {
        __typename: "ModelBankAccountConnection",
        items:  Array< {
          __typename: "BankAccount",
          id: string,
          bankName: string,
          accountNumber: string,
          accountHolderName?: string | null,
          accountCurrency?: string | null,
          default?: boolean | null,
          disabled?: boolean | null,
          status?: VerificationStatus | null,
          owner: string,
          comments?:  Array< {
            __typename: "Comment",
            message: string,
            commentedBy: string,
            commentedAt: number,
          } | null > | null,
          verifiedBy?: string | null,
          accountId: string,
          account:  {
            __typename: "Account",
            id: string,
            shortName?: string | null,
            type: AccountType,
            attributes?: string | null,
            questionnaire?: string | null,
            owner: string,
            status: AccountStatus,
            updatedBy: string,
            createdBy: string,
            createdAt: string,
            updatedAt: string,
          },
          updatedBy: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      referenceDocs?:  {
        __typename: "ModelDocumentConnection",
        items:  Array< {
          __typename: "Document",
          id: string,
          docType: DocType,
          docCategory: DocCategory,
          pages?:  Array< {
            __typename: "S3Object",
            bucket: string,
            key: string,
            region: string,
          } | null > | null,
          status?: VerificationStatus | null,
          comments?:  Array< {
            __typename: "Comment",
            message: string,
            commentedBy: string,
            commentedAt: number,
          } | null > | null,
          verifiedBy?: string | null,
          extractedData?: string | null,
          owner: string,
          accountId: string,
          account:  {
            __typename: "Account",
            id: string,
            shortName?: string | null,
            type: AccountType,
            attributes?: string | null,
            questionnaire?: string | null,
            owner: string,
            status: AccountStatus,
            updatedBy: string,
            createdBy: string,
            createdAt: string,
            updatedAt: string,
          },
          updatedBy: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      attributes?: string | null,
      questionnaire?: string | null,
      owner: string,
      status: AccountStatus,
      comments?:  Array< {
        __typename: "Comment",
        message: string,
        commentedBy: string,
        commentedAt: number,
      } | null > | null,
      updatedBy: string,
      createdBy: string,
      createdAt: string,
      updatedAt: string,
    },
    updatedBy: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteBankAccountSubscriptionVariables = {
  filter?: ModelSubscriptionBankAccountFilterInput | null,
  owner?: string | null,
};

export type OnDeleteBankAccountSubscription = {
  onDeleteBankAccount?:  {
    __typename: "BankAccount",
    id: string,
    bankName: string,
    accountNumber: string,
    accountHolderName?: string | null,
    accountCurrency?: string | null,
    default?: boolean | null,
    disabled?: boolean | null,
    status?: VerificationStatus | null,
    owner: string,
    comments?:  Array< {
      __typename: "Comment",
      message: string,
      commentedBy: string,
      commentedAt: number,
    } | null > | null,
    verifiedBy?: string | null,
    accountId: string,
    account:  {
      __typename: "Account",
      id: string,
      shortName?: string | null,
      type: AccountType,
      linkedBankAccounts?:  {
        __typename: "ModelBankAccountConnection",
        items:  Array< {
          __typename: "BankAccount",
          id: string,
          bankName: string,
          accountNumber: string,
          accountHolderName?: string | null,
          accountCurrency?: string | null,
          default?: boolean | null,
          disabled?: boolean | null,
          status?: VerificationStatus | null,
          owner: string,
          comments?:  Array< {
            __typename: "Comment",
            message: string,
            commentedBy: string,
            commentedAt: number,
          } | null > | null,
          verifiedBy?: string | null,
          accountId: string,
          account:  {
            __typename: "Account",
            id: string,
            shortName?: string | null,
            type: AccountType,
            attributes?: string | null,
            questionnaire?: string | null,
            owner: string,
            status: AccountStatus,
            updatedBy: string,
            createdBy: string,
            createdAt: string,
            updatedAt: string,
          },
          updatedBy: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      referenceDocs?:  {
        __typename: "ModelDocumentConnection",
        items:  Array< {
          __typename: "Document",
          id: string,
          docType: DocType,
          docCategory: DocCategory,
          pages?:  Array< {
            __typename: "S3Object",
            bucket: string,
            key: string,
            region: string,
          } | null > | null,
          status?: VerificationStatus | null,
          comments?:  Array< {
            __typename: "Comment",
            message: string,
            commentedBy: string,
            commentedAt: number,
          } | null > | null,
          verifiedBy?: string | null,
          extractedData?: string | null,
          owner: string,
          accountId: string,
          account:  {
            __typename: "Account",
            id: string,
            shortName?: string | null,
            type: AccountType,
            attributes?: string | null,
            questionnaire?: string | null,
            owner: string,
            status: AccountStatus,
            updatedBy: string,
            createdBy: string,
            createdAt: string,
            updatedAt: string,
          },
          updatedBy: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      attributes?: string | null,
      questionnaire?: string | null,
      owner: string,
      status: AccountStatus,
      comments?:  Array< {
        __typename: "Comment",
        message: string,
        commentedBy: string,
        commentedAt: number,
      } | null > | null,
      updatedBy: string,
      createdBy: string,
      createdAt: string,
      updatedAt: string,
    },
    updatedBy: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateAccountUserSubscriptionVariables = {
  filter?: ModelSubscriptionAccountUserFilterInput | null,
  userId?: string | null,
};

export type OnCreateAccountUserSubscription = {
  onCreateAccountUser?:  {
    __typename: "AccountUser",
    accountId: string,
    userId: string,
    role?: string | null,
    account:  {
      __typename: "Account",
      id: string,
      shortName?: string | null,
      type: AccountType,
      linkedBankAccounts?:  {
        __typename: "ModelBankAccountConnection",
        items:  Array< {
          __typename: "BankAccount",
          id: string,
          bankName: string,
          accountNumber: string,
          accountHolderName?: string | null,
          accountCurrency?: string | null,
          default?: boolean | null,
          disabled?: boolean | null,
          status?: VerificationStatus | null,
          owner: string,
          comments?:  Array< {
            __typename: "Comment",
            message: string,
            commentedBy: string,
            commentedAt: number,
          } | null > | null,
          verifiedBy?: string | null,
          accountId: string,
          account:  {
            __typename: "Account",
            id: string,
            shortName?: string | null,
            type: AccountType,
            attributes?: string | null,
            questionnaire?: string | null,
            owner: string,
            status: AccountStatus,
            updatedBy: string,
            createdBy: string,
            createdAt: string,
            updatedAt: string,
          },
          updatedBy: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      referenceDocs?:  {
        __typename: "ModelDocumentConnection",
        items:  Array< {
          __typename: "Document",
          id: string,
          docType: DocType,
          docCategory: DocCategory,
          pages?:  Array< {
            __typename: "S3Object",
            bucket: string,
            key: string,
            region: string,
          } | null > | null,
          status?: VerificationStatus | null,
          comments?:  Array< {
            __typename: "Comment",
            message: string,
            commentedBy: string,
            commentedAt: number,
          } | null > | null,
          verifiedBy?: string | null,
          extractedData?: string | null,
          owner: string,
          accountId: string,
          account:  {
            __typename: "Account",
            id: string,
            shortName?: string | null,
            type: AccountType,
            attributes?: string | null,
            questionnaire?: string | null,
            owner: string,
            status: AccountStatus,
            updatedBy: string,
            createdBy: string,
            createdAt: string,
            updatedAt: string,
          },
          updatedBy: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      attributes?: string | null,
      questionnaire?: string | null,
      owner: string,
      status: AccountStatus,
      comments?:  Array< {
        __typename: "Comment",
        message: string,
        commentedBy: string,
        commentedAt: number,
      } | null > | null,
      updatedBy: string,
      createdBy: string,
      createdAt: string,
      updatedAt: string,
    },
    updatedBy: string,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateAccountUserSubscriptionVariables = {
  filter?: ModelSubscriptionAccountUserFilterInput | null,
  userId?: string | null,
};

export type OnUpdateAccountUserSubscription = {
  onUpdateAccountUser?:  {
    __typename: "AccountUser",
    accountId: string,
    userId: string,
    role?: string | null,
    account:  {
      __typename: "Account",
      id: string,
      shortName?: string | null,
      type: AccountType,
      linkedBankAccounts?:  {
        __typename: "ModelBankAccountConnection",
        items:  Array< {
          __typename: "BankAccount",
          id: string,
          bankName: string,
          accountNumber: string,
          accountHolderName?: string | null,
          accountCurrency?: string | null,
          default?: boolean | null,
          disabled?: boolean | null,
          status?: VerificationStatus | null,
          owner: string,
          comments?:  Array< {
            __typename: "Comment",
            message: string,
            commentedBy: string,
            commentedAt: number,
          } | null > | null,
          verifiedBy?: string | null,
          accountId: string,
          account:  {
            __typename: "Account",
            id: string,
            shortName?: string | null,
            type: AccountType,
            attributes?: string | null,
            questionnaire?: string | null,
            owner: string,
            status: AccountStatus,
            updatedBy: string,
            createdBy: string,
            createdAt: string,
            updatedAt: string,
          },
          updatedBy: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      referenceDocs?:  {
        __typename: "ModelDocumentConnection",
        items:  Array< {
          __typename: "Document",
          id: string,
          docType: DocType,
          docCategory: DocCategory,
          pages?:  Array< {
            __typename: "S3Object",
            bucket: string,
            key: string,
            region: string,
          } | null > | null,
          status?: VerificationStatus | null,
          comments?:  Array< {
            __typename: "Comment",
            message: string,
            commentedBy: string,
            commentedAt: number,
          } | null > | null,
          verifiedBy?: string | null,
          extractedData?: string | null,
          owner: string,
          accountId: string,
          account:  {
            __typename: "Account",
            id: string,
            shortName?: string | null,
            type: AccountType,
            attributes?: string | null,
            questionnaire?: string | null,
            owner: string,
            status: AccountStatus,
            updatedBy: string,
            createdBy: string,
            createdAt: string,
            updatedAt: string,
          },
          updatedBy: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      attributes?: string | null,
      questionnaire?: string | null,
      owner: string,
      status: AccountStatus,
      comments?:  Array< {
        __typename: "Comment",
        message: string,
        commentedBy: string,
        commentedAt: number,
      } | null > | null,
      updatedBy: string,
      createdBy: string,
      createdAt: string,
      updatedAt: string,
    },
    updatedBy: string,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteAccountUserSubscriptionVariables = {
  filter?: ModelSubscriptionAccountUserFilterInput | null,
  userId?: string | null,
};

export type OnDeleteAccountUserSubscription = {
  onDeleteAccountUser?:  {
    __typename: "AccountUser",
    accountId: string,
    userId: string,
    role?: string | null,
    account:  {
      __typename: "Account",
      id: string,
      shortName?: string | null,
      type: AccountType,
      linkedBankAccounts?:  {
        __typename: "ModelBankAccountConnection",
        items:  Array< {
          __typename: "BankAccount",
          id: string,
          bankName: string,
          accountNumber: string,
          accountHolderName?: string | null,
          accountCurrency?: string | null,
          default?: boolean | null,
          disabled?: boolean | null,
          status?: VerificationStatus | null,
          owner: string,
          comments?:  Array< {
            __typename: "Comment",
            message: string,
            commentedBy: string,
            commentedAt: number,
          } | null > | null,
          verifiedBy?: string | null,
          accountId: string,
          account:  {
            __typename: "Account",
            id: string,
            shortName?: string | null,
            type: AccountType,
            attributes?: string | null,
            questionnaire?: string | null,
            owner: string,
            status: AccountStatus,
            updatedBy: string,
            createdBy: string,
            createdAt: string,
            updatedAt: string,
          },
          updatedBy: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      referenceDocs?:  {
        __typename: "ModelDocumentConnection",
        items:  Array< {
          __typename: "Document",
          id: string,
          docType: DocType,
          docCategory: DocCategory,
          pages?:  Array< {
            __typename: "S3Object",
            bucket: string,
            key: string,
            region: string,
          } | null > | null,
          status?: VerificationStatus | null,
          comments?:  Array< {
            __typename: "Comment",
            message: string,
            commentedBy: string,
            commentedAt: number,
          } | null > | null,
          verifiedBy?: string | null,
          extractedData?: string | null,
          owner: string,
          accountId: string,
          account:  {
            __typename: "Account",
            id: string,
            shortName?: string | null,
            type: AccountType,
            attributes?: string | null,
            questionnaire?: string | null,
            owner: string,
            status: AccountStatus,
            updatedBy: string,
            createdBy: string,
            createdAt: string,
            updatedAt: string,
          },
          updatedBy: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      attributes?: string | null,
      questionnaire?: string | null,
      owner: string,
      status: AccountStatus,
      comments?:  Array< {
        __typename: "Comment",
        message: string,
        commentedBy: string,
        commentedAt: number,
      } | null > | null,
      updatedBy: string,
      createdBy: string,
      createdAt: string,
      updatedAt: string,
    },
    updatedBy: string,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateDocumentSubscriptionVariables = {
  filter?: ModelSubscriptionDocumentFilterInput | null,
  owner?: string | null,
};

export type OnCreateDocumentSubscription = {
  onCreateDocument?:  {
    __typename: "Document",
    id: string,
    docType: DocType,
    docCategory: DocCategory,
    pages?:  Array< {
      __typename: "S3Object",
      bucket: string,
      key: string,
      region: string,
    } | null > | null,
    status?: VerificationStatus | null,
    comments?:  Array< {
      __typename: "Comment",
      message: string,
      commentedBy: string,
      commentedAt: number,
    } | null > | null,
    verifiedBy?: string | null,
    extractedData?: string | null,
    owner: string,
    accountId: string,
    account:  {
      __typename: "Account",
      id: string,
      shortName?: string | null,
      type: AccountType,
      linkedBankAccounts?:  {
        __typename: "ModelBankAccountConnection",
        items:  Array< {
          __typename: "BankAccount",
          id: string,
          bankName: string,
          accountNumber: string,
          accountHolderName?: string | null,
          accountCurrency?: string | null,
          default?: boolean | null,
          disabled?: boolean | null,
          status?: VerificationStatus | null,
          owner: string,
          comments?:  Array< {
            __typename: "Comment",
            message: string,
            commentedBy: string,
            commentedAt: number,
          } | null > | null,
          verifiedBy?: string | null,
          accountId: string,
          account:  {
            __typename: "Account",
            id: string,
            shortName?: string | null,
            type: AccountType,
            attributes?: string | null,
            questionnaire?: string | null,
            owner: string,
            status: AccountStatus,
            updatedBy: string,
            createdBy: string,
            createdAt: string,
            updatedAt: string,
          },
          updatedBy: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      referenceDocs?:  {
        __typename: "ModelDocumentConnection",
        items:  Array< {
          __typename: "Document",
          id: string,
          docType: DocType,
          docCategory: DocCategory,
          pages?:  Array< {
            __typename: "S3Object",
            bucket: string,
            key: string,
            region: string,
          } | null > | null,
          status?: VerificationStatus | null,
          comments?:  Array< {
            __typename: "Comment",
            message: string,
            commentedBy: string,
            commentedAt: number,
          } | null > | null,
          verifiedBy?: string | null,
          extractedData?: string | null,
          owner: string,
          accountId: string,
          account:  {
            __typename: "Account",
            id: string,
            shortName?: string | null,
            type: AccountType,
            attributes?: string | null,
            questionnaire?: string | null,
            owner: string,
            status: AccountStatus,
            updatedBy: string,
            createdBy: string,
            createdAt: string,
            updatedAt: string,
          },
          updatedBy: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      attributes?: string | null,
      questionnaire?: string | null,
      owner: string,
      status: AccountStatus,
      comments?:  Array< {
        __typename: "Comment",
        message: string,
        commentedBy: string,
        commentedAt: number,
      } | null > | null,
      updatedBy: string,
      createdBy: string,
      createdAt: string,
      updatedAt: string,
    },
    updatedBy: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateDocumentSubscriptionVariables = {
  filter?: ModelSubscriptionDocumentFilterInput | null,
  owner?: string | null,
};

export type OnUpdateDocumentSubscription = {
  onUpdateDocument?:  {
    __typename: "Document",
    id: string,
    docType: DocType,
    docCategory: DocCategory,
    pages?:  Array< {
      __typename: "S3Object",
      bucket: string,
      key: string,
      region: string,
    } | null > | null,
    status?: VerificationStatus | null,
    comments?:  Array< {
      __typename: "Comment",
      message: string,
      commentedBy: string,
      commentedAt: number,
    } | null > | null,
    verifiedBy?: string | null,
    extractedData?: string | null,
    owner: string,
    accountId: string,
    account:  {
      __typename: "Account",
      id: string,
      shortName?: string | null,
      type: AccountType,
      linkedBankAccounts?:  {
        __typename: "ModelBankAccountConnection",
        items:  Array< {
          __typename: "BankAccount",
          id: string,
          bankName: string,
          accountNumber: string,
          accountHolderName?: string | null,
          accountCurrency?: string | null,
          default?: boolean | null,
          disabled?: boolean | null,
          status?: VerificationStatus | null,
          owner: string,
          comments?:  Array< {
            __typename: "Comment",
            message: string,
            commentedBy: string,
            commentedAt: number,
          } | null > | null,
          verifiedBy?: string | null,
          accountId: string,
          account:  {
            __typename: "Account",
            id: string,
            shortName?: string | null,
            type: AccountType,
            attributes?: string | null,
            questionnaire?: string | null,
            owner: string,
            status: AccountStatus,
            updatedBy: string,
            createdBy: string,
            createdAt: string,
            updatedAt: string,
          },
          updatedBy: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      referenceDocs?:  {
        __typename: "ModelDocumentConnection",
        items:  Array< {
          __typename: "Document",
          id: string,
          docType: DocType,
          docCategory: DocCategory,
          pages?:  Array< {
            __typename: "S3Object",
            bucket: string,
            key: string,
            region: string,
          } | null > | null,
          status?: VerificationStatus | null,
          comments?:  Array< {
            __typename: "Comment",
            message: string,
            commentedBy: string,
            commentedAt: number,
          } | null > | null,
          verifiedBy?: string | null,
          extractedData?: string | null,
          owner: string,
          accountId: string,
          account:  {
            __typename: "Account",
            id: string,
            shortName?: string | null,
            type: AccountType,
            attributes?: string | null,
            questionnaire?: string | null,
            owner: string,
            status: AccountStatus,
            updatedBy: string,
            createdBy: string,
            createdAt: string,
            updatedAt: string,
          },
          updatedBy: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      attributes?: string | null,
      questionnaire?: string | null,
      owner: string,
      status: AccountStatus,
      comments?:  Array< {
        __typename: "Comment",
        message: string,
        commentedBy: string,
        commentedAt: number,
      } | null > | null,
      updatedBy: string,
      createdBy: string,
      createdAt: string,
      updatedAt: string,
    },
    updatedBy: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteDocumentSubscriptionVariables = {
  filter?: ModelSubscriptionDocumentFilterInput | null,
  owner?: string | null,
};

export type OnDeleteDocumentSubscription = {
  onDeleteDocument?:  {
    __typename: "Document",
    id: string,
    docType: DocType,
    docCategory: DocCategory,
    pages?:  Array< {
      __typename: "S3Object",
      bucket: string,
      key: string,
      region: string,
    } | null > | null,
    status?: VerificationStatus | null,
    comments?:  Array< {
      __typename: "Comment",
      message: string,
      commentedBy: string,
      commentedAt: number,
    } | null > | null,
    verifiedBy?: string | null,
    extractedData?: string | null,
    owner: string,
    accountId: string,
    account:  {
      __typename: "Account",
      id: string,
      shortName?: string | null,
      type: AccountType,
      linkedBankAccounts?:  {
        __typename: "ModelBankAccountConnection",
        items:  Array< {
          __typename: "BankAccount",
          id: string,
          bankName: string,
          accountNumber: string,
          accountHolderName?: string | null,
          accountCurrency?: string | null,
          default?: boolean | null,
          disabled?: boolean | null,
          status?: VerificationStatus | null,
          owner: string,
          comments?:  Array< {
            __typename: "Comment",
            message: string,
            commentedBy: string,
            commentedAt: number,
          } | null > | null,
          verifiedBy?: string | null,
          accountId: string,
          account:  {
            __typename: "Account",
            id: string,
            shortName?: string | null,
            type: AccountType,
            attributes?: string | null,
            questionnaire?: string | null,
            owner: string,
            status: AccountStatus,
            updatedBy: string,
            createdBy: string,
            createdAt: string,
            updatedAt: string,
          },
          updatedBy: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      referenceDocs?:  {
        __typename: "ModelDocumentConnection",
        items:  Array< {
          __typename: "Document",
          id: string,
          docType: DocType,
          docCategory: DocCategory,
          pages?:  Array< {
            __typename: "S3Object",
            bucket: string,
            key: string,
            region: string,
          } | null > | null,
          status?: VerificationStatus | null,
          comments?:  Array< {
            __typename: "Comment",
            message: string,
            commentedBy: string,
            commentedAt: number,
          } | null > | null,
          verifiedBy?: string | null,
          extractedData?: string | null,
          owner: string,
          accountId: string,
          account:  {
            __typename: "Account",
            id: string,
            shortName?: string | null,
            type: AccountType,
            attributes?: string | null,
            questionnaire?: string | null,
            owner: string,
            status: AccountStatus,
            updatedBy: string,
            createdBy: string,
            createdAt: string,
            updatedAt: string,
          },
          updatedBy: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      attributes?: string | null,
      questionnaire?: string | null,
      owner: string,
      status: AccountStatus,
      comments?:  Array< {
        __typename: "Comment",
        message: string,
        commentedBy: string,
        commentedAt: number,
      } | null > | null,
      updatedBy: string,
      createdBy: string,
      createdAt: string,
      updatedAt: string,
    },
    updatedBy: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateUserPreferenceSubscriptionVariables = {
  filter?: ModelSubscriptionUserPreferenceFilterInput | null,
  id?: string | null,
};

export type OnCreateUserPreferenceSubscription = {
  onCreateUserPreference?:  {
    __typename: "UserPreference",
    userId: string,
    preferences?: string | null,
    updatedBy: string,
    createdAt: string,
    updatedAt: string,
    id?: string | null,
  } | null,
};

export type OnUpdateUserPreferenceSubscriptionVariables = {
  filter?: ModelSubscriptionUserPreferenceFilterInput | null,
  id?: string | null,
};

export type OnUpdateUserPreferenceSubscription = {
  onUpdateUserPreference?:  {
    __typename: "UserPreference",
    userId: string,
    preferences?: string | null,
    updatedBy: string,
    createdAt: string,
    updatedAt: string,
    id?: string | null,
  } | null,
};

export type OnDeleteUserPreferenceSubscriptionVariables = {
  filter?: ModelSubscriptionUserPreferenceFilterInput | null,
  id?: string | null,
};

export type OnDeleteUserPreferenceSubscription = {
  onDeleteUserPreference?:  {
    __typename: "UserPreference",
    userId: string,
    preferences?: string | null,
    updatedBy: string,
    createdAt: string,
    updatedAt: string,
    id?: string | null,
  } | null,
};
