import {
	IonButton,
	IonCol,
	IonGrid,
	IonIcon,
	IonImg,
	IonLabel,
	IonRow,
	IonSpinner,
	useIonToast,
	IonCard,
} from '@ionic/react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { AccountType, DocType, DocCategory, VerificationStatus } from 'API';
import DocumentApi from 'api/document';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FileUploader } from 'react-drag-drop-files';
import { useNavigate, useLocation } from 'react-router-dom';
import AppContext from 'utils/app-context';
import { Routes } from 'utils/routes';
import DocumentCapture from 'components/ImageCapture';
import IdCapture from 'components/IdCapture';
import { removeTypenameFromS3 } from 'utils/object-helper';
import RinngitPayLoader from 'components/RinngitPayLoader';
import { Toaster, toast } from 'react-hot-toast';

import AccountApi from 'api/account';

import scanner from '../../images/Scanner.gif';

export default function IdSubmissionForIndividual() {
	const navigate = useNavigate();
	const appContext = useContext(AppContext);
	const fileTypes = ['JPEG', 'PNG', 'JPG'];
	const document0 = useRef<HTMLInputElement>(null);
	const document1 = useRef<HTMLInputElement>(null);
	let [imageIndex, setImageIndex] = useState<number>(0);
	const [fileChange, setFileChange] = useState<boolean>(false);
	const [captureImage, setCapturImage] = useState<boolean>(false);
	const [capturedImage0, setCapturedImage0] = useState<File | null>(null);
	const [capturedImage1, setCapturedImage1] = useState<File | null>(null);
	const [documentNumber, setDocumentNumber] = useState<number>(0);
	const isAdmin = appContext.userDetails.signInUserSession.idToken.payload['cognito:groups'] || [];
	const [isLoading, setIsLoading] = useState(false);
	const [present] = useIonToast();
	let location = useLocation();

	const notify = (toastMessageDetails) => {
		if (toastMessageDetails.toastType === 'error') {
			toast.error(toastMessageDetails.toastMessage, {
				duration: 3200,
				position: 'top-center',
			});
		}

		if (toastMessageDetails.toastType === 'success') {
			toast.success(toastMessageDetails.toastMessage, {
				duration: 3200,
				position: 'top-center',
			});
		}
	};

	const accountDetails = appContext.selectedAccount();
	const docType =
		JSON.parse(appContext.selectedAccount().attributes).nationality ==
		appContext.nationality.MALAYSIAN
			? DocType.MYKAD
			: DocType.PASSPORT;

	const validationSchema = Yup.object({
		documentType: Yup.string().required(),
		docCategory: Yup.string().required(),
		document0: Yup.string(),
		document1: Yup.string(),
	});

	let formik = useFormik({
		initialValues: {
			documentType: docType,
			docCategory: DocCategory.INDIVIDUAL,
			document0: undefined as any,
			document1: undefined as any,
		},
		validationSchema: validationSchema,
		onSubmit: (values, { setSubmitting }) => {
			fileChange
				? onNext(values, setSubmitting)
				: navigate(Routes.CUSTOMER_VERIFICATION + '/' + Routes.ACCOUNT_SELFIE_VERIFICATION, {
						state: { id: accountDetails?.id, page: 2 },
				  });
		},
	});

	useEffect(() => {
		if (location.state !== null) {
			notify({ toastMessage: location.state.toastMessage, toastType: location.state.toastType });
		}
		console.log('ID Submission Page for Individual');
		console.log('Selected Account: ', accountDetails);
		console.log(
			JSON.parse(accountDetails.attributes).nationality == appContext.nationality.MALAYSIAN
				? DocType.MYKAD
				: DocType.PASSPORT,
		);
		let document = accountDetails.referenceDocs?.items?.filter((obj) => obj.docType === docType);
		console.log(document && document[0]);
		loadImagefromS3(document && document[0]);
	}, []);

	async function getAccount() {
		const getAccountDetails = await AccountApi.getByUser(appContext.userDetails.username).then(
			(accounts) => {
				return accounts;
			},
		);
		const selectedAccount = getAccountDetails.find((account) => {
			return account.id === appContext.selectedAccount().id;
		});
		console.log('--------', selectedAccount);
		appContext.setSelectedAccount(selectedAccount);
	}

	const onNext = async (formState, setSubmitting) => {
		console.log(fileChange);
		try {
			setIsLoading(true);
			const pages = setUplaodDocument();
			const idDocument = !uploadedDocument() ? {} : uploadedDocument();
			console.log('idDocument', idDocument);
			console.log('----------', uploadedDocument());
			idDocument.docType = docType;
			idDocument.docCategory = DocCategory.INDIVIDUAL;
			idDocument.accountId = accountDetails.id;
			idDocument.updatedBy = appContext.userDetails.username;
			idDocument.owner = accountDetails.owner;
			idDocument.status = VerificationStatus.PENDING;
			const updatedPages = (idDocument.pages || []).map((page) => removeTypenameFromS3(page));
			idDocument.pages = updatedPages;
			console.log('idDocument', idDocument);
			const createDocument = await DocumentApi.save(idDocument);
			console.log('createDocument', createDocument);
			await DocumentApi.upload(idDocument, pages, createDocument.id, false);
			idDocument.id = createDocument.id;
			const updateDocument = await DocumentApi.save(idDocument);
			console.log('updateDocument', updateDocument);
			const extractedData = await DocumentApi.extractID(createDocument.id);
			console.log(extractedData);
			// await updateAttributes(extractedData);
			appContext.setSelectedAccount(createDocument.account);
			await getAccount();

			// console.log('-----55555-----', accountDetails?.id);
			navigate(Routes.CUSTOMER_VERIFICATION + '/' + Routes.ACCOUNT_SELFIE_VERIFICATION, {
				state: { id: accountDetails?.id, page: 2 },
			});
		} catch (error) {
			setIsLoading(false);
			console.log(error);
			console.error('Failed uploading document', error);
		}
	};

	async function updateAttributes(extractedData) {
		console.log(JSON.parse(extractedData.data).name);

		AccountApi.save({
			id: appContext.selectedAccount()?.id,
			owner: appContext.selectedAccount()?.owner,
			shortName: appContext.selectedAccount()?.shortName,
			updatedBy: appContext.selectedAccount()?.owner,
			createdBy: appContext.selectedAccount()?.owner,
			type: appContext.selectedAccount()?.type,
			questionnaire: appContext.selectedAccount()?.questionnaire,
			attributes: JSON.stringify({
				name: JSON.parse(extractedData.data).name,
				nationality: JSON.parse(appContext.selectedAccount()?.attributes).nationality,
			}),
		})
			.then((account) => {
				console.log(account);
			})
			.catch((error) => {
				console.error('Failed Saving Account', error);

				notify({ toastMessage: error?.errors[0].message, toastType: 'error' });
			});
	}

	function uploadedId() {
		const filteredData = appContext.selectedAccount()?.referenceDocs.items.find((item) => {
			if (
				item.docType === docType &&
				item.docCategory === DocCategory.INDIVIDUAL &&
				item.account.type === AccountType.INDIVIDUAL
			) {
				return true;
			}
			return false;
		});
		console.log(filteredData);
		return filteredData;
	}

	function uploadedDocument() {
		return appContext
			.selectedAccount()
			?.referenceDocs?.items.find(
				(doc) =>
					doc.docType == docType &&
					doc.docCategory === DocCategory.INDIVIDUAL &&
					doc.account.type === AccountType.INDIVIDUAL,
			);
	}

	function setUplaodDocument() {
		return [
			formik.values?.document0 as unknown as File,
			formik.values?.document1 as unknown as File,
		];
	}

	function loadImagefromS3(imageDetails) {
		console.log('get image url', imageDetails);

		imageDetails?.pages?.forEach((element, index) => {
			console.log(element);
			DocumentApi.getImage(element.key).then((res) => {
				formik.setFieldValue('document' + index, res);
			});
		});
	}

	const handleFileChange = async (file, key) => {
		setFileChange(true);
		console.log(file, key);
		if (file === '') {
			key === 'document0'
				? document0.current?.click()
				: key === 'document1'
				? document1.current?.click()
				: document0.current?.click();
		} else {
			if (file.type === 'application/pdf') {
				console.log('pdf file');
			}
			formik.setFieldValue(key, file);
		}
	};

	const isFormValid = () => {
		if (docType === DocType.MYKAD) {
			return (
				(formik.values.document0?.type && formik.values.document1?.type) ||
				(formik.values.document0 && formik.values.document1)
			);
		}
		if (docType === DocType.PASSPORT) {
			return (
				(formik.values.document0?.type && formik.values.document1?.type) ||
				(formik.values.document0 && formik.values.document1)
			);
		}
		return false;
	};

	// const handleImageCapture = (image: string) => {
	//  console.log(image);
	//  setCapturedImage(image);
	//  formik.setFieldValue('document0', image);
	//  setCapturImage(false);
	// };

	const handleImageCapture = (image: File, documentNumber: number) => {
		setCapturImage(false);
		documentNumber === 0 ? setCapturedImage0(image) : setCapturedImage1(image);
		formik.setFieldValue('document' + documentNumber, image);
		console.log(captureImage);
	};

	function documentDetailsToChild() {
		return {
			documentType: formik.values.documentType === DocType.MYKAD ? DocType.MYKAD : DocType.PASSPORT,
			documentNumber,
		};
	}
	const handleButtonClick = () => {};

	return (
		<form onSubmit={formik.handleSubmit}>
			<Toaster />
			{!isLoading ? (
				<section className="ml-0 md:ml-4 md:pl-0.5 h-full">
					{captureImage && (
						<div className="ml-1 mt-5">
							<IdCapture
								onImageCapture={handleImageCapture}
								documentDetails={documentDetailsToChild()}
							/>
						</div>
					)}

					{!captureImage && (
						<>
							<IonRow className="ml-5 w-3/4 mt-5">
								<div className="text-22px text-secondary-75 font-bold mb-0.5">
									Upload Your {docType === DocType.MYKAD ? 'MyKad' : 'Passport'}
								</div>
							</IonRow>

							<IonRow className="ml-5  w-11/12 mt-5 mb-2">
								<div className="text-16px text-secondary-75 font-normal">
									Once you upload a copy of {docType === DocType.MYKAD ? 'MyKad' : 'Passport'}, the
									system will automatically capture the information in the document and auto fill
									your details.
								</div>
							</IonRow>

							<IonRow>
								{!formik.values?.document0 && (
									<>
										<IonRow className="w-296px mb-5 md:mb-0 md:ml-0.5">
											<IonCard className="ml-5 w-64 h-40 border-2 border-dashed  border-secondary-100 rounded-lg  overflow-hidden flex flex-col justify-center items-center text-center bg-secondary-00">
												<IonCol>
													<IonRow className=" mb-1 justify-center">
														<IonIcon
															className={`uploadIcon bg-primary-100 h-7 w-9 mt-4 ${
																docType === DocType.MYKAD ? 'mykad' : 'passport'
															} bg-primary-50`}
														/>
													</IonRow>
													<IonRow className="mt-1 mb-0 justify-center">
														<IonLabel className="text-16px text-secondary-75 font-normal">
															Front Side of your
															{formik.values.documentType === DocType.MYKAD
																? ' MyKad'
																: ' Passport'}
														</IonLabel>
													</IonRow>
													<IonRow className="mt-1 mb-0 justify-center">
														<IonLabel className="text-secondary-75 font-normal text-14px">
															Scan or take a photo
														</IonLabel>
													</IonRow>
													<IonRow className="flex justify-center">
														<div
															className="text-xs cursor-pointer mt-2 m-auto h-auto py-1 bg-primary-100 rounded-md text-center text-secondary-00 w-24 align-center"
															onClick={() => {
																setCapturImage(true);
																setDocumentNumber(0);
																setFileChange(true);
															}}
														>
															Capture
														</div>
													</IonRow>
												</IonCol>
											</IonCard>
										</IonRow>
									</>
								)}

								{formik.values?.document0 && (
									<>
										<IonRow className="w-296px mb-5 md:mb-0">
											<IonCard className="ml-5 w-64 h-40 border-2 border-secondary-100 rounded-lg  overflow-hidden flex flex-col justify-center items-center text-center bg-transparent">
												{formik.isSubmitting ? 'scanning' : 'upload id'}
												<IonImg
													src={
														capturedImage0
															? URL.createObjectURL(capturedImage0)
															: formik.values?.document0
													}
												></IonImg>
												{formik.isSubmitting && (
													<IonImg
														className="absolute top-0 left-0 w-66 h-40"
														src={scanner}
													></IonImg>
												)}
											</IonCard>

											<IonRow className="mt-1 ml-5 w-64 mb-0 justify-center">
												<IonLabel className="text-16px text-secondary-75 font-normal">
													Front Side of
													{formik.values.documentType === DocType.MYKAD ? ' MyKad' : ' Passport'}
												</IonLabel>
											</IonRow>

											<IonRow className="ml-5 w-64 justify-center mt-2">
												<div
													className="text-xs cursor-pointer h-auto py-1 bg-primary-100 border border-primary-50 rounded-md text-secondary-00 text-center w-24 "
													onClick={() => {
														setCapturImage(true);
														setDocumentNumber(0);
														setFileChange(true);
													}}
												>
													Re-Capture
												</div>
											</IonRow>
										</IonRow>
									</>
								)}

								{!formik.values?.document1 && (
									<>
										<IonRow className="w-96">
											<IonCard className="ml-5 md:ml-0 w-64 h-40 border-2 border-dashed  border-secondary-100 rounded-lg  overflow-hidden flex flex-col justify-center items-center text-center bg-secondary-00">
												<IonCol>
													<IonRow className=" mb-1 justify-center">
														<IonIcon
															className={`uploadIcon bg-primary-100 mt-4 h-7 w-9 ${
																docType === DocType.MYKAD ? 'mykad' : 'passport'
															} bg-primary-50`}
														/>
													</IonRow>
													<IonRow className="mt-1 mb-0 justify-center">
														<IonLabel className="text-16px text-secondary-75 font-normal">
															Back Side of your
															{formik.values.documentType === DocType.MYKAD
																? ' MyKad'
																: ' Passport'}
														</IonLabel>
													</IonRow>
													<IonRow className="mt-1 mb-0 justify-center">
														<IonLabel className="text-secondary-75 font-normal text-14px">
															Scan or take a photo
														</IonLabel>
													</IonRow>
													<IonRow className="flex justify-center">
														<div
															className="text-xs cursor-pointer mt-1 m-auto h-auto py-1 bg-primary-100 rounded-md text-center text-secondary-00 w-24 align-center"
															onClick={() => {
																setCapturImage(true);
																setDocumentNumber(1);
																setFileChange(true);
															}}
														>
															Capture
														</div>
													</IonRow>
												</IonCol>
											</IonCard>
										</IonRow>
									</>
								)}

								{formik.values?.document1 && (
									<>
										<IonRow className="w-96">
											<IonCard className="ml-5 md:ml-0 w-64 h-40 border-2 border-secondary-100 rounded-lg  overflow-hidden flex flex-col justify-center items-center text-center bg-transparent">
												<IonImg
													src={
														capturedImage1
															? URL.createObjectURL(capturedImage1)
															: formik.values?.document1
													}
												></IonImg>
												{formik.isSubmitting && (
													<IonImg
														className="absolute top-0 left-0 w-66 h-40"
														src={scanner}
													></IonImg>
												)}
											</IonCard>

											<IonRow className="mt-1 ml-5 md:ml-0 w-64 mb-0 justify-center">
												<IonLabel className="text-16px text-secondary-75 font-normal">
													Back Side of
													{formik.values.documentType === DocType.MYKAD ? ' MyKad' : ' Passport'}
												</IonLabel>
											</IonRow>

											<IonRow className="ml-5 md:ml-0 w-64 justify-center mt-2">
												<div
													className="text-xs cursor-pointer h-auto py-1 bg-primary-100 border border-primary-50 rounded-md text-center text-secondary-00 w-24 "
													onClick={() => {
														setCapturImage(true);
														setDocumentNumber(1);
														setFileChange(true);
													}}
												>
													Re-Capture
												</div>
											</IonRow>
										</IonRow>
									</>
								)}
							</IonRow>

							<IonRow className="ml-5 w-3/4 mt-2 pt-1.5 mb-5 pb-1.5">
								<IonButton
									type="submit"
									expand="block"
									className="h-36px capitalize font-normal text-16px rounded-sm"
									disabled={!isFormValid() || formik.isSubmitting}
									//disabled={formik.isSubmitting}
									onClick={handleButtonClick}
								>
									{formik.isSubmitting ? <IonSpinner /> : 'Continue'}
								</IonButton>
							</IonRow>
						</>
					)}
				</section>
			) : (
				<RinngitPayLoader props={{ overlay: true }} />
			)}
		</form>
	);
}
