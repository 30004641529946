/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const checkKycReadiness = /* GraphQL */ `query CheckKycReadiness($accountId: ID!) {
  checkKycReadiness(accountId: $accountId)
}
` as GeneratedQuery<
  APITypes.CheckKycReadinessQueryVariables,
  APITypes.CheckKycReadinessQuery
>;
export const getAccount = /* GraphQL */ `query GetAccount($id: ID!) {
  getAccount(id: $id) {
    id
    shortName
    type
    linkedBankAccounts {
      items {
        id
        bankName
        accountNumber
        accountHolderName
        accountCurrency
        default
        disabled
        status
        owner
        comments {
          message
          commentedBy
          commentedAt
          __typename
        }
        verifiedBy
        accountId
        account {
          id
          shortName
          type
          linkedBankAccounts {
            nextToken
            __typename
          }
          referenceDocs {
            nextToken
            __typename
          }
          attributes
          questionnaire
          owner
          status
          comments {
            message
            commentedBy
            commentedAt
            __typename
          }
          updatedBy
          createdBy
          createdAt
          updatedAt
          __typename
        }
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    referenceDocs {
      items {
        id
        docType
        docCategory
        pages {
          bucket
          key
          region
          __typename
        }
        status
        comments {
          message
          commentedBy
          commentedAt
          __typename
        }
        verifiedBy
        extractedData
        owner
        accountId
        account {
          id
          shortName
          type
          linkedBankAccounts {
            nextToken
            __typename
          }
          referenceDocs {
            nextToken
            __typename
          }
          attributes
          questionnaire
          owner
          status
          comments {
            message
            commentedBy
            commentedAt
            __typename
          }
          updatedBy
          createdBy
          createdAt
          updatedAt
          __typename
        }
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    attributes
    questionnaire
    owner
    status
    comments {
      message
      commentedBy
      commentedAt
      __typename
    }
    updatedBy
    createdBy
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAccountQueryVariables,
  APITypes.GetAccountQuery
>;
export const listAccounts = /* GraphQL */ `query ListAccounts(
  $id: ID
  $filter: ModelAccountFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listAccounts(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      shortName
      type
      linkedBankAccounts {
        items {
          id
          bankName
          accountNumber
          accountHolderName
          accountCurrency
          default
          disabled
          status
          owner
          comments {
            message
            commentedBy
            commentedAt
            __typename
          }
          verifiedBy
          accountId
          account {
            id
            shortName
            type
            attributes
            questionnaire
            owner
            status
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      referenceDocs {
        items {
          id
          docType
          docCategory
          pages {
            bucket
            key
            region
            __typename
          }
          status
          comments {
            message
            commentedBy
            commentedAt
            __typename
          }
          verifiedBy
          extractedData
          owner
          accountId
          account {
            id
            shortName
            type
            attributes
            questionnaire
            owner
            status
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      attributes
      questionnaire
      owner
      status
      comments {
        message
        commentedBy
        commentedAt
        __typename
      }
      updatedBy
      createdBy
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAccountsQueryVariables,
  APITypes.ListAccountsQuery
>;
export const getAccountByShortName = /* GraphQL */ `query GetAccountByShortName(
  $shortName: String!
  $sortDirection: ModelSortDirection
  $filter: ModelAccountFilterInput
  $limit: Int
  $nextToken: String
) {
  getAccountByShortName(
    shortName: $shortName
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      shortName
      type
      linkedBankAccounts {
        items {
          id
          bankName
          accountNumber
          accountHolderName
          accountCurrency
          default
          disabled
          status
          owner
          comments {
            message
            commentedBy
            commentedAt
            __typename
          }
          verifiedBy
          accountId
          account {
            id
            shortName
            type
            attributes
            questionnaire
            owner
            status
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      referenceDocs {
        items {
          id
          docType
          docCategory
          pages {
            bucket
            key
            region
            __typename
          }
          status
          comments {
            message
            commentedBy
            commentedAt
            __typename
          }
          verifiedBy
          extractedData
          owner
          accountId
          account {
            id
            shortName
            type
            attributes
            questionnaire
            owner
            status
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      attributes
      questionnaire
      owner
      status
      comments {
        message
        commentedBy
        commentedAt
        __typename
      }
      updatedBy
      createdBy
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAccountByShortNameQueryVariables,
  APITypes.GetAccountByShortNameQuery
>;
export const getAccountsByOwner = /* GraphQL */ `query GetAccountsByOwner(
  $owner: String!
  $sortDirection: ModelSortDirection
  $filter: ModelAccountFilterInput
  $limit: Int
  $nextToken: String
) {
  getAccountsByOwner(
    owner: $owner
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      shortName
      type
      linkedBankAccounts {
        items {
          id
          bankName
          accountNumber
          accountHolderName
          accountCurrency
          default
          disabled
          status
          owner
          comments {
            message
            commentedBy
            commentedAt
            __typename
          }
          verifiedBy
          accountId
          account {
            id
            shortName
            type
            attributes
            questionnaire
            owner
            status
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      referenceDocs {
        items {
          id
          docType
          docCategory
          pages {
            bucket
            key
            region
            __typename
          }
          status
          comments {
            message
            commentedBy
            commentedAt
            __typename
          }
          verifiedBy
          extractedData
          owner
          accountId
          account {
            id
            shortName
            type
            attributes
            questionnaire
            owner
            status
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      attributes
      questionnaire
      owner
      status
      comments {
        message
        commentedBy
        commentedAt
        __typename
      }
      updatedBy
      createdBy
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAccountsByOwnerQueryVariables,
  APITypes.GetAccountsByOwnerQuery
>;
export const getBankAccount = /* GraphQL */ `query GetBankAccount($id: ID!) {
  getBankAccount(id: $id) {
    id
    bankName
    accountNumber
    accountHolderName
    accountCurrency
    default
    disabled
    status
    owner
    comments {
      message
      commentedBy
      commentedAt
      __typename
    }
    verifiedBy
    accountId
    account {
      id
      shortName
      type
      linkedBankAccounts {
        items {
          id
          bankName
          accountNumber
          accountHolderName
          accountCurrency
          default
          disabled
          status
          owner
          comments {
            message
            commentedBy
            commentedAt
            __typename
          }
          verifiedBy
          accountId
          account {
            id
            shortName
            type
            attributes
            questionnaire
            owner
            status
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      referenceDocs {
        items {
          id
          docType
          docCategory
          pages {
            bucket
            key
            region
            __typename
          }
          status
          comments {
            message
            commentedBy
            commentedAt
            __typename
          }
          verifiedBy
          extractedData
          owner
          accountId
          account {
            id
            shortName
            type
            attributes
            questionnaire
            owner
            status
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      attributes
      questionnaire
      owner
      status
      comments {
        message
        commentedBy
        commentedAt
        __typename
      }
      updatedBy
      createdBy
      createdAt
      updatedAt
      __typename
    }
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBankAccountQueryVariables,
  APITypes.GetBankAccountQuery
>;
export const listBankAccounts = /* GraphQL */ `query ListBankAccounts(
  $id: ID
  $filter: ModelBankAccountFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listBankAccounts(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      bankName
      accountNumber
      accountHolderName
      accountCurrency
      default
      disabled
      status
      owner
      comments {
        message
        commentedBy
        commentedAt
        __typename
      }
      verifiedBy
      accountId
      account {
        id
        shortName
        type
        linkedBankAccounts {
          items {
            id
            bankName
            accountNumber
            accountHolderName
            accountCurrency
            default
            disabled
            status
            owner
            verifiedBy
            accountId
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        referenceDocs {
          items {
            id
            docType
            docCategory
            status
            verifiedBy
            extractedData
            owner
            accountId
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        attributes
        questionnaire
        owner
        status
        comments {
          message
          commentedBy
          commentedAt
          __typename
        }
        updatedBy
        createdBy
        createdAt
        updatedAt
        __typename
      }
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBankAccountsQueryVariables,
  APITypes.ListBankAccountsQuery
>;
export const getLinkedBankAccountsByAccount = /* GraphQL */ `query GetLinkedBankAccountsByAccount(
  $accountId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelBankAccountFilterInput
  $limit: Int
  $nextToken: String
) {
  getLinkedBankAccountsByAccount(
    accountId: $accountId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      bankName
      accountNumber
      accountHolderName
      accountCurrency
      default
      disabled
      status
      owner
      comments {
        message
        commentedBy
        commentedAt
        __typename
      }
      verifiedBy
      accountId
      account {
        id
        shortName
        type
        linkedBankAccounts {
          items {
            id
            bankName
            accountNumber
            accountHolderName
            accountCurrency
            default
            disabled
            status
            owner
            verifiedBy
            accountId
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        referenceDocs {
          items {
            id
            docType
            docCategory
            status
            verifiedBy
            extractedData
            owner
            accountId
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        attributes
        questionnaire
        owner
        status
        comments {
          message
          commentedBy
          commentedAt
          __typename
        }
        updatedBy
        createdBy
        createdAt
        updatedAt
        __typename
      }
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetLinkedBankAccountsByAccountQueryVariables,
  APITypes.GetLinkedBankAccountsByAccountQuery
>;
export const getAccountUser = /* GraphQL */ `query GetAccountUser($id: ID!) {
  getAccountUser(id: $id) {
    accountId
    userId
    role
    account {
      id
      shortName
      type
      linkedBankAccounts {
        items {
          id
          bankName
          accountNumber
          accountHolderName
          accountCurrency
          default
          disabled
          status
          owner
          comments {
            message
            commentedBy
            commentedAt
            __typename
          }
          verifiedBy
          accountId
          account {
            id
            shortName
            type
            attributes
            questionnaire
            owner
            status
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      referenceDocs {
        items {
          id
          docType
          docCategory
          pages {
            bucket
            key
            region
            __typename
          }
          status
          comments {
            message
            commentedBy
            commentedAt
            __typename
          }
          verifiedBy
          extractedData
          owner
          accountId
          account {
            id
            shortName
            type
            attributes
            questionnaire
            owner
            status
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      attributes
      questionnaire
      owner
      status
      comments {
        message
        commentedBy
        commentedAt
        __typename
      }
      updatedBy
      createdBy
      createdAt
      updatedAt
      __typename
    }
    updatedBy
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAccountUserQueryVariables,
  APITypes.GetAccountUserQuery
>;
export const listAccountUsers = /* GraphQL */ `query ListAccountUsers(
  $filter: ModelAccountUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listAccountUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      accountId
      userId
      role
      account {
        id
        shortName
        type
        linkedBankAccounts {
          items {
            id
            bankName
            accountNumber
            accountHolderName
            accountCurrency
            default
            disabled
            status
            owner
            verifiedBy
            accountId
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        referenceDocs {
          items {
            id
            docType
            docCategory
            status
            verifiedBy
            extractedData
            owner
            accountId
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        attributes
        questionnaire
        owner
        status
        comments {
          message
          commentedBy
          commentedAt
          __typename
        }
        updatedBy
        createdBy
        createdAt
        updatedAt
        __typename
      }
      updatedBy
      id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAccountUsersQueryVariables,
  APITypes.ListAccountUsersQuery
>;
export const getUsersByAccount = /* GraphQL */ `query GetUsersByAccount(
  $accountId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelAccountUserFilterInput
  $limit: Int
  $nextToken: String
) {
  getUsersByAccount(
    accountId: $accountId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      accountId
      userId
      role
      account {
        id
        shortName
        type
        linkedBankAccounts {
          items {
            id
            bankName
            accountNumber
            accountHolderName
            accountCurrency
            default
            disabled
            status
            owner
            verifiedBy
            accountId
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        referenceDocs {
          items {
            id
            docType
            docCategory
            status
            verifiedBy
            extractedData
            owner
            accountId
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        attributes
        questionnaire
        owner
        status
        comments {
          message
          commentedBy
          commentedAt
          __typename
        }
        updatedBy
        createdBy
        createdAt
        updatedAt
        __typename
      }
      updatedBy
      id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUsersByAccountQueryVariables,
  APITypes.GetUsersByAccountQuery
>;
export const getAccountsByUser = /* GraphQL */ `query GetAccountsByUser(
  $userId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelAccountUserFilterInput
  $limit: Int
  $nextToken: String
) {
  getAccountsByUser(
    userId: $userId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      accountId
      userId
      role
      account {
        id
        shortName
        type
        linkedBankAccounts {
          items {
            id
            bankName
            accountNumber
            accountHolderName
            accountCurrency
            default
            disabled
            status
            owner
            verifiedBy
            accountId
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        referenceDocs {
          items {
            id
            docType
            docCategory
            status
            verifiedBy
            extractedData
            owner
            accountId
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        attributes
        questionnaire
        owner
        status
        comments {
          message
          commentedBy
          commentedAt
          __typename
        }
        updatedBy
        createdBy
        createdAt
        updatedAt
        __typename
      }
      updatedBy
      id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAccountsByUserQueryVariables,
  APITypes.GetAccountsByUserQuery
>;
export const getDocument = /* GraphQL */ `query GetDocument($id: ID!) {
  getDocument(id: $id) {
    id
    docType
    docCategory
    pages {
      bucket
      key
      region
      __typename
    }
    status
    comments {
      message
      commentedBy
      commentedAt
      __typename
    }
    verifiedBy
    extractedData
    owner
    accountId
    account {
      id
      shortName
      type
      linkedBankAccounts {
        items {
          id
          bankName
          accountNumber
          accountHolderName
          accountCurrency
          default
          disabled
          status
          owner
          comments {
            message
            commentedBy
            commentedAt
            __typename
          }
          verifiedBy
          accountId
          account {
            id
            shortName
            type
            attributes
            questionnaire
            owner
            status
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      referenceDocs {
        items {
          id
          docType
          docCategory
          pages {
            bucket
            key
            region
            __typename
          }
          status
          comments {
            message
            commentedBy
            commentedAt
            __typename
          }
          verifiedBy
          extractedData
          owner
          accountId
          account {
            id
            shortName
            type
            attributes
            questionnaire
            owner
            status
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      attributes
      questionnaire
      owner
      status
      comments {
        message
        commentedBy
        commentedAt
        __typename
      }
      updatedBy
      createdBy
      createdAt
      updatedAt
      __typename
    }
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDocumentQueryVariables,
  APITypes.GetDocumentQuery
>;
export const listDocuments = /* GraphQL */ `query ListDocuments(
  $id: ID
  $filter: ModelDocumentFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listDocuments(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      docType
      docCategory
      pages {
        bucket
        key
        region
        __typename
      }
      status
      comments {
        message
        commentedBy
        commentedAt
        __typename
      }
      verifiedBy
      extractedData
      owner
      accountId
      account {
        id
        shortName
        type
        linkedBankAccounts {
          items {
            id
            bankName
            accountNumber
            accountHolderName
            accountCurrency
            default
            disabled
            status
            owner
            verifiedBy
            accountId
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        referenceDocs {
          items {
            id
            docType
            docCategory
            status
            verifiedBy
            extractedData
            owner
            accountId
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        attributes
        questionnaire
        owner
        status
        comments {
          message
          commentedBy
          commentedAt
          __typename
        }
        updatedBy
        createdBy
        createdAt
        updatedAt
        __typename
      }
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDocumentsQueryVariables,
  APITypes.ListDocumentsQuery
>;
export const getReferenceDocumentsByAccount = /* GraphQL */ `query GetReferenceDocumentsByAccount(
  $accountId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelDocumentFilterInput
  $limit: Int
  $nextToken: String
) {
  getReferenceDocumentsByAccount(
    accountId: $accountId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      docType
      docCategory
      pages {
        bucket
        key
        region
        __typename
      }
      status
      comments {
        message
        commentedBy
        commentedAt
        __typename
      }
      verifiedBy
      extractedData
      owner
      accountId
      account {
        id
        shortName
        type
        linkedBankAccounts {
          items {
            id
            bankName
            accountNumber
            accountHolderName
            accountCurrency
            default
            disabled
            status
            owner
            verifiedBy
            accountId
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        referenceDocs {
          items {
            id
            docType
            docCategory
            status
            verifiedBy
            extractedData
            owner
            accountId
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        attributes
        questionnaire
        owner
        status
        comments {
          message
          commentedBy
          commentedAt
          __typename
        }
        updatedBy
        createdBy
        createdAt
        updatedAt
        __typename
      }
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetReferenceDocumentsByAccountQueryVariables,
  APITypes.GetReferenceDocumentsByAccountQuery
>;
export const getUserPreference = /* GraphQL */ `query GetUserPreference($userId: String!) {
  getUserPreference(userId: $userId) {
    userId
    preferences
    updatedBy
    createdAt
    updatedAt
    id
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserPreferenceQueryVariables,
  APITypes.GetUserPreferenceQuery
>;
export const listUserPreferences = /* GraphQL */ `query ListUserPreferences(
  $userId: String
  $filter: ModelUserPreferenceFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listUserPreferences(
    userId: $userId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      userId
      preferences
      updatedBy
      createdAt
      updatedAt
      id
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserPreferencesQueryVariables,
  APITypes.ListUserPreferencesQuery
>;
export const listUsers = /* GraphQL */ `query ListUsers($input: ListUsersInput) {
  listUsers(input: $input) {
    Users {
      Username
      Attributes {
        Name
        Value
        __typename
      }
      UserCreateDate
      UserLastModifiedDate
      Enabled
      UserStatus
      MFAOptions {
        DeliveryMedium
        AttributeName
        __typename
      }
      PreferredMfaSetting
      UserMFASettingList
      Groups
      __typename
    }
    NextPaginationToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>;
export const getUserByUserId = /* GraphQL */ `query GetUserByUserId($userId: String!) {
  getUserByUserId(userId: $userId) {
    Username
    Attributes {
      Name
      Value
      __typename
    }
    UserCreateDate
    UserLastModifiedDate
    Enabled
    UserStatus
    MFAOptions {
      DeliveryMedium
      AttributeName
      __typename
    }
    PreferredMfaSetting
    UserMFASettingList
    Groups
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserByUserIdQueryVariables,
  APITypes.GetUserByUserIdQuery
>;
export const getAccountUsers = /* GraphQL */ `query GetAccountUsers($accountId: String!) {
  getAccountUsers(accountId: $accountId) {
    Username
    Attributes {
      Name
      Value
      __typename
    }
    UserCreateDate
    UserLastModifiedDate
    Enabled
    UserStatus
    MFAOptions {
      DeliveryMedium
      AttributeName
      __typename
    }
    PreferredMfaSetting
    UserMFASettingList
    Groups
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAccountUsersQueryVariables,
  APITypes.GetAccountUsersQuery
>;
export const getUserByEmail = /* GraphQL */ `query GetUserByEmail($email: AWSEmail!) {
  getUserByEmail(email: $email) {
    Username
    Attributes {
      Name
      Value
      __typename
    }
    UserCreateDate
    UserLastModifiedDate
    Enabled
    UserStatus
    MFAOptions {
      DeliveryMedium
      AttributeName
      __typename
    }
    PreferredMfaSetting
    UserMFASettingList
    Groups
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserByEmailQueryVariables,
  APITypes.GetUserByEmailQuery
>;
export const getUserByEmailFilter = /* GraphQL */ `query GetUserByEmailFilter($email: String!) {
  getUserByEmailFilter(email: $email) {
    Username
    Attributes {
      Name
      Value
      __typename
    }
    UserCreateDate
    UserLastModifiedDate
    Enabled
    UserStatus
    MFAOptions {
      DeliveryMedium
      AttributeName
      __typename
    }
    PreferredMfaSetting
    UserMFASettingList
    Groups
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserByEmailFilterQueryVariables,
  APITypes.GetUserByEmailFilterQuery
>;
export const getUserByPhoneNumber = /* GraphQL */ `query GetUserByPhoneNumber($phoneNumber: AWSPhone!) {
  getUserByPhoneNumber(phoneNumber: $phoneNumber) {
    Username
    Attributes {
      Name
      Value
      __typename
    }
    UserCreateDate
    UserLastModifiedDate
    Enabled
    UserStatus
    MFAOptions {
      DeliveryMedium
      AttributeName
      __typename
    }
    PreferredMfaSetting
    UserMFASettingList
    Groups
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserByPhoneNumberQueryVariables,
  APITypes.GetUserByPhoneNumberQuery
>;
export const listGroupsForUser = /* GraphQL */ `query ListGroupsForUser($userId: String!) {
  listGroupsForUser(userId: $userId)
}
` as GeneratedQuery<
  APITypes.ListGroupsForUserQueryVariables,
  APITypes.ListGroupsForUserQuery
>;
export const checkShortNameAvailability = /* GraphQL */ `query CheckShortNameAvailability($shortName: String!, $accountId: String) {
  checkShortNameAvailability(shortName: $shortName, accountId: $accountId) {
    Username
    Attributes {
      Name
      Value
      __typename
    }
    UserCreateDate
    UserLastModifiedDate
    Enabled
    UserStatus
    MFAOptions {
      DeliveryMedium
      AttributeName
      __typename
    }
    PreferredMfaSetting
    UserMFASettingList
    Groups
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CheckShortNameAvailabilityQueryVariables,
  APITypes.CheckShortNameAvailabilityQuery
>;
export const listAllGroups = /* GraphQL */ `query ListAllGroups {
  listAllGroups {
    Username
    Attributes {
      Name
      Value
      __typename
    }
    UserCreateDate
    UserLastModifiedDate
    Enabled
    UserStatus
    MFAOptions {
      DeliveryMedium
      AttributeName
      __typename
    }
    PreferredMfaSetting
    UserMFASettingList
    Groups
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAllGroupsQueryVariables,
  APITypes.ListAllGroupsQuery
>;
export const listUsersInGroup = /* GraphQL */ `query ListUsersInGroup($groupName: String!) {
  listUsersInGroup(groupName: $groupName) {
    Username
    Attributes {
      Name
      Value
      __typename
    }
    UserCreateDate
    UserLastModifiedDate
    Enabled
    UserStatus
    MFAOptions {
      DeliveryMedium
      AttributeName
      __typename
    }
    PreferredMfaSetting
    UserMFASettingList
    Groups
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUsersInGroupQueryVariables,
  APITypes.ListUsersInGroupQuery
>;
