import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import {
	IonButton,
	IonIcon,
	IonInput,
	IonItem,
	IonLabel,
	IonNavLink,
	IonRow,
	IonSpinner,
	IonText,
	useIonToast,
} from '@ionic/react';
import { Auth } from 'aws-amplify';
import React from 'react';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppContext, { AppContextType } from 'utils/app-context';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import './SignIn.scss';
import logoimg from '../../images/RP_Logo-01.svg';
import phoneIcon from '../../images/light/phone.svg';
import googleIcon from '../../images/light/google.svg';
import mailIcon from '../../images/light/mail.svg';
import useMobileDetector from 'hooks/use-mobile-detector';
import { Routes } from 'utils/routes';
import RinngitPayLoader from 'components/RinngitPayLoader';
import { eyeOutline, eyeOffOutline } from 'ionicons/icons';
import { Toaster, toast } from 'react-hot-toast';
import chalk from 'chalk';

const SignIn = () => {
	const log = console.log;
	const appContext: AppContextType = useContext(AppContext);
	const isMobile = useMobileDetector() === 'mobile';
	const navigate = useNavigate();
	const [newUser, setNewUser] = useState(false);
	const [verificationPage, setVerificationPage] = useState(false);
	const [firstTimeUser, setFirstTimeUser] = useState(false);
	const [showEmail, setshowEmail] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [showOtp, setShowOtp] = useState(false);
	const [showResetPassword, setShowResetPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const [loginDetails, setLoginDetails] = useState({});
	const [resendCodeTimer, setResendCodeTimer] = useState(50);
	const [resendCodeDisabled, setResendCodeDisabled] = useState(false);
	const [loggedUser, setLoggedUser] = useState<undefined | any>(undefined);

	const validationSchema = Yup.object({
		code: newUser && !firstTimeUser ? Yup.string().required() : Yup.string(),
		userName: showEmail
			? Yup.string()
					.required('Email is required')
					.matches(
						/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
						'Invalid Email address',
					)
			: Yup.number().required(),
		password: Yup.string().required('Password is required'),
		resetPassword: firstTimeUser
			? Yup.string()
					.required('Password is required')
					.matches(
						/^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/,
						'Password must contain at least one uppercase letter, one number, and one special character',
					)
			: Yup.string(),
		confirmPassword: Yup.string().oneOf(
			[Yup.ref('resetPassword'), undefined],
			'Passwords must match',
		),
	});

	let formik = useFormik({
		initialValues: {
			code: '',
			userName: '',
			password: '',
			resetPassword: '',
			confirmPassword: '',
		},
		validationSchema: validationSchema,
		onSubmit: (values, { setSubmitting }) => {
			newUser ? confirmSignUpHandler(values, setSubmitting) : signInHandler(values, setSubmitting);
		},
	});

	// useEffect(() => {
	// 	console.log('is user signed in ' + appContext.isSignedIn());
	// 	if (appContext.isSignedIn()) {
	// 		// log(chalk.cyan.bold(loggedUser));
	// 		// const routes = appContext.isAdmin ? Routes.MANAGE_ACCOUNTS : Routes.ACCOUNT_SELECTION;
	// 		// navigate(routes, { replace: true });
	// 	}
	// }, [appContext.isSignedIn()]);

	useEffect(() => {
		console.log('Is user signed in ' + appContext.isSignedIn());
	}, []);

	const notify = (toastMessageDetails) => {
		if (toastMessageDetails.toastType === 'error') {
			toast.error(toastMessageDetails.toastMessage, {
				duration: 3200,
				position: 'top-center',
			});
		}

		if (toastMessageDetails.toastType === 'success') {
			toast.success(toastMessageDetails.toastMessage, {
				duration: 3200,
				position: 'top-center',
			});
		}
	};

	const signInHandler = async (formState, setSubmitting) => {
		try {
			const logIn = await appContext.signIn({
				username: formState.userName,
				password: formState.password,
			});

			console.log(logIn);
			setLoggedUser(logIn);

			if (logIn.challengeName === 'NEW_PASSWORD_REQUIRED') {
				log(chalk.redBright('New User Login.....'));
				setLoginDetails(logIn);
				setNewUser(true);
				setFirstTimeUser(true);
				setSubmitting(false);
			}
			if (appContext.isAdmin && logIn.challengeName !== 'NEW_PASSWORD_REQUIRED') {
				navigate(Routes.MANAGE_ACCOUNTS, { replace: true });
			}
			if (!appContext.isAdmin && logIn.challengeName !== 'NEW_PASSWORD_REQUIRED') {
				navigate(Routes.ACCOUNT_SELECTION, { replace: true });
			}
		} catch (error: any) {
			console.log('error signing in:', error);
			if (error.code === 'UserNotFoundException') {
				notify({
					toastMessage: 'Verification code has been sent to e-mail',
					toastType: 'success',
				});
				setSubmitting(false);
				startResendCodeTimer();
				signUpHandler(formState);
			} else if (error.code === 'UserNotConfirmedException') {
				notify({ toastMessage: error?.message, toastType: 'error' });
				setSubmitting(false);
				setNewUser(true);
				setVerificationPage(true);
				resendCodeHandler(formState);
			} else if (error.code === 'NotAuthorizedException') {
				console.log(error?.message);
				setSubmitting(false);
				notify({ toastMessage: error?.message, toastType: 'error' });
			} else {
				setSubmitting(false);
				notify({ toastMessage: error?.message, toastType: 'error' });
			}
		}
	};

	const signUpHandler = async (formState) => {
		const attributes = showEmail
			? { email: formState.userName }
			: { phone_number: formState.userName };
		// console.log(attributes);
		try {
			await Auth.signUp({
				username: formState.userName,
				password: formState.password,
				attributes: attributes,
				autoSignIn: {
					enabled: false,
				},
			});
			setNewUser(true);
			setVerificationPage(true);
		} catch (error: any) {
			notify({ toastMessage: error?.message, toastType: 'error' });
			console.log('error signing up:', error);
		}
	};

	const resendCodeHandler = async (formState) => {
		try {
			const user = await Auth.resendSignUp(formState.userName);
			notify({
				toastMessage: 'Verification code has been sent to e-mail',
				toastType: 'success',
			});
			// setNewUser(true);
			// setVerificationPage(true);
		} catch (error: any) {
			console.log('error signing up:', error);
			notify({ toastMessage: error?.message, toastType: 'error' });
		}
	};

	const confirmSignUpHandler = async (formState, setSubmitting) => {
		try {
			const user = await Auth.confirmSignUp(formState.userName, formState.code);
			// console.log(user);
			notify({
				toastMessage: 'Account validated successfully',
				toastType: 'success',
			});
			appContext.getUserDetails();
			setNewUser(false);
			setVerificationPage(false);
			setSubmitting(false);
			formik.resetForm();
			// navigate(appContext.isAdmin ? Routes.ACCOUNT_SELECTION : Routes.MANAGE_ACCOUNTS);
			// navigate(Routes.ACCOUNT_SELECTION, { replace: true });
		} catch (error: any) {
			setSubmitting(false);
			notify({ toastMessage: error?.message, toastType: 'error' });
			console.log('error signing up:', error);
		}
	};

	const completeNewPassword = async () => {
		try {
			// console.log(loginDetails);
			await Auth.completeNewPassword(loginDetails, formik.values.confirmPassword)
				.then((result) => {
					// console.log(result);
					notify({ toastMessage: 'Password Changed Successfully', toastType: 'success' });
					setNewUser(false);
					setFirstTimeUser(false);
					formik.resetForm();
				})
				.catch((error) => {
					console.log(error);
					notify({ toastMessage: error?.message, toastType: 'error' });
				});
		} catch (error) {
			console.log(error);
		}
	};

	const forgotPasswordHandler = () => {
		navigate(Routes.FORGOT_PASSWORD, { replace: true });
	};

	const handleButtonClick = () => {};

	const showCodeValue = () => {
		setNewUser(false);
	};

	const showEmailHandler = () => {
		setshowEmail(!showEmail);
	};

	const startResendCodeTimer = () => {
		setResendCodeTimer(30);
		setResendCodeDisabled(true);

		const timerInterval = setInterval(() => {
			setResendCodeTimer((prevTimer) => {
				if (prevTimer > 0) {
					return prevTimer - 1;
				} else {
					clearInterval(timerInterval);
					setResendCodeDisabled(false);
					return 0;
				}
			});
		}, 1000);
	};

	return (
		<form onSubmit={formik.handleSubmit}>
			<div className="justify-center w-280px ">
				<Toaster />
				{isMobile && (
					<div className="container mx-auto mt-16 flex items-center justify-center md:justify-start">
						{/* Replace text with SVG logo */}
						<img src={logoimg} alt="Logo" className=" h-10" />
					</div>
				)}

				{!newUser && (
					<div>
						<div className="mt-20"></div>

						<div className="text-center text-22px text-secondary-100 font-bold my-12 ">Login</div>

						{/* <IonButton
							expand="block"
							fill="outline"
							// signin-google
							className=" mt-5 lg:mt-3 mb-8 capitalize text-secondary-75 font-normal text-16px shadow-none"
							onClick={() => {
								Auth.federatedSignIn({
									provider: CognitoHostedUIIdentityProvider.Google,
									customState: '', // TODO: generate custom state
								});
							}}
						>
							<img src={googleIcon} alt="Logo" className="mr-2 h-6" />
							Continue with Google
						</IonButton>
						{!showEmail && (
							<IonButton
								expand="block"
								fill="outline"
								className=" mt-5 mb-8 lg:mt-3 capitalize text-secondary-75 font-normal text-16px shadow-none"
								onClick={showEmailHandler}
							>
								<img src={mailIcon} alt="Logo" className="mr-2 h-4" />
								Continue with Email
							</IonButton>
						)}
						{showEmail && (
							<IonButton
								expand="block"
								fill="outline"
								className=" mt-5 lg:mt-3 mb-8 capitalize text-secondary-75 font-normal text-16px shadow-none"
								onClick={showEmailHandler}
							>
								<img src={phoneIcon} alt="Logo" className="text-secondary-75 mr-2 h-6" />
								Continue with Phone
							</IonButton>
						)} */}

						<div className="text-start text-secondary-100 mt-16">
							<IonLabel className="text-16px font-normal">
								{' '}
								{showEmail ? 'Email' : 'Phone'}
								<span className="text-failure text-xl">*</span>
							</IonLabel>
						</div>

						<IonItem
							className={`mt-2.5  app-input-filed  bg-secondary-00 ${
								formik.touched.userName && formik.errors.userName ? 'app-input-has-error' : ''
							}`}
							lines="none"
						>
							{!showEmail && (
								<IonLabel className=" signin-flag bg-no-repeat pl-7 w-16">+60</IonLabel>
							)}
							{/* {showEmail && <IonLabel>@</IonLabel>} */}
							<IonInput
								id="userName"
								name="userName"
								className="bg-transparent items-center text-secondary-100 text-14px font-medium"
								type={showEmail ? 'email' : 'text'}
								placeholder={showEmail ? 'Email' : '0000000000'}
								value={formik.values.userName}
								onIonChange={formik.handleChange}
							/>
						</IonItem>

						{showEmail ? (
							<IonText className="text-failure">
								{formik.touched.userName && formik.errors.userName && formik.errors.userName}
							</IonText>
						) : (
							<IonText className="text-failure">
								{formik.touched.userName && formik.errors.userName
									? 'Phone No field is required '
									: null}
							</IonText>
						)}

						<div className="text-start text-secondary-100 mt-8">
							<IonLabel className="text-16px font-normal">
								Password
								<span className="text-failure text-xl">*</span>
							</IonLabel>
						</div>
						<IonItem
							className={`app-input-filed bg-secondary-00 mt-2.5 ${
								formik.touched.password && formik.errors.password ? 'app-input-has-error' : ''
							}`}
							lines="none"
						>
							<IonInput
								type={showPassword ? 'text' : 'password'}
								placeholder="Password"
								className=" text-16px text-secondary-75 font-medium bg-secondary-00"
								value={formik.values.password}
								name="password"
								onIonChange={formik.handleChange}
								// onBlur={formik.handleBlur}
							></IonInput>
							<IonIcon
								onClick={() => setShowPassword(!showPassword)}
								slot="end"
								icon={showPassword ? eyeOutline : eyeOffOutline}
								className="text-primary-500 cursor-pointer"
							/>
						</IonItem>

						<IonText className="text-failure">
							{formik.touched.password && formik.errors.password && (
								<div className="text-failure">{formik.errors.password}</div>
							)}
						</IonText>

						<div
							className="text-end text-primary-100 mt-1 cursor-pointer"
							onClick={forgotPasswordHandler}
						>
							<IonLabel className="text-14px font-normal">Forgot Password?</IonLabel>
						</div>

						<IonButton
							expand="block"
							className="mt-16 shadow-none capitalize h-36px text-16px rounded-md "
							type="submit"
							disabled={formik.isSubmitting}
						>
							{formik.isSubmitting ? <IonSpinner /> : 'login'}
						</IonButton>
					</div>
				)}

				{verificationPage && (
					<div className="mt-36">
						<div className="text-secondary-100 text-center text-22px font-bold mb-2">
							Account Verification
						</div>

						<div className="justify-center w-280px mb-10 text-center">
							Verification code has been sent to e-mail
						</div>

						<IonItem
							className={`mt-20 app-input-filed shadow-none ${
								formik.touched.code && formik.errors.code ? 'app-input-has-error' : ''
							}`}
							lines="none"
						>
							<IonInput
								type={showOtp ? 'text' : 'password'}
								name="code"
								placeholder="Code"
								value={formik.values.code}
								onIonChange={formik.handleChange}
							/>
							<IonIcon
								onClick={() => setShowOtp(!showOtp)}
								slot="end"
								icon={showOtp ? eyeOutline : eyeOffOutline}
								className="text-primary-500 cursor-pointer"
							/>
						</IonItem>
						<IonText className="text-failure">
							{formik.touched.code && formik.errors.code ? 'Code field is required' : null}
						</IonText>

						<IonButton
							expand="block"
							className="mt-3 shadow-none"
							type="submit"
							disabled={formik.isSubmitting}
						>
							{formik.isSubmitting ? <IonSpinner /> : 'Confirm'}
						</IonButton>

						<div className="text-start text-secondary-100 mt-8">
							<IonText className="text-16px font-normal">Didn't get the code?</IonText>
						</div>
						<IonButton
							expand="block"
							className="mt-8 capitalize h-36px text-16px bg-primary-100 text-secondary-00 font-normal rounded-md"
							onClick={() => {
								resendCodeHandler(formik.values);
								startResendCodeTimer();
							}}
							disabled={formik.isSubmitting || resendCodeDisabled}
						>
							{resendCodeDisabled ? `Resend Code (${resendCodeTimer}s)` : 'Resend Code'}
						</IonButton>
						<div className="text-end text-primary-100 mr-1 cursor-pointer" onClick={showCodeValue}>
							<IonLabel className="text-14px font-normal">Back to login</IonLabel>
						</div>
					</div>
				)}

				{firstTimeUser && (
					<>
						<div className="text-center w-280px text-22px text-secondary-100 font-bold my-12">
							Enter Your New Password
						</div>
						<div className="mt-32 pt-5"></div>

						<div className="text-16px font-normal text-secondary-100">Password</div>
						<IonItem
							className={`mt-2.5 app-input-filed ${
								formik.touched.resetPassword && formik.errors.resetPassword
									? 'app-input-has-error'
									: ''
							}`}
							lines="none"
						>
							<IonInput
								type={showResetPassword ? 'text' : 'password'}
								className="bg-transparent text-14px text-secondary-75 font-normal"
								placeholder="Password"
								id="resetPassword"
								name="resetPassword"
								value={formik.values.resetPassword}
								onIonChange={formik.handleChange}
							/>
							<IonIcon
								onClick={() => setShowResetPassword(!showResetPassword)}
								slot="end"
								icon={showResetPassword ? eyeOutline : eyeOffOutline}
								className="text-primary-500 cursor-pointer"
							/>
						</IonItem>
						{formik.touched.resetPassword && formik.errors.resetPassword && (
							<div className="text-failure">{formik.errors.resetPassword}</div>
						)}

						<div className="mt-8 pt-3"></div>
						<div className="text-16px font-normal text-secondary-100">Confirm Password</div>
						<IonItem
							className={`mt-2.5 app-input-filed  ${
								formik.touched.confirmPassword && formik.errors.confirmPassword
									? 'app-input-has-error'
									: ''
							}`}
							lines="none"
						>
							<IonInput
								type={showConfirmPassword ? 'text' : 'password'}
								className="bg-transparent text-14px  text-secondary-75 font-normal"
								placeholder="Confirm Password"
								id="confirmPassword"
								name="confirmPassword"
								value={formik.values.confirmPassword}
								onIonChange={formik.handleChange}
							/>
							<IonIcon
								onClick={() => setShowConfirmPassword(!showConfirmPassword)}
								slot="end"
								icon={showConfirmPassword ? eyeOutline : eyeOffOutline}
								className="text-primary-500 cursor-pointer"
							/>
						</IonItem>
						{formik.touched.confirmPassword && formik.errors.confirmPassword && (
							<div className="text-failure">{formik.errors.confirmPassword}</div>
						)}

						<IonButton
							onClick={completeNewPassword}
							expand="block"
							className="mt-16 capitalize h-36px text-16px bg-primary-100 text-secondary-00 font-normal rounded-md"
							disabled={formik.isSubmitting}
						>
							{formik.isSubmitting ? <IonSpinner /> : 'Change Password'}
						</IonButton>
					</>
				)}
			</div>
		</form>
	);
};

export default SignIn;
