/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_appsync_graphqlEndpoint": "https://um3a5cpm7zfmhmmxkoyeytduja.appsync-api.ap-southeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "ap-southeast-1:d6b90c29-cb14-4060-b03f-928b8f366ad3",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_82vAJ7PZE",
    "aws_user_pools_web_client_id": "65snqbs6rm126jp40dk39adn57",
    "oauth": {
        "domain": "rpauth-uat.auth.ap-southeast-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/signin-redirect/",
        "redirectSignOut": "http://localhost:3000/signout-redirect/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "PHONE_NUMBER",
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "onboarding-dev-uat",
    "aws_content_delivery_bucket_region": "ap-southeast-1",
    "aws_content_delivery_url": "https://d7sni96cx9vmt.cloudfront.net",
    "aws_user_files_s3_bucket": "rp-kyc-docs-bucket53025-uat",
    "aws_user_files_s3_bucket_region": "ap-southeast-1"
};


export default awsmobile;
