import {
	IonButton,
	IonCol,
	IonLabel,
	IonRow,
	IonSpinner,
	IonRadio,
	IonRadioGroup,
	IonItem,
	IonText,
	IonInput,
} from '@ionic/react';
import { AccountStatus, AccountType } from 'API';
import AccountApi from 'api/account';
import { useFormik } from 'formik';
import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AppContext from 'utils/app-context';
import { Routes } from 'utils/routes';
import * as Yup from 'yup';
import RinngitPayLoader from 'components/RinngitPayLoader';
import { Toaster, toast } from 'react-hot-toast';
import chalk from 'chalk';

const BasicDetails = () => {
	const log = console.log;
	const navigate = useNavigate();
	const appContext = useContext(AppContext);
	const userDetails = appContext.userDetails;
	const [answerOneButton, setAnswerOneButton] = useState(0);
	const [answerTwoButton, setAnswerTwoButton] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [isNewAccount, setIsNewAccount] = useState(true);
	const [showToast, setShowToast] = useState(false);

	const accountDetails = appContext.selectedAccount();

	const answersOne = [
		'Physical goods (e.g. clothing, electronics, etc.)',
		'Digital goods (e.g. ebooks, software, music, financial assets etc.)',
		'Services (e.g. coaching, consulting, design, programming, etc.)',
		'Property Management or Rental',
		'Others',
	];
	const answersTwo = ["Individual's", 'Business', "Both Individual's and Business", 'Others'];

	const notify = (toastMessageDetails) => {
		if (toastMessageDetails.toastType === 'error') {
			toast.error(toastMessageDetails.toastMessage, {
				duration: 3200,
				position: 'top-center',
			});
		}

		if (toastMessageDetails.toastType === 'success') {
			toast.success(toastMessageDetails.toastMessage, {
				duration: 3200,
				position: 'top-center',
			});
		}
		setShowToast(false);
	};

	const validationSchema = Yup.object({
		accountType: Yup.string().required(),
		nationality: Yup.string().required(),
		email: appContext.isAdmin
			? Yup.string()
					.required('Email is required')
					.matches(
						/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
						'Invalid Email address',
					)
			: Yup.string(),
		shortName: Yup.string()
			.required('Short Name is required')
			.matches(/^\S+$/, 'Spaces are not allowed'),
	});

	let formik = useFormik({
		initialValues: {
			accountType: !accountDetails
				? appContext.registrationDetails.accountType
				: accountDetails.type,
			nationality: !accountDetails
				? appContext.registrationDetails.nationality
				: JSON.parse(accountDetails.attributes).nationality,
			shortName: accountDetails ? accountDetails.shortName : '',
			email: '',
			productService: !accountDetails && answersOne[0],
			payer: !accountDetails && answersTwo[0],
		},
		validationSchema: validationSchema,
		onSubmit: (values, { setSubmitting }) => {
			onNext(values, setSubmitting);
		},
	});

	useEffect(() => {
		console.log('Basic Detail Page.....');
		console.log(
			getAccountType() === AccountType.INDIVIDUAL
				? 'Creating Individual Account.....'
				: 'Creating Business Account.....',
		);

		if (accountDetails !== null) {
			setIsNewAccount(false);
		}

		const setProductAndPayer = (fieldName, fieldValue, answersArray, setAnswerButton) => {
			const index = answersArray.indexOf(fieldValue);
			if (index !== -1) {
				log(chalk.yellow.bold(`Selected ${fieldName} index: `, index));
				setAnswerButton(index);
				formik.setFieldValue(fieldName, answersArray[index]);
			}
		};

		const parsedQuestionnaire = JSON.parse(accountDetails?.questionnaire || '{}');
		setProductAndPayer(
			'productService',
			parsedQuestionnaire.productService,
			answersOne,
			setAnswerOneButton,
		);
		setProductAndPayer('payer', parsedQuestionnaire.payer, answersTwo, setAnswerTwoButton);

		if (appContext.isAdmin && accountDetails !== null) {
			getAccountOwner();
		}
	}, []);

	function handleProductAndPayerClick(index, setButtonFunction) {
		setButtonFunction(index);
	}

	function getAccountType() {
		return !accountDetails ? appContext.registrationDetails.accountType : accountDetails.type;
	}

	async function saveAccountDetails(formState, getUserId) {
		const accountData = {
			id: accountDetails?.id,
			owner: appContext.isAdmin ? getUserId : userDetails.username,
			createdBy: userDetails.username,
			updatedBy: userDetails.username,
			type: appContext.isAdmin ? AccountType.BUSINESS : formState.accountType,
			questionnaire: JSON.stringify({
				productService: formState.productService,
				payer: formState.payer,
			}),
			shortName: formState.shortName.toLowerCase(),
			attributes: JSON.stringify({
				name:
					formState.accountType === AccountType.INDIVIDUAL
						? 'To be extracted from Id'
						: 'To be extracted from SSM',
				nationality: formState.nationality,
			}),
			status: AccountStatus.DRAFT,
		};

		const account = await AccountApi.save(accountData);
		console.log(account);
		appContext.setSelectedAccount(account);
		return account;
	}

	async function onNext(formState, setSubmitting) {
		setIsLoading(true);
		try {
			const getUserId = appContext.isAdmin && (await getUserIdByEmail(formik.values.email));
			console.log(appContext.isAdmin && 'To update owner details: ', getUserId);
			const account = await saveAccountDetails(formState, getUserId);
			handleNavigation(account, getUserId);
			setIsLoading(false);
			setSubmitting(false);
		} catch (error: any) {
			setShowToast(true);
			setIsLoading(false);
			setSubmitting(false);
			console.error('Failed Saving Account', error);
			notify({ toastMessage: error?.errors[0].message, toastType: 'error' });
		}
	}

	async function getUserIdByEmail(email) {
		try {
			const userDetailsByEmail = await AccountApi.getUserByEmail(email);
			console.log(userDetailsByEmail.data.getUserByEmail.Username);
			return userDetailsByEmail.data.getUserByEmail.Username;
		} catch (error: any) {
			setIsLoading(false);
			console.error('Failed Saving Account: ', error);
			log(chalk.green.bold(error.errors[0].message));

			if (
				error.errors[0].message ===
				'Failed to get user by email: User not found with the provided email'
			) {
				log(chalk.cyan.bold('Creating new user.....'));
				return createNewUser(email);
			}
		}
	}

	async function createNewUser(email) {
		try {
			const createdUserDetails = await AccountApi.createUser({ username: email });
			console.log('Created user details: ', createdUserDetails);
			console.log('Logged user: ', userDetails);
			return createdUserDetails.data.createUser.Username;
		} catch (error: any) {
			setIsLoading(false);
			setShowToast(true);
			console.error('Failed to Create User', error);
			notify({ toastMessage: error?.errors[0].message, toastType: 'error' });
		}
	}

	function handleNavigation(account, getUserId) {
		if (appContext.isAdmin) {
			navigate(Routes.CUSTOMER_VERIFICATION + '/' + Routes.ACCOUNT_SSM_SUBMISSION_FOR_BUSINESS, {
				state: {
					id: account?.id,
					page: 1,
					toastMessage: isNewAccount
						? 'Account Created Successfully'
						: 'Account Updated Successfully',
					toastType: 'success',
				},
			});

			createAccountUser(account.id, getUserId);
		} else {
			if (account.type === AccountType.INDIVIDUAL) {
				console.log('Individual Account.....');
				navigate(Routes.CUSTOMER_VERIFICATION + '/' + Routes.ACCOUNT_ID_SUBMISSION_FOR_INDIVIDUAL, {
					state: {
						id: account?.id,
						page: 1,
						toastMessage: isNewAccount
							? 'Account Created Successfully'
							: 'Account Updated Successfully',
						toastType: 'success',
					},
				});
				if (!accountDetails) {
					console.log('adding account user to table*****************');
				}
				createAccountUser(account.id, userDetails.username);
			} else {
				console.log('Business Account.....');
				navigate(
					Routes.CUSTOMER_VERIFICATION + '/' + Routes.ACCOUNT_ID_SUBMISSION_FOR_BUSINESS_AUTH,
					{
						state: {
							id: account?.id,
							page: 1,
							toastMessage: isNewAccount
								? 'Account Created Successfully'
								: 'Account Updated Successfully',
							toastType: 'success',
						},
					},
				);

				createAccountUser(account.id, userDetails.username);
			}
		}
	}

	async function createAccountUser(accountId, userId) {
		try {
			const existingUsers = await AccountApi.getUsersByAccount(accountId);

			const currentUserExists = existingUsers.data.getUsersByAccount.items.some(
				(user) => user.userId === userId,
			);
			if (!currentUserExists) {
				const account = await AccountApi.createAccountUser({
					accountId: accountId,
					userId: userId,
					updatedBy: userDetails.username,
				});

				console.log(account);
				console.log('Account details added for account user table');
			} else {
				console.log('Current user is already in the list. Skipping user creation.');
			}
		} catch (error: any) {
			setIsLoading(false);
			setShowToast(true);
			console.error('Error checking or creating account user', error);
			notify({ toastMessage: error?.errors[0].message, toastType: 'error' });
		}
	}

	async function getAccountOwner() {
		try {
			const ownerEmail = await AccountApi.getUserByUserId(accountDetails?.owner);
			console.log(ownerEmail);
			const email = !ownerEmail.data.getUserByUserId.Attributes[2]
				? ownerEmail.data.getUserByUserId.Attributes[1].Value
				: ownerEmail.data.getUserByUserId.Attributes[2].Value;
			formik.setFieldValue('email', email);
		} catch (error: any) {
			setIsLoading(false);
			setShowToast(true);
			console.error('Error getting final account list:', error);
			notify({ toastMessage: error?.errors[0].message, toastType: 'error' });
		}
	}

	const isFormValid = () => {
		if (appContext.isAdmin && formik.values.email && formik.values.shortName) {
			return true;
		}

		if (formik.values.shortName || (accountDetails && accountDetails.shortName)) {
			return true;
		}

		return false;
	};

	return (
		<form className=" mt-5 ml-1 mb-5" onSubmit={formik.handleSubmit}>
			<Toaster />

			{!isLoading ? (
				<IonRow className="ml-3.5 h-full">
					<IonRow className="md:ml-5 w-3/4">
						<IonText className="text-secondary-75 font-bold text-22px">Basic Details</IonText>
					</IonRow>

					{!appContext.isAdmin && (
						<>
							<IonRow className="md:ml-5 w-3/4 mt-5">
								<IonLabel className="mt-1 font-normal text-secondary-75 text-16px">
									I am creating this account for
								</IonLabel>
							</IonRow>
						</>
					)}

					<IonRow>
						{!appContext.isAdmin && (
							<IonRow>
								<IonRadioGroup
									value={formik.values.accountType}
									onIonChange={(e) => {
										formik.setFieldValue('accountType', e.detail.value);
									}}
								>
									<IonRow className="  w-screen">
										<IonRow className="w-3/4 md:w-40">
											<div onClick={() => formik.setFieldValue('accountType', 'INDIVIDUAL')}>
												<IonItem lines="none" className="common-item">
													<IonLabel className=" text-16px font-normal text-secondary-100">
														Individual
													</IonLabel>
													<IonRadio className="mr-2" slot="start" value={AccountType.INDIVIDUAL} />
												</IonItem>
											</div>
										</IonRow>

										<IonRow className="w-3/4 md:w-0">
											<div onClick={() => formik.setFieldValue('accountType', 'BUSINESS')}>
												<IonItem lines="none" className="common-item">
													<IonLabel className=" text-16px font-normal text-secondary-100">
														Business
													</IonLabel>
													<IonRadio className="mr-2" slot="start" value={AccountType.BUSINESS} />
												</IonItem>
											</div>
										</IonRow>
									</IonRow>
								</IonRadioGroup>
							</IonRow>
						)}

						{appContext.isAdmin && (
							<>
								<IonRow className="mb-3 mt-4 md:ml-4 w-80">
									<IonCol className="p-0">
										<div className="mt-0.5">
											<IonLabel
												className="font-normal text-secondary-75 text-16px"
												position="floating"
											>
												Account Owner
												<span className="text-failure text-xl">*</span>
											</IonLabel>
										</div>
										<IonItem
											className={`app-input-filed bg-secondary-00 mt-3.5 ${
												formik.touched.email && formik.errors.email ? 'app-input-has-error' : ''
											}`}
											lines="none"
										>
											<IonInput
												placeholder="Email"
												className=" text-16px text-secondary-75 font-medium bg-secondary-00"
												value={formik.values.email}
												name="email"
												onIonChange={formik.handleChange}
												// onBlur={formik.handleBlur}
											></IonInput>
										</IonItem>
										{formik.touched.email && formik.errors.email && (
											<div className="text-failure">{formik.errors.email}</div>
										)}
									</IonCol>
								</IonRow>
							</>
						)}

						<IonRow className="md:ml-5 w-11/12 mt-4">
							<IonLabel className="mt-1 font-normal text-secondary-75 text-16px">
								{getAccountType() === AccountType.BUSINESS || appContext.isAdmin
									? 'Company'
									: 'You are'}
							</IonLabel>
						</IonRow>

						<IonRadioGroup
							value={formik.values.nationality}
							onIonChange={(e) => formik.setFieldValue('nationality', e.detail.value)}
						>
							<IonRow className=" pb-4 w-screen">
								<IonRow className="w-3/4 md:w-40">
									<div onClick={() => formik.setFieldValue('nationality', 'MALAYSIAN')}>
										<IonItem lines="none" className="common-item">
											<IonLabel className=" text-16px font-normal text-secondary-100">
												Malaysian
											</IonLabel>
											<IonRadio className="mr-2" slot="start" value="MALAYSIAN" />
										</IonItem>
									</div>
								</IonRow>

								<IonRow className="w-3/4 md:w-40">
									<div onClick={() => formik.setFieldValue('nationality', 'NON-MALAYSIAN')}>
										<IonItem lines="none" className="common-item">
											<IonLabel className=" text-16px font-normal text-secondary-100">
												Non-Malaysian
											</IonLabel>
											<IonRadio className="mr-2 " slot="start" value="NON-MALAYSIAN" />
										</IonItem>
									</div>
								</IonRow>
							</IonRow>
						</IonRadioGroup>

						<IonRow className="my-1 mb-10 md:ml-5 w-80">
							<IonCol className="p-0">
								<div className="pb-0.5">
									<IonLabel position="stacked" className="font-normal text-secondary-75 text-16px">
										Short Name
										<span className="text-failure text-xl">*</span>
									</IonLabel>
								</div>
								<IonItem
									className={`app-input-filed bg-secondary-00 mt-2.5 ${
										formik.touched.shortName && formik.errors.shortName ? 'app-input-has-error' : ''
									}`}
									lines="none"
								>
									<IonInput
										placeholder={accountDetails ? accountDetails.shortName : 'Short Name'}
										className={`text-16px ${
											accountDetails ? 'text-secondary-100' : 'text-secondary-75'
										} font-medium bg-secondary-00`}
										value={formik.values.shortName}
										name="shortName"
										onBlur={formik.handleBlur}
										onIonChange={(e) => {
											formik.handleChange(e);
											// handleShortNameChange(e);
										}}
										maxlength={23}
									></IonInput>
								</IonItem>

								{formik.touched.shortName && formik.errors.shortName && (
									<div className="text-failure">{String(formik.errors.shortName)}</div>
								)}
							</IonCol>
						</IonRow>

						<IonRow className="pb-5">
							<IonRow className="flex md:ml-5 mb-3.5 pt-0.5 w-screen">
								<IonLabel className="font-normal text-secondary-75 text-16px">
									What Product or Service you offer?
								</IonLabel>
							</IonRow>

							<IonRow className=" w-5/6 md:w-3/4 md:ml-5 ">
								<IonRow className="flex justify-around mb-4 mr-4 ">
									<IonButton
										className={`custom-button capitalize text-16px font-normal ${
											answerOneButton == 0 ? 'text-secondary-00' : 'text-secondary-100'
										}`}
										expand="block"
										fill={answerOneButton == 0 ? 'solid' : 'default'}
										onClick={() => {
											handleProductAndPayerClick(0, setAnswerOneButton);
											formik.setFieldValue('productService', answersOne[0]);
										}}
									>
										Physical Goods
									</IonButton>
								</IonRow>

								<IonRow className="flex justify-around mb-4 mr-4">
									<IonButton
										className={`custom-button capitalize text-16px font-normal ${
											answerOneButton == 1 ? 'text-secondary-00' : 'text-secondary-100'
										}`}
										expand="block"
										fill={answerOneButton == 1 ? 'solid' : 'default'}
										onClick={() => {
											handleProductAndPayerClick(1, setAnswerOneButton);
											formik.setFieldValue('productService', answersOne[1]);
										}}
									>
										Digital Goods
									</IonButton>
								</IonRow>

								<IonRow className="flex justify-around mb-4 mr-4">
									<IonButton
										className={`custom-button capitalize text-16px font-normal  ${
											answerOneButton == 2 ? 'text-secondary-00' : 'text-secondary-100'
										}`}
										expand="block"
										fill={answerOneButton == 2 ? 'solid' : 'default'}
										onClick={() => {
											handleProductAndPayerClick(2, setAnswerOneButton);
											formik.setFieldValue('productService', answersOne[2]);
										}}
									>
										Services
									</IonButton>
								</IonRow>

								<IonRow className="flex justify-around mb-4 mr-4">
									<IonButton
										className={`custom-button capitalize text-16px font-normal  ${
											answerOneButton == 3 ? 'text-secondary-00' : 'text-secondary-100'
										}`}
										expand="block"
										fill={answerOneButton == 3 ? 'solid' : 'default'}
										onClick={() => {
											handleProductAndPayerClick(3, setAnswerOneButton);
											formik.setFieldValue('productService', answersOne[3]);
										}}
									>
										Property Management or Rental
									</IonButton>
								</IonRow>

								<IonRow className="flex justify-around mb-4 mr-4">
									<IonButton
										className={`custom-button capitalize text-16px font-normal  ${
											answerOneButton == 4 ? 'text-secondary-00' : 'text-secondary-100'
										}`}
										expand="block"
										fill={answerOneButton == 4 ? 'solid' : 'default'}
										onClick={() => {
											handleProductAndPayerClick(4, setAnswerOneButton);
											formik.setFieldValue('productService', answersOne[4]);
										}}
									>
										Others
									</IonButton>
								</IonRow>
							</IonRow>
						</IonRow>

						<IonRow className="pb-2">
							<IonRow className="flex md:ml-5 mb-4 mt-1 whitespace-normal w-screen">
								<IonLabel className="font-normal text-secondary-75 text-16px">
									Who will be paying for your Product or Service?
								</IonLabel>
							</IonRow>

							<IonRow className=" w-5/6 md:w-3/4 md:ml-5">
								<IonRow className="flex justify-around mb-4 mr-4">
									<IonButton
										className={`custom-button capitalize text-16px font-normal ${
											answerTwoButton == 0 ? 'text-secondary-00' : 'text-secondary-100'
										}`}
										expand="block"
										fill={answerTwoButton == 0 ? 'solid' : 'default'}
										onClick={() => {
											handleProductAndPayerClick(0, setAnswerTwoButton);
											formik.setFieldValue('payer', answersTwo[0]);
										}}
									>
										Individual's
									</IonButton>
								</IonRow>

								<IonRow className="flex justify-around mb-4 mr-4">
									<IonButton
										className={`custom-button capitalize text-16px font-normal  ${
											answerTwoButton == 1 ? 'text-secondary-00' : 'text-secondary-100'
										}`}
										expand="block"
										fill={answerTwoButton == 1 ? 'solid' : 'default'}
										onClick={() => {
											handleProductAndPayerClick(1, setAnswerTwoButton);
											formik.setFieldValue('payer', answersTwo[1]);
										}}
									>
										Business
									</IonButton>
								</IonRow>

								<IonRow className="flex justify-around mb-4 mr-4">
									<IonButton
										className={`custom-button capitalize text-16px font-normal  ${
											answerTwoButton == 2 ? 'text-secondary-00' : 'text-secondary-100'
										}`}
										expand="block"
										fill={answerTwoButton == 2 ? 'solid' : 'default'}
										onClick={() => {
											handleProductAndPayerClick(2, setAnswerTwoButton);
											formik.setFieldValue('payer', answersTwo[2]);
										}}
									>
										Both Individual's and Business
									</IonButton>
								</IonRow>

								<IonRow className="flex justify-around mb-4 mr-4">
									<IonButton
										className={`capitalize custom-button text-16px font-normal ${
											answerTwoButton == 3 ? 'text-secondary-00' : 'text-secondary-100'
										}`}
										expand="block"
										fill={answerTwoButton == 3 ? 'solid' : 'default'}
										onClick={() => {
											handleProductAndPayerClick(3, setAnswerTwoButton);
											formik.setFieldValue('payer', answersTwo[3]);
										}}
									>
										Others
									</IonButton>
								</IonRow>
							</IonRow>
						</IonRow>
					</IonRow>

					<IonRow className="md:ml-5 md:w-3/4 mb-1 md:mb-4 pb-0.5">
						<IonButton
							type="submit"
							expand="block"
							className="h-36px capitalize font-normal text-16px rounded-sm"
							disabled={!isFormValid() || formik.isSubmitting}
						>
							{formik.isSubmitting ? <IonSpinner /> : 'Continue'}
						</IonButton>
					</IonRow>
				</IonRow>
			) : (
				<RinngitPayLoader props={{ overlay: true }} />
			)}
		</form>
	);
};

export default React.memo(BasicDetails);
